import Uppy, { type UppyFile } from '@uppy/core';
import { FileInput } from '@uppy/react';
import { useField } from 'formik';
import truncate from 'lodash/truncate';
import React, {
  type ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import Select, {
  type Props as ReactSelectProps,
  type SingleValue,
} from 'react-select';
import CreatableSelect, { type CreatableProps } from 'react-select/creatable';
import useMeasure from 'react-use-measure';

import {
  type DtoPromptTemplate,
  type DtoSharedAsset,
  type EnumsBrandPredefinedBlockScenario,
  EnumsSharedAssetPurpose,
} from '@lp-lib/api-service-client/public';
import {
  type Block,
  type BlockFields,
  type BlockMedia,
  type BlockMediaFields,
} from '@lp-lib/game';
import { type Media, type MediaData } from '@lp-lib/media';

import { useLiveCallback } from '../../../../../hooks/useLiveCallback';
import { useUppy } from '../../../../../hooks/useUppy';
import { type BlockUGCAsset, RoleUtils } from '../../../../../types';
import { fromMediaDTO } from '../../../../../utils/api-dto';
import { uuidv4 } from '../../../../../utils/common';
import { isObjectish } from '../../../../../utils/object';
import { buildReactSelectStyles } from '../../../../../utils/react-select';
import { TimeUtils } from '../../../../../utils/time';
import { ArrowLeftIcon, ArrowRightIcon } from '../../../../icons/Arrows';
import { DeleteIcon } from '../../../../icons/DeleteIcon';
import { InfoIcon } from '../../../../icons/InfoIcon';
import { Loading } from '../../../../Loading';
import { MarkdownTextArea } from '../../../../MarkdownEditor';
import {
  MediaEditor,
  type MediaEditorProps,
} from '../../../../MediaUploader/MediaEditor';
import { MediaUploader } from '../../../../MediaUploader/MediaUploader';
import { usePromptTemplate } from '../../../../PromptTemplate';
import { useOpenPromptTemplatePickerModal } from '../../../../PromptTemplate/PromptTemplatePicker';
import { useBlockEditorStore } from '../../../../RoutedBlock';
import {
  SharedAssetCover,
  useOpenShareAssetPickerModal,
  useSharedAsset,
} from '../../../../SharedAsset';
import { SwitcherControlled } from '../../../../Switcher';
import { useUser } from '../../../../UserContext';
import { UGCUtils } from '../../../UGC/utils';
import { BlockLifecycleRulesEditor } from '../LifecycleRules/BlockLifecycleRulesEditor';
import {
  CollapsibleFields,
  FieldContainer,
  FieldDescription,
  FieldPanel,
  FieldTitle,
  SimpleFieldEditor,
} from './FieldEditorUtilities';

export function EditorLayout(props: {
  children: ReactNode;
  bottomAccessory?: ReactNode;
}): JSX.Element {
  return (
    <div className='relative w-full border-none flex flex-col'>
      <div className='p-5 rounded-xl bg-dark-gray flex flex-row'>
        {props.children}
      </div>
      {props.bottomAccessory}
    </div>
  );
}

export function EditorBody(props: { children: ReactNode }): JSX.Element {
  return (
    <div className='w-full h-full min-w-220 flex flex-col'>
      {props.children}
    </div>
  );
}

export type EditorProps<T extends Block> = {
  block: T;
  blockScenario?: EnumsBrandPredefinedBlockScenario | null;
  setSavingChanges: (c: boolean) => void;
  hideSharedFields?: boolean;
};

export type BlockFieldUpdater<B extends Block> = <
  T extends keyof BlockFields<B>
>(
  field: T,
  value: BlockFields<B>[T]
) => Promise<void>;

export function useEditor<B extends Block>(
  props: EditorProps<B>
): {
  updateField: <T extends keyof BlockFields<B>>(
    field: T,
    value: BlockFields<B>[T]
  ) => Promise<void>;
  updateFieldLocalFirst: <T extends keyof BlockFields<B>>(
    field: T,
    value: BlockFields<B>[T]
  ) => Promise<void>;
} {
  const store = useBlockEditorStore();
  const { block, setSavingChanges } = props;
  const blockId = block.id;
  return {
    updateField: useLiveCallback(
      async <T extends keyof BlockFields<B>>(
        name: T,
        value: BlockFields<B>[T]
      ) => {
        if (!blockId || !name || value === null || value === undefined) return;
        setSavingChanges(true);
        try {
          await store.updateEditingBlockField<B, T>(blockId, name, value);
        } catch (error) {
          // TODO: (RGS)
          console.log(error);
        } finally {
          setSavingChanges(false);
        }
      }
    ),
    updateFieldLocalFirst: useLiveCallback(
      async <T extends keyof BlockFields<B>>(
        name: T,
        value: BlockFields<B>[T]
      ) => {
        if (!blockId || !name) return;
        setSavingChanges(true);
        try {
          await store.updateEditingBlockFieldLocalFirst<B, T>(
            blockId,
            name,
            value
          );
        } catch (error) {
          // TODO: (RGS)
          console.log(error);
        } finally {
          setSavingChanges(false);
        }
      }
    ),
  };
}

type BlockMediaProps<B extends BlockMedia> = {
  blockId: string;
  field: keyof BlockMediaFields<B>;
} & Parameters<typeof MediaUploader>[number];

export function BlockMediaUploader<B extends BlockMedia>(
  props: BlockMediaProps<B>
): JSX.Element {
  const { blockId, field, ...rest } = props;
  const id = `${blockId}-${String(field)}`;
  const store = useBlockEditorStore();

  const onUploadSuccess = (media: Media) => {
    store.updateBlockMedia(blockId, field, media);
  };

  const onMediaDelete = () => {
    store.updateBlockMedia(blockId, field, null);
  };

  return (
    <MediaUploader
      id={id}
      {...rest}
      onUploadSuccess={onUploadSuccess}
      onMediaDelete={onMediaDelete}
    />
  );
}

type BlockMediaEditorProps<B extends BlockMedia> = {
  blockId: string;
  field: keyof BlockMediaFields<B>;
} & Omit<MediaEditorProps, 'id' | 'onChange'>;

export function BlockMediaEditor<B extends BlockMedia>(
  props: BlockMediaEditorProps<B>
): JSX.Element {
  const { blockId, field, ...rest } = props;
  const id = `${blockId}-${String(field)}`;
  const store = useBlockEditorStore();

  const handleChange = (mediaData: MediaData | null, media: Media | null) => {
    store.updateBlockMediaV2(blockId, field, mediaData, media);
  };

  return <MediaEditor id={id} {...rest} onChange={handleChange} />;
}

type CheckboxProps = {
  name: string;
  value: boolean;
  onChange: (checked: boolean, name?: string) => void;
};
export function CheckboxField(props: CheckboxProps): JSX.Element {
  const { name, onChange } = props;
  const [field, , helpers] = useField<boolean>({
    name,
    type: 'checkbox',
    checked: props.value,
  });
  return (
    <SwitcherControlled
      name={field.name}
      className=''
      checked={field.checked ?? true}
      onChange={(checked: boolean): void => {
        helpers.setValue(checked);
        onChange(checked, field.name);
      }}
    />
  );
}
// reference: https://github.com/JedWatson/react-select/blob/fe7dab371290b87c425d9036b66febcf4d1beead/packages/react-select/src/builtins.ts
export type Option = {
  label: string;
  value: string | number;
  isDisabled?: boolean;
};

type SelectProps = {
  name: string;
  // TODO: can probably remove this too, and just emit { name, value }
  onChange: (
    e: React.ChangeEvent<HTMLInputElement> | null,
    name?: string,
    value?: string | number
  ) => void;
} & ReactSelectProps<Option>;

export function SelectField(props: SelectProps): JSX.Element {
  const { name, onChange } = props;
  const options = props.options as Option[];
  const [field, , helpers] = useField<string | number>(name);

  return (
    <Select<Option>
      options={options}
      name={field.name}
      isSearchable={false}
      className='select-box text-white w-76 h-10 mt-1'
      classNamePrefix='select-box'
      value={options?.find((option) => option.value === field.value)}
      onChange={(option): void => {
        if (!option) return;
        helpers.setValue(option.value);
        onChange(null, name, option.value);
      }}
      onBlur={field.onBlur}
    />
  );
}

function isOptions(options: SelectProps['options']): options is Option[] {
  if (!options || options.length === 0) return true;
  return 'value' in options[0];
}

type RHFSelectProps<B extends Block, T extends KeyOf<BlockFields<B>>> = {
  name: T;
  value: Option['value'];
  onChange: (name: T, value: Nullable<Option['value'], false>) => void;
  options: ReactSelectProps<Option>['options'];
  label?: ReactNode;
  disabled?: boolean;
  className?: string;
  description?: string;
};

export function RHFSelectField<
  B extends Block,
  T extends KeyOf<BlockFields<B>> = KeyOf<BlockFields<B>>
>(props: RHFSelectProps<B, T>): JSX.Element | null {
  if (!isOptions(props.options))
    throw new Error('Group select is not supported');
  const options = props.options || [];

  const onChange = (newValue: SingleValue<Option>) => {
    props.onChange(props.name, newValue?.value ?? null);
  };

  return (
    <div className='w-full'>
      {props.label && (
        <>
          {typeof props.label === 'string' ? (
            <span className='text-white font-bold'>{props.label}</span>
          ) : (
            props.label
          )}
        </>
      )}
      <Select<Option>
        options={props.options}
        name={props.name}
        isSearchable={false}
        className={`select-box ${props.className}`}
        classNamePrefix='select-box'
        value={options.find((o) => o.value === props.value)}
        onChange={onChange}
        isDisabled={props.disabled}
      />
      {props.description && (
        <div className='text-icon-gray text-sms mt-2 ml-2'>
          {props.description}
        </div>
      )}
    </div>
  );
}

type RHFCreatableSelectProps<
  B extends Block,
  T extends KeyOf<BlockFields<B>>
> = {
  name: T;
  label?: string;
  description?: string;
  value?: Option['value'] | SingleValue<Option>;
} & Omit<CreatableProps<Option, false, never>, 'value'>;

export function RHFCreatableSelectField<
  B extends Block,
  T extends KeyOf<BlockFields<B>> = KeyOf<BlockFields<B>>
>(props: RHFCreatableSelectProps<B, T>): JSX.Element | null {
  const styles = useMemo(
    () => props.styles ?? (buildReactSelectStyles<Option>() as never),
    [props.styles]
  );

  const value = useMemo(() => {
    return !props.value || isObjectish(props.value)
      ? (props.value as SingleValue<Option>)
      : props.options?.find((o) => o.value === props.value);
  }, [props.value, props.options]);

  return (
    <div className='w-full'>
      {props.label && (
        <span className='text-white font-bold'>{props.label}</span>
      )}
      <CreatableSelect<Option, false, never>
        {...props}
        name={props.name}
        className={props.className ?? 'select-box'}
        classNamePrefix={props.classNamePrefix ?? 'select-box-v2'}
        value={value}
        styles={styles}
      />
      {props.description && (
        <div className='text-icon-gray text-xs mt-2 font-normal'>
          {props.description}
        </div>
      )}
    </div>
  );
}

export function formatDurationOption(secs: number | string): string {
  let seconds = typeof secs === 'string' ? parseInt(secs, 10) : secs;
  if (isNaN(seconds)) seconds = 0;
  return TimeUtils.AutoFormatMMSS(seconds, 'long');
}

export function getNewDurationOptionData(inputValue: string) {
  const value = parseInt(inputValue, 10);
  return {
    value,
    label: formatDurationOption(value),
  };
}

export function isValidNewDurationOption(inputValue: string) {
  const raw = parseInt(inputValue, 10);
  return !isNaN(raw) && raw > 0;
}

type CreatableDurationSelectProps<
  B extends Block,
  T extends KeyOf<BlockFields<B>>
> = {
  name: T;
  value: Option['value'];
  onChange: (name: T, value: Nullable<Option['value'], false>) => void;
  options: ReactSelectProps<Option, false, never>['options'];
  label?: string;
  disabled?: boolean;
  className?: string;
  description?: string;
};

export function CreatableDurationSelect<
  B extends Block,
  T extends KeyOf<BlockFields<B>> = KeyOf<BlockFields<B>>
>(props: CreatableDurationSelectProps<B, T>) {
  const options = props.options || [];
  const value = options.find((o) => o.value === props.value) ?? {
    value: props.value,
    label: formatDurationOption(props.value),
  };
  const onChange = (newValue: SingleValue<Option>) => {
    props.onChange(props.name, newValue?.value ?? null);
  };
  return (
    <RHFCreatableSelectField<B, T>
      className={props.className ?? 'w-full h-10 text-white'}
      label={props.label}
      name={props.name}
      description={props.description}
      options={options}
      onChange={onChange}
      value={value}
      formatCreateLabel={formatDurationOption}
      isValidNewOption={isValidNewDurationOption}
      getNewOptionData={getNewDurationOptionData}
      createOptionPosition='first'
    />
  );
}

type RFHCheckboxProps<B extends Block, T extends KeyOf<BlockFields<B>>> = {
  label: string;
  name: T;
  value: boolean;
  onChange: (name: T, checked: boolean) => void;
  description?:
    | string
    | {
        enabled: string;
        disabled: string;
      };
  disabled?: boolean;
};

type RHFCheckboxRawProps = {
  label: string;
  name: string;
  value: boolean;
  onChange: (checked: boolean) => void;
  description?:
    | string
    | {
        enabled: string;
        disabled: string;
      };
};

export function RHFCheckbox<
  B extends Block,
  T extends KeyOf<BlockFields<B>> = KeyOf<BlockFields<B>>
>(props: RFHCheckboxProps<B, T>): JSX.Element {
  const description = useMemo(() => {
    if (!props.description) return;
    if (typeof props.description === 'string') return props.description;
    return props.value ? props.description.enabled : props.description.disabled;
  }, [props.value, props.description]);
  return (
    <div className='w-full flex flex-col'>
      <div className='flex items-center justify-between'>
        <span className='text-white font-bold'>{props.label}</span>
        <SwitcherControlled
          name={props.name}
          className=''
          checked={props.value}
          onChange={(checked: boolean): void => {
            props.onChange(props.name, checked);
          }}
          disabled={props.disabled}
        />
      </div>
      {description && (
        <div className='text-icon-gray text-3xs font-medium mt-2'>
          {description}
        </div>
      )}
    </div>
  );
}

export function RHFCheckboxRaw(props: RHFCheckboxRawProps): JSX.Element {
  const description = useMemo(() => {
    if (!props.description) return;
    if (typeof props.description === 'string') return props.description;
    return props.value ? props.description.enabled : props.description.disabled;
  }, [props.value, props.description]);
  return (
    <div className='w-full flex flex-col'>
      <div className='flex items-center justify-between'>
        <span className='text-white font-bold'>{props.label}</span>
        <SwitcherControlled
          name={props.name}
          className=''
          checked={props.value}
          onChange={(checked: boolean): void => {
            props.onChange(checked);
          }}
        />
      </div>
      {description && (
        <div className='text-icon-gray text-3xs font-medium mt-2'>
          {description}
        </div>
      )}
    </div>
  );
}

function BlockUGCAssetFieldEditor<B extends Block>(
  props: EditorProps<B>
): JSX.Element {
  const { block } = props;
  const { updateFieldLocalFirst: updateField } = useEditor(props);
  const [selected, setSelected] = useState<DtoSharedAsset | undefined>();
  const { data, isLoading } = useSharedAsset(block.fields.ugcAssetId);

  useEffect(() => {
    setSelected(data);
  }, [data]);

  const openShareAssetPickerModal = useOpenShareAssetPickerModal();

  const handleAdd = () => {
    openShareAssetPickerModal({
      purposes: [
        EnumsSharedAssetPurpose.SharedAssetPurposeGamePackShowcaseCard,
      ],
      onSelected: (item) => {
        setSelected(item);
        updateField('ugcAssetId', item.id);
      },
    });
  };

  const asset = useMemo<BlockUGCAsset | null>(() => {
    if (!selected) return null;
    return UGCUtils.FromSharedAsset(selected);
  }, [selected]);

  const onDelete = () => {
    setSelected(undefined);
    updateField('ugcAssetId', null);
  };

  if (isLoading) return <Loading text='' />;

  return (
    <div className='flex items-center gap-2 relative'>
      <div className='w-full flex items-center gap-2 relative'>
        <div className='flex-1 bg-secondary rounded-xl p-2 flex items-center gap-3.5'>
          <button type='button' onClick={handleAdd}>
            📂
          </button>
          <SharedAssetCover
            media={fromMediaDTO(asset?.media)}
            className='w-22.5 h-12.5'
            cliclable
          />
          <div className='flex-1'>
            <div className='text-2xs font-bold text-white'>
              {asset?.primaryText ?? 'N/A'}
            </div>
            <div className={`mt-2 text-3xs font-normal text-icon-gray`}>
              {asset?.secondaryText}
            </div>
          </div>
          {asset && (
            <div className='flex flex-col h-full justify-between'>
              <div className='w-6 h-6 flex items-center justify-center text-white'>
                <a
                  href={`/admin/toolkit/shared-media?id=${asset.id}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <ArrowRightIcon />
                </a>
              </div>
              <button
                type='button'
                onClick={onDelete}
                className='w-6 h-6 rounded-lg border border-secondary btn flex 
                justify-center items-center text-red-002 bg-black'
              >
                <DeleteIcon className='w-3 h-3 fill-current' />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function BlockUGCPromptTemplateFieldEditor<B extends Block>(
  props: EditorProps<B>
): JSX.Element {
  const { block } = props;
  const { updateFieldLocalFirst: updateField } = useEditor(props);
  const [selected, setSelected] = useState<DtoPromptTemplate | undefined>();
  const { data, isLoading } = usePromptTemplate(
    block.fields.ugcPromptTemplateId
  );

  useEffect(() => {
    setSelected(data);
  }, [data]);

  const openPicker = useOpenPromptTemplatePickerModal();

  const handleAdd = () => {
    openPicker({
      onSelected: (item) => {
        setSelected(item);
        updateField('ugcPromptTemplateId', item.id);
      },
    });
  };

  const onDelete = () => {
    setSelected(undefined);
    updateField('ugcPromptTemplateId', null);
  };

  if (isLoading) return <Loading text='' />;

  return (
    <div className='flex items-center gap-2 relative'>
      <div className='w-full flex items-center gap-2 relative'>
        <div className='flex-1 bg-secondary rounded-xl p-2 flex items-center gap-3.5 min-h-16'>
          <button type='button' onClick={handleAdd}>
            📂
          </button>
          <div className='flex-1'>
            <div className='text-2xs font-bold text-white'>
              {selected?.name ?? 'Unselected'}
            </div>
            {selected?.systemPrompt && (
              <div className={`mt-2 text-3xs font-normal text-icon-gray`}>
                {truncate(selected?.systemPrompt, { length: 30 })}
              </div>
            )}
          </div>
          {selected && (
            <div className='flex flex-col h-full justify-between'>
              <div className='w-6 h-6 flex items-center justify-center text-white'>
                <a
                  href={`/admin/prompt-templates/${selected.id}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <ArrowRightIcon />
                </a>
              </div>
              <button
                type='button'
                onClick={onDelete}
                className='w-6 h-6 rounded-lg border border-secondary btn flex 
                justify-center items-center text-red-002 bg-black'
              >
                <DeleteIcon className='w-3 h-3 fill-current' />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function RHFLabel(props: {
  label: string;
  description: string;
}): JSX.Element | null {
  return (
    <div className='w-full'>
      {props.label && (
        <span className='text-white font-bold'>{props.label}</span>
      )}
      {props.description && (
        <div className='text-icon-gray text-sms mt-2 ml-2'>
          {props.description}
        </div>
      )}
    </div>
  );
}

export function AdditionalSettings(props: { children: React.ReactNode }) {
  const user = useUser();
  const isAdmin = RoleUtils.isAdmin(user);

  return (
    <CollapsibleFields
      buttonLabel='Additional Settings'
      initiallyOpen={isAdmin}
    >
      {props.children}
    </CollapsibleFields>
  );
}

export function AdditionalSharedSettingsEditor<B extends Block>(
  props: EditorProps<B>
) {
  const { updateField } = useEditor(props);

  if (props.hideSharedFields) return null;

  return (
    <>
      <FieldContainer styles={{ bg: 'bg-admin-red' }}>
        <div className='flex-1'>
          <FieldTitle>Block Title</FieldTitle>
          <input
            name='title'
            key={`block-${props.block.id}-title`}
            className='field h-13.5 m-0 w-full'
            placeholder='Max 100 characters'
            maxLength={100}
            defaultValue={props.block.fields.title ?? ''}
            onBlur={(event) => {
              updateField('title', event.target.value);
            }}
          />
        </div>

        <div className='flex-1'>
          <FieldTitle>Internal Label</FieldTitle>
          <input
            name='internalLabel'
            key={`block-${props.block.id}-internal-label`}
            className='field h-13.5 m-0 w-full'
            placeholder='Max 100 characters'
            maxLength={100}
            defaultValue={props.block.fields.internalLabel}
            onBlur={(event) => {
              updateField('internalLabel', event.target.value);
            }}
          />
        </div>
      </FieldContainer>
      <FieldContainer styles={{ bg: 'bg-admin-red' }}>
        <FieldPanel>
          <FieldTitle>UGC Asset (Optional)</FieldTitle>
          <FieldDescription>
            Asset that will be displayed in the UGC Editor
          </FieldDescription>
        </FieldPanel>
        <div className='w-1/2'>
          <BlockUGCAssetFieldEditor {...props} />
        </div>
      </FieldContainer>
      <FieldContainer styles={{ bg: 'bg-admin-red' }}>
        <FieldPanel>
          <FieldTitle>UGC AI Template (Optional)</FieldTitle>
          <FieldDescription>
            Prompt Template that will be used for Generative AI
          </FieldDescription>
        </FieldPanel>
        <div className='w-1/2'>
          <BlockUGCPromptTemplateFieldEditor {...props} />
        </div>
      </FieldContainer>
      <SimpleFieldEditor
        name='Notes'
        description='Optional notes for hosts. Markdown supported.'
        styles={{ bg: 'bg-admin-red' }}
      >
        <MarkdownTextArea
          defaultValue={props.block.fields.notes ?? ''}
          onBlur={(event) => {
            updateField('notes', event.target.value);
          }}
          onMarkdownEditorSave={(value) => {
            updateField('notes', value);
          }}
        />
      </SimpleFieldEditor>
      <SimpleFieldEditor
        name='Block Reference Id'
        description={`This globally-unique name allows referencing this block's output from another block's Voice Over Script. The variable will be of the style %block:ReferenceID%. Outputs are currently only supported for Question and Multiple Choice blocks. Valid characters: a-z, A-Z, 0-9, _, -`}
        styles={{ bg: 'bg-admin-red' }}
      >
        <input
          name='referenceId'
          key={`block-${props.block.id}-reference-id`}
          className='field h-13.5 m-0 w-full'
          placeholder='Max 100 characters, no spaces or % allowed'
          maxLength={100}
          defaultValue={props.block.fields.referenceId ?? ''}
          onBlur={(event) => {
            updateField('referenceId', event.target.value);
          }}
        />
      </SimpleFieldEditor>
      <BlockLifecycleRulesEditor
        block={props.block}
        value={props.block.fields.lifecycleRules}
        onChange={(val) => updateField('lifecycleRules', val)}
      />
    </>
  );
}

export function useCSVUploader(
  onUpload: (content: string | ArrayBuffer) => void,
  id?: string
) {
  const uploaderId = useMemo(() => `csv-uploader-${id ?? uuidv4()}`, [id]);
  const fileParser = useLiveCallback((currentFile: UppyFile) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const content = e.target?.result ?? '';
      if (!content) return;
      return onUpload(content);
    };

    reader.readAsText(currentFile.data);
    return false;
  });

  const uppy = useUppy(
    uploaderId,
    () =>
      new Uppy({
        id: uploaderId,
        autoProceed: false,
        debug: true,
        restrictions: {
          maxFileSize: 1024 * 1024,
          minFileSize: null,
          maxTotalFileSize: 1024 * 1024,
          maxNumberOfFiles: null,
          minNumberOfFiles: 1,
          allowedFileTypes: ['.csv'],
        },
        locale: {
          strings: {
            chooseFiles: 'Upload CSV',
            exceedsSize: 'Max file size exceeded',
            youCanOnlyUploadFileTypes: 'File type not supported',
          },
        },
        onBeforeFileAdded: fileParser,
      })
  );

  return uppy;
}

export function CSVUtils(props: {
  id?: string;
  onUpload: (content: string | ArrayBuffer) => void;
  downloadHref: string;
  downloadName: string;
}) {
  const uppy = useCSVUploader(props.onUpload, props.id);

  return (
    <div className='flex items-center gap-4'>
      <label className='btn text-xs text-icon-gray underline cursor-pointer'>
        Upload CSV
        <div className='hidden'>
          <FileInput uppy={uppy} inputName={'files'} />
        </div>
      </label>
      <a
        className='text-xs text-icon-gray underline'
        href={props.downloadHref}
        download={props.downloadName}
      >
        Download CSV
      </a>
    </div>
  );
}

const pageItemWidthPx = 42;

export function Pagination(props: {
  count: number;
  page: number;
  onChange: (page: number) => void;
}) {
  const { count, page, onChange } = props;
  const pagesRef = useRef<HTMLDivElement | null>(null);
  const [leftIndex, setLeftIndex] = useState(0);
  const [ref, bounds] = useMeasure({
    debounce: 100,
    polyfill: ResizeObserver,
  });

  const ready = bounds.width > 0;

  const visiblePagesCount = Math.floor(bounds.width / pageItemWidthPx);
  const hideBack = !ready || leftIndex === 0;
  const hideForward = !ready || leftIndex + visiblePagesCount > count - 1;

  const handleSetLeftIndex = (index: number) => {
    if (index + visiblePagesCount > count - 1)
      index = count - visiblePagesCount;
    if (index < 0) index = 0;
    setLeftIndex(index);
  };

  useEffect(() => {
    if (!pagesRef.current) return;

    pagesRef.current.children[leftIndex]?.scrollIntoView({
      behavior: 'smooth',
      inline: 'start',
      block: 'nearest',
    });
  }, [leftIndex]);

  const init = useLiveCallback(() => {
    handleSetLeftIndex(page);
  });
  useEffect(() => {
    if (!ready) return;

    init();
  }, [init, ready]);

  const pages = useMemo(() => {
    const pages = [];
    for (let i = 0; i < count; i++) {
      pages.push(
        <div
          key={i}
          className={`flex-none p-0.5 cursor-pointer`}
          onClick={() => onChange(i)}
          style={{ scrollSnapAlign: 'start', width: pageItemWidthPx }}
        >
          <div
            className={`
              flex items-center justify-center w-full h-8
              bg-secondary rounded-xl
              border ${i === page ? 'border-primary' : 'border-secondary'}
            `}
          >
            {i + 1}
          </div>
        </div>
      );
    }
    return pages;
  }, [count, onChange, page]);

  const handleBack = () => {
    handleSetLeftIndex(leftIndex - visiblePagesCount);
  };

  const handleForward = () => {
    handleSetLeftIndex(leftIndex + visiblePagesCount);
  };

  return (
    <div className='w-full min-w-0 flex items-center justify-start gap-4 overflow-x-hidden'>
      <button
        type='button'
        className={`flex-none btn text-white`}
        onClick={handleBack}
        disabled={hideBack}
      >
        <ArrowLeftIcon className='w-3.5 h-3.5 fill-current' />
      </button>

      <div className='flex-1 min-w-0' ref={ref}>
        <div
          ref={pagesRef}
          className='flex items-center scrollbar-hide overflow-x-hidden'
          style={{ scrollSnapType: 'x mandatory' }}
        >
          {pages}
        </div>
      </div>

      <button
        type='button'
        className={`flex-none btn text-white`}
        onClick={handleForward}
        disabled={hideForward}
      >
        <ArrowRightIcon className='w-3.5 h-3.5 fill-current' />
      </button>
    </div>
  );
}

export function SimpleTooltip(props: {
  children: ReactNode;
  triggerNode?: ReactNode;
  className?: string;
}): JSX.Element {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement: 'auto',
      trigger: 'hover',
      interactive: true,
      delayHide: 150,
    });

  return (
    <div>
      <div ref={setTriggerRef}>
        {props.triggerNode ?? <InfoIcon className='w-4 h-4' color='#8B9294' />}
      </div>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: props.className,
          })}
        >
          {props.children}
        </div>
      )}
    </div>
  );
}
