import { ref } from 'valtio';

import { xDomainifyUrl } from '../../../utils/common';
import { uncheckedIndexAccess_UNSAFE } from '../../../utils/uncheckedIndexAccess_UNSAFE';
import { type StrongBlockId } from './asBlockId';
import {
  type VideoMixerTrackMapGroup,
  type VideoMixerTrackMapGroupItems,
} from './ondVideoMixerGameUtils';

/**
 * Track which blocks' host videos have been added to the video mixer. The
 * VideoMixer should remain unaware of the game system, it's just a mixer! There
 * are named "slots" here for tracks that are not specific to a block: stage,
 * intro, outro. These IDs are used by the game system to know when the first
 * block starts and when the game is over.
 */
export class BlockToVideoMixerTrackMap {
  static CreateVRefed(): BlockToVideoMixerTrackMap {
    return ref(new BlockToVideoMixerTrackMap());
  }

  private m = new Map<string, VideoMixerTrackMapGroup>();

  set(
    key: 'intro' | 'outro' | StrongBlockId,
    item: VideoMixerTrackMapGroup
  ): void {
    this.m.set(key, item);
  }

  get(key: 'intro' | 'outro' | StrongBlockId): VideoMixerTrackMapGroup | null {
    return this.m.get(key) ?? null;
  }

  delete(key: 'intro' | 'outro' | StrongBlockId): boolean {
    return this.m.delete(key);
  }

  entries(): IterableIterator<[string, VideoMixerTrackMapGroup]> {
    return this.m.entries();
  }

  /**
   * Does the existing item for this key match the provided source?
   */
  matches(
    item: VideoMixerTrackMapGroup | null,
    name: keyof VideoMixerTrackMapGroupItems,
    source: string | MediaStream | null | undefined
  ): boolean {
    if (item && !source) return false;
    else if (!item && source) return false;
    else if (
      (item && item.get(name)?.media.source === source) ||
      (typeof source === 'string' &&
        item &&
        item.get(name)?.media.source === xDomainifyUrl(source))
    )
      return true;

    return false;
  }

  /**
   * Assuming groups were added roughly in time-linear order, what's the last
   * item that had the key set? This provides the "newest" item with that key
   * non-null.
   */
  lastNonNull(
    name: keyof VideoMixerTrackMapGroupItems
  ): VideoMixerTrackMapGroup | null {
    let latest: null | VideoMixerTrackMapGroup = null;
    for (const [, group] of this.m) {
      if (
        uncheckedIndexAccess_UNSAFE(group)[name] !== null &&
        uncheckedIndexAccess_UNSAFE(group)[name] !== undefined
      )
        latest = group;
    }
    return latest;
  }

  destroy(): void {
    return this.m.clear();
  }
}
