import {
  type FBReference,
  RTDBServerValueTIMESTAMP,
} from '@lp-lib/firebase-typesafe';

import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';
import {
  type GameLogData,
  type GameLogInfo,
  type GameLogRow,
  type GameLogRowRetrieved,
  type GLInfoNarrow,
} from './GameLogInfos';

/**
 * Firebase will throw if a property is undefined.
 */
export function removeUndefineds(obj0: object) {
  const obj = uncheckedIndexAccess_UNSAFE(obj0);
  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key];
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      removeUndefineds(obj[key]);
    }
  }
  return obj;
}

/**
 * Writes to the game log. You must provide a known kind. If you need to log
 * different kinds, create more Writer instances.
 */
export class GameLogWriter {
  constructor(
    venueId: string,
    refCreator: (path: string) => FBReference<GameLogData>,
    private ref = refCreator(`game-log/${venueId}`)
  ) {}

  /**
   * Append an item to the log. `uniqueBy` allow specifying a property whose
   * value will be used as the newly pushed item's key/id, if it does not
   * already exist. This allows for only adding some items _once_. Generally the
   * `key/id` is opaque.
   */
  append = async (info: GameLogInfo, uniqueBy?: keyof typeof info) => {
    if (uniqueBy) {
      await this.appendIfNotExists(info, uniqueBy);
    } else {
      await this.ref.child(`indices/${info.kind}`).push({
        createdAt: RTDBServerValueTIMESTAMP,
        info: removeUndefineds(info) as never,
      });
    }
  };

  private async appendIfNotExists(
    info: GameLogInfo,
    uniqueBy?: keyof typeof info
  ) {
    // We cannot really attempt this if the ID is not stable/known
    if (!uniqueBy) return this.append(info);
    // This complex type is to help narrow this complex struture. it's too hard
    // for the compiler to figure out that `kind` narrows the union without this
    // hint.
    const ref: FBReference<
      GameLogRow<GLInfoNarrow<GameLogInfo, typeof info.kind>>
    > = this.ref.child(`indices/${info.kind}/${info[uniqueBy]}`);
    return await ref.transaction((existing) => {
      if (existing) return;
      return {
        createdAt: RTDBServerValueTIMESTAMP,
        info: removeUndefineds(info) as never,
      };
    });
  }
}

export class GameLogReader {
  constructor(
    venueId: string,
    refCreator: (path: string) => FBReference<GameLogData>,
    private ref = refCreator(`game-log/${venueId}`)
  ) {}

  query = async (kind: GameLogInfo['kind']) => {
    const snapshot = await this.ref.child(`indices/${kind}`).get();

    const rows = snapshot.val();
    if (!rows) return [];

    // Ignore the firebase key.
    const items: GameLogRowRetrieved<GLInfoNarrow<GameLogInfo, typeof kind>>[] =
      Object.values(rows);
    return items.sort((a, b) => a.createdAt - b.createdAt);
  };
}
