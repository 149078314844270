import React, {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLatest } from 'react-use';

import {
  type MemoryMatchBlock,
  type MemoryMatchBlockDetailScore,
} from '@lp-lib/game';

import { getFeatureQueryParamNumber } from '../../../../hooks/useFeatureQueryParam';
import { useIsController } from '../../../../hooks/useMyInstance';
import { type TeamId } from '../../../../types';
import { uuidv4 } from '../../../../utils/common';
import { uncheckedIndexAccess_UNSAFE } from '../../../../utils/uncheckedIndexAccess_UNSAFE';
import {
  useDatabaseSafeRef,
  useFirebaseBatchWrite,
  useFirebaseValue,
} from '../../../Firebase';
import { increment } from '../../../Firebase/utils';
import { useMyTeamId } from '../../../Player';
import { useIsStreamSessionAlive } from '../../../Session';
import { useTeamMembers, useTeams } from '../../../TeamAPI/TeamV1';
import { useVenueId } from '../../../Venue/VenueProvider';
import { useGameSessionLocalTimer } from '../../hooks';
import { updateBlockDetailScore } from '../../store';
import { useGamePlayEmitter } from '../Common/GamePlay/GamePlayProvider';
import { type GamePlayEndedState } from '../Common/GamePlay/types';
import { BlockKnifeUtils } from '../Shared';
import {
  type Card,
  type CardMap,
  type Game,
  type GameProgressSummary,
  GameState,
  type GameTeamInfo,
  type HiddenModeInfo,
} from './types';
import { log, MemoryMatchUtils } from './utils';

interface MemoryMatchGameControlAPI {
  initGame: (block: MemoryMatchBlock) => Promise<void>;
  startGame: () => Promise<void>;
  stopGame: () => Promise<void>;
  resetGame: (debug: string) => Promise<void>;
  deinitGame: () => Promise<void>;
}

interface MemoryMatchGamePlayAPI {
  revealCard: (card: Card) => Promise<void>;
  unrevealCard: (card: Card) => Promise<void>;
}

type Context = {
  game: Nullable<Game>;
  updateGame: (next: Nullable<Game, false>) => Promise<void>;
  // Array is not supported in Firebase
  // https://firebase.blog/posts/2014/04/best-practices-arrays-in-firebase
  cardMap: CardMap;
  hiddenModeInfo: Nullable<HiddenModeInfo>;
};

const context = React.createContext<Nullable<Context, false>>(null);

function useMemoryMatchContext(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('MemoryMatchContext is not in the tree!');
  return ctx;
}

function useInitGame(options?: { enabled: boolean; readonly: boolean }) {
  const venueId = useVenueId();
  return useFirebaseValue<Nullable<Game, false>>(
    MemoryMatchUtils.GetFBPath(venueId, 'game'),
    {
      enabled: !!options?.enabled,
      seedValue: null,
      seedEnabled: false,
      readOnly: !!options?.readonly,
      resetWhenUmount: true,
    }
  );
}

export function useMemoryMatchGame(): Context['game'] {
  const ctx = useMemoryMatchContext();
  return ctx.game;
}

export function useMemoryMatchCards(): Card[] {
  const cardMap = useMemoryMatchContext().cardMap;
  return useMemo(() => {
    const cards = Object.values(cardMap);
    cards.sort((a, b) => a.index - b.index);
    return cards;
  }, [cardMap]);
}

export function useMemoryMatchHiddenModeInfo(): Nullable<HiddenModeInfo> {
  return useMemoryMatchContext().hiddenModeInfo;
}

export function useMemoryMatchGameControl(): MemoryMatchGameControlAPI {
  const venueId = useVenueId();
  const { updateGame, game } = useMemoryMatchContext();
  const gameId = game?.id;
  const batchWrite = useFirebaseBatchWrite();
  const ref = useDatabaseSafeRef(MemoryMatchUtils.GetFBPath(venueId, 'root'));
  const teams = useLatest(
    useTeams({
      updateStaffTeam: true,
      excludeStaffTeam: true,
    })
  );

  const resetGame = useCallback(
    async (debug: string) => {
      log.info(`reset game: ${debug}`);
      await ref.remove();
    },
    [ref]
  );

  const initGame = useCallback(
    async (
      block: MemoryMatchBlock,
      overrideNumberOfCardPairs = getFeatureQueryParamNumber(
        'memory-match-num-of-pairs'
      )
    ) => {
      const numOfCardPairs =
        overrideNumberOfCardPairs || block.fields.numberOfCardPairs;
      const updates = uncheckedIndexAccess_UNSAFE({});
      const assets = MemoryMatchUtils.PrepareAssets(
        block.fields.cardPairs,
        numOfCardPairs
      );
      for (const team of teams.current) {
        const cards = MemoryMatchUtils.BuildCards(assets);
        updates[MemoryMatchUtils.GetFBPathByTeam(venueId, team.id, 'cards')] =
          cards;
      }
      await batchWrite(updates);
      await updateGame({ id: uuidv4(), state: GameState.Inited });
    },
    [batchWrite, updateGame, teams, venueId]
  );

  const startGame = useCallback(async () => {
    if (!gameId) return;
    await updateGame({
      id: gameId,
      state: GameState.InProgress,
    });
  }, [gameId, updateGame]);

  const stopGame = useCallback(async () => {
    if (!gameId) return;
    await updateGame({ id: gameId, state: GameState.Ended });
  }, [updateGame, gameId]);

  const deinitGame = useCallback(async () => {
    if (!gameId) return;
    await updateGame({ id: gameId, state: GameState.None });
  }, [updateGame, gameId]);

  return {
    initGame,
    startGame,
    stopGame,
    resetGame,
    deinitGame,
  };
}

export function useMemoryMatchGamePlayAPI(
  teamId: TeamId,
  hiddenMode: boolean
): MemoryMatchGamePlayAPI {
  const venueId = useVenueId();
  const cards = useLatest(useMemoryMatchCards());
  const batchWrite = useFirebaseBatchWrite();
  const teamMembers = useLatest(useTeamMembers(teamId));
  const hiddenModeInfo = useMemoryMatchHiddenModeInfo();
  const nextRevealTarget = useCallback(() => {
    const members = teamMembers.current;
    const member = hiddenModeInfo?.lastRevealedMember;
    if (!members) return null;
    let nextIdx = -1;
    if (member) {
      // Do not count the matches
      if (hiddenModeInfo.lastRevealMatched) return member;
      nextIdx = members.findIndex((m) => m.id === member.id);
      if (nextIdx === -1) {
        nextIdx = members.findIndex((m) => m.joinedAt > member.joinedAt);
      }
    }
    nextIdx += 1;
    if (nextIdx >= members.length) nextIdx = 0;
    const next = members[nextIdx];
    log.debug('next reveal to', { curr: member, next, nextIdx });
    if (!next) return null;
    return { id: next.id, joinedAt: next.joinedAt };
  }, [hiddenModeInfo, teamMembers]);

  const revealCard = useCallback(
    async (card: Card) => {
      const revertCards = cards.current.filter((c) => c.matched === 'wrong');
      const revealedCards = cards.current.filter((c) => !!c.reveal);
      if (revealedCards.length >= 2) revertCards.push(...revealedCards);
      const updates: { [key: string]: Card | HiddenModeInfo } = {};

      // unreveal wrong match and revealed cards
      if (revertCards.length >= 0) {
        revertCards.forEach((c) => {
          updates[MemoryMatchUtils.GetFBCardPath(venueId, teamId, c)] =
            MemoryMatchUtils.UnrevealCard(c);
        });
      }
      let revealToAll = false;
      if (hiddenMode) {
        // reveal card to next team member in hidden gameplay
        const next = nextRevealTarget();
        if (!next) {
          revealToAll = true;
        } else {
          updates[MemoryMatchUtils.GetFBCardPath(venueId, teamId, card)] = {
            ...card,
            matched: 'unknown',
            reveal: {
              target: 'someone',
              memberId: next.id,
              revealedAt: Date.now(),
            },
          };
          const updatedHiddenModeInfo: HiddenModeInfo = {
            lastRevealedMember: next,
          };
          const hiddenModeInfoPath = MemoryMatchUtils.GetFBPathByTeam(
            venueId,
            teamId,
            'hidden-mode'
          );
          updates[hiddenModeInfoPath] = updatedHiddenModeInfo;
        }
      } else {
        // reveal card to everyone
        revealToAll = true;
      }
      if (revealToAll) {
        updates[MemoryMatchUtils.GetFBCardPath(venueId, teamId, card)] = {
          ...card,
          matched: 'unknown',
          reveal: { target: 'all', revealedAt: Date.now() },
        };
      }
      await batchWrite(updates);
    },
    [batchWrite, cards, hiddenMode, nextRevealTarget, teamId, venueId]
  );

  const unrevealCard = useCallback(
    async (card: Card) => {
      if (card.matched === 'done') return;
      await batchWrite({
        [MemoryMatchUtils.GetFBCardPath(venueId, teamId, card)]:
          MemoryMatchUtils.UnrevealCard(card),
      });
    },
    [batchWrite, teamId, venueId]
  );

  return {
    revealCard,
    unrevealCard,
  };
}

export function usePairAutoGrading(teamId: TeamId, points: number): void {
  const venueId = useVenueId();
  const cards = useMemoryMatchCards();
  const batchWrite = useFirebaseBatchWrite();
  const latestPoints = useLatest(points);
  const latestHiddenModeInfo = useLatest(useMemoryMatchHiddenModeInfo());

  useEffect(() => {
    async function run() {
      const revealCards = cards.filter((c) => !!c.reveal);
      if (revealCards.length !== 2) return;
      const [a, b] = revealCards;
      const hiddenModePath = MemoryMatchUtils.GetFBPathByTeam(
        venueId,
        teamId,
        'hidden-mode'
      );

      // matched
      if (a.pair === b.pair) {
        const aShowScore =
          (a.reveal?.revealedAt ?? 0) > (b.reveal?.revealedAt ?? 0);
        const updates: { [key: string]: Card | HiddenModeInfo } = {};
        updates[MemoryMatchUtils.GetFBCardPath(venueId, teamId, a)] = {
          ...a,
          reveal: null,
          matched: 'done',
          showScore: aShowScore,
        };
        updates[MemoryMatchUtils.GetFBCardPath(venueId, teamId, b)] = {
          ...b,
          reveal: null,
          matched: 'done',
          showScore: !aShowScore,
        };
        if (latestHiddenModeInfo.current) {
          updates[hiddenModePath] = {
            ...latestHiddenModeInfo.current,
            lastRevealMatched: true,
          };
        }
        const p1 = batchWrite(updates);
        const p2 = updateBlockDetailScore<MemoryMatchBlockDetailScore>(teamId, {
          score: increment(latestPoints.current),
          submittedAt: Date.now(),
        });
        await Promise.all([p1, p2]);
        return;
      }

      // not matched
      const updates: { [key: string]: Card | HiddenModeInfo } = {};
      revealCards.forEach((c) => {
        updates[MemoryMatchUtils.GetFBCardPath(venueId, teamId, c)] = {
          ...c,
          reveal: null,
          matched: 'wrong',
        };
      });
      if (latestHiddenModeInfo.current) {
        updates[hiddenModePath] = {
          ...latestHiddenModeInfo.current,
          lastRevealMatched: false,
        };
      }
      await batchWrite(updates);
    }
    run();
  }, [batchWrite, cards, latestHiddenModeInfo, latestPoints, teamId, venueId]);
}

export function usePairCard(card: Card): Card {
  const cards = useMemoryMatchCards();

  return useMemo(() => {
    const pairCard = cards.find(
      (c) => c.pair === card.pair && c.index !== card.index
    );
    if (!pairCard) throw new Error(`pair card not found: ${card.pair}`);
    return pairCard;
  }, [card.index, card.pair, cards]);
}

export function useEmitGamePlayEndedState(block: MemoryMatchBlock): void {
  const emitter = useGamePlayEmitter();
  const [finalState, setFinalState] = useState<Nullable<GamePlayEndedState>>();
  const goalMedia = BlockKnifeUtils.GetGoalCompletionMedia(block);
  const time = useGameSessionLocalTimer();
  const gameState = useMemoryMatchGame()?.state;
  const cards = useMemoryMatchCards();
  const allMatched = useMemo(() => {
    if (cards.length === 0) return false;
    return !cards.find((c) => c.matched !== 'done');
  }, [cards]);

  // timesup
  useEffect(() => {
    if (finalState) return;
    if (gameState === GameState.Ended) {
      emitter.emit('ended', block.id, 'timesup');
      setFinalState('timesup');
    }
  }, [time, gameState, emitter, block.id, finalState]);

  // finished
  useEffect(() => {
    if (finalState || !allMatched || gameState !== GameState.InProgress) return;
    if (goalMedia)
      emitter.emit('ended-awaiting-goal-media', block.id, 'finished', {
        animationMedia: goalMedia,
      });
    else
      emitter.emit('ended', block.id, 'finished', {
        animationMedia: goalMedia,
      });
    setFinalState('finished');
  }, [
    allMatched,
    block.fields.goalAnimationMedia,
    block.id,
    emitter,
    finalState,
    gameState,
    goalMedia,
  ]);

  useEffect(() => {
    return () => {
      setFinalState(undefined);
    };
  }, [block.id]);
}

export function useGameProgressSummary(): GameProgressSummary {
  const venueId = useVenueId();
  const [teamInfo] = useFirebaseValue<Nullable<GameTeamInfo>>(
    MemoryMatchUtils.GetFBPath(venueId, 'teams'),
    {
      enabled: true,
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );

  return useMemo(() => {
    const summary: GameProgressSummary = {};
    if (!teamInfo) return summary;
    Object.keys(teamInfo).forEach((teamId) => {
      const cards = teamInfo[teamId]?.cards ?? [];
      summary[teamId] = {
        numOfCorrectMatches:
          cards.filter((c) => c.matched === 'done').length / 2,
      };
    });
    return summary;
  }, [teamInfo]);
}

export function MemoryMatchProvider(props: {
  children?: ReactNode;
}): JSX.Element | null {
  const venueId = useVenueId();
  const isController = useIsController();
  const isSessionAlive = useIsStreamSessionAlive();
  const teamId = useMyTeamId() ?? '';
  const [game, updateGame] = useInitGame({
    enabled: isSessionAlive,
    readonly: !isController,
  });
  const [cardMap] = useFirebaseValue<Nullable<CardMap>>(
    MemoryMatchUtils.GetFBPathByTeam(venueId, teamId, 'cards'),
    {
      enabled: isSessionAlive && !!teamId,
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );
  const [hiddenModeInfo] = useFirebaseValue<Nullable<HiddenModeInfo>>(
    MemoryMatchUtils.GetFBPathByTeam(venueId, teamId, 'hidden-mode'),
    {
      enabled: isSessionAlive && !!teamId,
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );

  const ctxValue: Context = useMemo(
    () => ({
      game,
      updateGame,
      cardMap: cardMap ?? {},
      hiddenModeInfo,
    }),
    [cardMap, game, hiddenModeInfo, updateGame]
  );
  return <context.Provider value={ctxValue}>{props.children}</context.Provider>;
}
