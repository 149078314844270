import {
  isServerValue,
  RTDBServerValueTIMESTAMP,
} from '@lp-lib/firebase-typesafe';
import { type Logger } from '@lp-lib/logger-base';

import { BrowserTimeoutCtrl } from '../../../../../utils/BrowserTimeoutCtrl';
import {
  randomBetween,
  uuidv4,
  weightedPandomPick,
} from '../../../../../utils/common';
import { uncheckedIndexAccess_UNSAFE } from '../../../../../utils/uncheckedIndexAccess_UNSAFE';
import { type FirebaseValueHandle } from '../../../../Firebase';
import {
  type GroupId,
  type Ingredient,
  type Order,
  type TruckId,
} from '../types';

export class OrderManager {
  private automationCtrl?: BrowserTimeoutCtrl;

  private candidates: { ingredient: Ingredient; count: number }[];
  private seqNum = 1;

  constructor(
    private groupId: GroupId,
    private truckId: TruckId,
    ingredients: Ingredient[],
    private maxIngredientsPerOrder: number,
    private maxActiveOrdersCount: number,
    private log: Logger,
    private fbHandle: FirebaseValueHandle<Record<string, Order>>
  ) {
    this.candidates = ingredients.map((ingredient) => {
      return { ingredient: ingredient, count: 0 };
    });
  }

  startAutomation(cooldownSec = 2): () => void {
    this.stopAutomation();

    const ctrl = new BrowserTimeoutCtrl();
    const tick = async () => {
      this.fill(cooldownSec);
      ctrl.set(tick, 1000);
    };
    ctrl.set(tick, 1000);
    this.automationCtrl = ctrl;
    return ctrl.clear;
  }

  stopAutomation(): void {
    this.automationCtrl?.clear();
  }

  private async fill(cooldownSec: number): Promise<Order | null> {
    const orders = await this.getActiveOrdersFromFB();
    if (orders.length >= this.maxActiveOrdersCount) {
      this.log.debug('order full', {
        groupId: this.groupId,
        truckId: this.truckId,
      });
      return null;
    }

    let lastOrderCreatedAt = 0;
    orders.forEach((o) => {
      if (isServerValue(o.createdAt)) return;
      if (o.createdAt > lastOrderCreatedAt) {
        lastOrderCreatedAt = o.createdAt;
      }
    });
    const timeElasped = Date.now() - lastOrderCreatedAt;
    if (timeElasped < cooldownSec * 1000) {
      this.log.debug('order cooldown', {
        groupId: this.groupId,
        truckId: this.truckId,
        lastOrderCreatedAt,
        timeElasped,
      });
      return null;
    }

    const newOrders = await this.build(1);
    this.log.debug('order created', {
      groupId: this.groupId,
      truckId: this.truckId,
      lastOrderCreatedAt,
      timeElasped,
      order: newOrders[0],
    });

    return newOrders?.[0];
  }

  async build(
    num: number,
    ingredientsCount = randomBetween(1, this.maxIngredientsPerOrder)
  ): Promise<Order[]> {
    if (num === 0) return [];

    const orders: Order[] = [];
    for (let i = 0; i < num; i++) {
      const orderId = uuidv4();

      const ingredients = this.pickIngredients(ingredientsCount);
      orders.push({
        id: orderId,
        truckId: this.truckId,
        seqNum: this.seqNum++,
        ingredients: ingredients,
        createdAt: RTDBServerValueTIMESTAMP,
      });
    }

    this.addOrdersToFB(orders);
    return orders;
  }

  private pickIngredients(count: number): Ingredient[] {
    const ingredients: Ingredient[] = [];
    for (let i = 0; i < count; i++) {
      ingredients.push(this.pickIngredient());
    }
    return ingredients;
  }

  private pickIngredient(): Ingredient {
    const weighted = this.candidates.map((c) => ({
      ingredient: c.ingredient,
      weight: c.count === 0 ? 1 : 1 / c.count,
      count: c.count,
    }));
    const picked = weightedPandomPick(weighted);
    this.log.debug('pick ingredient for order', {
      group: this.groupId,
      truckId: this.truckId,
      candidates: weighted,
      picked,
    });
    const candidate = this.candidates.find(
      (c) => c.ingredient === picked.ingredient
    );
    if (candidate) candidate.count += 1;
    return picked.ingredient;
  }

  private async getActiveOrdersFromFB(): Promise<Order[]> {
    const orderMap = await this.fbHandle.get();
    return Object.values(orderMap || {}).filter(
      (o) => o.truckId === this.truckId
    );
  }

  private async addOrdersToFB(orders: Order[]): Promise<void> {
    const updates = uncheckedIndexAccess_UNSAFE({});
    orders.forEach((o) => (updates[o.id] = o));
    await this.fbHandle.update(updates);
  }
}
