import { useEffect } from 'react';

import { BlockType } from '@lp-lib/game';

import { assertExhaustive } from '../../../../../utils/common';
import { useGameSessionBlock } from '../../../hooks';
import { useScoreSummarySyncer } from '../../../store/ScoreSummarySyncer';
import { AIChatBlockGameControl } from '../../AIChat';
import { CreativePromptBlockGameControl } from '../../CreativePrompt/CreativePromptBlockGameControl';
import { DrawingPromptBlockGameControl } from '../../DrawingPrompt';
import { GuessWhoBlockGameControl } from '../../GuessWho/GuessWhoBlockGameControl';
import { HeadToHeadBlockGameControl } from '../../HeadToHead';
import { HiddenPictureBlockGameControl } from '../../HiddenPicture/HiddenPictureBlockGameControl';
import { IcebreakerBlockGameControl } from '../../Icebreaker';
import { JeopardyBlockGameControl } from '../../Jeopardy/JeopardyBlockGameControl';
import { MemoryMatchBlockGameControl } from '../../MemoryMatch';
import { MultipleChoiceGameControl } from '../../MultipleChoice';
import { OverRoastedBlockGameControl } from '../../OverRoasted';
import { PuzzleBlockGameControl } from '../../Puzzle';
import { QuestionBlockGameControl } from '../../Question';
import { RandomizerBlockControl } from '../../Randomizer/RandomizerBlockControl';
import { RapidBlockGameControl } from '../../Rapid/RapidBlockGameControl';
import { RoundRobinQuestionBlockGameControl } from '../../RoundRobinQuestion';
import { ScoreboardBlockGameControl } from '../../Scoreboard/ScoreboardBlockGameControl';
import { SpotlightBlockControl } from '../../Spotlight/SpotlightBlockControl';
import { SpotlightBlockV2Control } from '../../SpotlightV2/SpotlightBlockV2Control';
import { TeamRelayBlockGameControl } from '../../TeamRelay';
import { TitleBlockV2GameControl } from '../../TitleV2/TitleBlockV2GameControl';

export function GameControl(props: {
  venueId: string;
  sessionId?: string;
}): JSX.Element | null {
  const { venueId, sessionId } = props;
  const gameSessionBlock = useGameSessionBlock();
  const blockId = gameSessionBlock?.id;

  const syncer = useScoreSummarySyncer(venueId, sessionId);

  useEffect(() => {
    if (!blockId) return;
    const off = syncer?.watch(blockId);
    return () => off?.();
  }, [blockId, syncer]);

  if (gameSessionBlock === null) return null;

  const blockType = gameSessionBlock.type;

  switch (blockType) {
    case BlockType.CREATIVE_PROMPT:
      return <CreativePromptBlockGameControl block={gameSessionBlock} />;
    case BlockType.RAPID:
      return <RapidBlockGameControl block={gameSessionBlock} />;
    case BlockType.QUESTION:
      return <QuestionBlockGameControl block={gameSessionBlock} />;
    case BlockType.SCOREBOARD:
      return <ScoreboardBlockGameControl block={gameSessionBlock} />;
    case BlockType.TITLE_V2:
      return <TitleBlockV2GameControl block={gameSessionBlock} />;
    case BlockType.SPOTLIGHT:
      return <SpotlightBlockControl block={gameSessionBlock} />;
    case BlockType.SPOTLIGHT_V2:
      return <SpotlightBlockV2Control block={gameSessionBlock} />;
    case BlockType.TEAM_RELAY:
      return <TeamRelayBlockGameControl block={gameSessionBlock} />;
    case BlockType.RANDOMIZER:
      return <RandomizerBlockControl block={gameSessionBlock} />;
    case BlockType.MULTIPLE_CHOICE:
      return <MultipleChoiceGameControl block={gameSessionBlock} />;
    case BlockType.MEMORY_MATCH:
      return <MemoryMatchBlockGameControl block={gameSessionBlock} />;
    case BlockType.PUZZLE:
      return <PuzzleBlockGameControl block={gameSessionBlock} />;
    case BlockType.ROUND_ROBIN_QUESTION:
      return (
        <RoundRobinQuestionBlockGameControl
          key={gameSessionBlock.id}
          block={gameSessionBlock}
        />
      );
    case BlockType.INSTRUCTION:
      return null;
    case BlockType.OVERROASTED:
      return (
        <OverRoastedBlockGameControl
          key={gameSessionBlock.id}
          block={gameSessionBlock}
        />
      );
    case BlockType.DRAWING_PROMPT:
      return (
        <DrawingPromptBlockGameControl
          key={gameSessionBlock.id}
          block={gameSessionBlock}
        />
      );
    case BlockType.HIDDEN_PICTURE:
      return (
        <HiddenPictureBlockGameControl
          key={gameSessionBlock.id}
          block={gameSessionBlock}
        />
      );
    case BlockType.AI_CHAT:
      return (
        <AIChatBlockGameControl
          key={gameSessionBlock.id}
          block={gameSessionBlock}
        />
      );
    case BlockType.GUESS_WHO:
      return (
        <GuessWhoBlockGameControl
          key={gameSessionBlock.id}
          block={gameSessionBlock}
        />
      );
    case BlockType.ICEBREAKER:
      return (
        <IcebreakerBlockGameControl
          key={gameSessionBlock.id}
          block={gameSessionBlock}
        />
      );
    case BlockType.MARKETING:
      return null;
    case BlockType.JEOPARDY:
      return (
        <JeopardyBlockGameControl
          key={gameSessionBlock.id}
          block={gameSessionBlock}
        />
      );
    case BlockType.HEAD_TO_HEAD:
      return (
        <HeadToHeadBlockGameControl
          key={gameSessionBlock.id}
          block={gameSessionBlock}
        />
      );
    case BlockType.SLIDE:
    case BlockType.DRAW_TO_WIN:
    case BlockType.ROLEPLAY:
      return null;
    default: {
      assertExhaustive(blockType);
      return null;
    }
  }
}
