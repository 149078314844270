import { useLocation, useNavigate, useSearchParams } from '@remix-run/react';
import isEqual from 'lodash/isEqual';
import React, {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { assertExhaustive } from '@lp-lib/crowd-frames-schema/src/utils';

import { type Repository } from '../../../hooks/useArrayState';
import { useInstance } from '../../../hooks/useInstance';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useQueryParam } from '../../../hooks/useQueryParam';
import logger from '../../../logger/logger';
import {
  type Game,
  type GameLike,
  GameLikeQueryType,
  type GameLikeType,
  type GamePack,
} from '../../../types/game';
import { type Tag, tagMatched, virtualTags } from '../../../types/tag';
import { stripLeft } from '../../../utils/common';
import { Emitter } from '../../../utils/emitter';
import { uncheckedIndexAccess_UNSAFE } from '../../../utils/uncheckedIndexAccess_UNSAFE';
import { type BreadcrumbChain } from '../../Breadcrumbs';
import { useUser } from '../../UserContext';
import { GameEditorStoreProvider } from '../GameEditorStore';
import {
  FilterPresets,
  type GameLikeFilterOptions,
  parsePlayerCountFilter,
} from './types';

type GameLikeCallback<T extends GameLike> = (instance: T) => void;

type GameLikeEvents<T extends GameLike> = {
  created: GameLikeCallback<T>;
  updated: GameLikeCallback<T>;
  duplicated: GameLikeCallback<T>;
  deleted: GameLikeCallback<T>;
  published: GameLikeCallback<T>;
};

type GameLikeOperation = 'publish' | 'duplicate' | 'delete' | 'active';
type GameLikeWorkspace<T extends GameLike> = {
  [key in GameLikeOperation]?: T | null;
};

type GameWorkspace = GameLikeWorkspace<Game> & {
  create?: boolean | CreatingGameRequest;
};

type GamePackWorkspace = GameLikeWorkspace<GamePack> & {
  create?: boolean;
  edit?: GamePack | null;
  preview?: GamePack | null;
};

export type GameLikeClickedProps<T extends GameLike> = {
  onItemClick?: (item: T) => void;
};

export interface CreatingGameRequest {
  initialValues?: Game;
  openEditorAfterCreated?: boolean;
  initBlock?: boolean;
}

export type WritableEmbedContext = {
  tag?: Tag;
  search?: string;
  noOnboardingTasks?: boolean;
  noPlayButton?: boolean;
  isLegacyPublicHome?: boolean;
};

export type EmbedContext<T extends GameLike> = WritableEmbedContext & {
  handleAdd?: (item: T) => void;
  handleRemove?: (item: T) => void;
  picked?: (id: string) => boolean;
  handleLoad?: (item: T) => Promise<boolean>;
  handleSchedule?: (item: T) => void;
};

// default: for Live
// public: for OnD
export type GameLikePageType = 'default' | 'public';

// public-home: /home, /tags/*, /search
// admin: /admin/*
// host: /host/*
export type GameLikeMode = 'public-home' | 'admin' | 'host';

type GameLikeSharedContext = {
  breadcrumbs: BreadcrumbChain;
  pageType: GameLikePageType;
};

export type GameLikeContext<T extends GameLike> = GameLikeSharedContext &
  (
    | {
        embed: true;
        embedCtx: EmbedContext<T>;
        updateEmbedCtx: (ctx: WritableEmbedContext | null) => void;
      }
    | {
        embed: false;
        mode: GameLikeMode;
        routePrefix: string;
        editRoutePrefix: string;
      }
  );

interface GameLikeWorkspaceContext<
  T extends GameLike,
  W extends GameLikeWorkspace<T> = GameLikeWorkspace<T>
> {
  workspace: W;
  buildWorkspaceSetter: <P extends keyof W>(op: P) => (v: W[P]) => void;
  emitter: Emitter<GameLikeEvents<T>>;
  filters: Partial<GameLikeFilterOptions>;
  updateFilterOptions: (options: Partial<GameLikeFilterOptions> | null) => void;
}

function useSetupGameLikeWorkspaceContext<
  T extends GameLike,
  W extends GameLikeWorkspace<T>
>(): GameLikeWorkspaceContext<T, W> {
  const [workspace, setWorkspace] = useState<W>({} as W);
  const emitter = useInstance(() => new Emitter<GameLikeEvents<T>>());
  const [filters, setFilters] = useState<Partial<GameLikeFilterOptions>>({});

  const buildWorkspaceSetter = useCallback(<P extends keyof W>(op: P) => {
    return (v: W[P]) => {
      setWorkspace((prev) => {
        return { ...prev, [op]: v };
      });
    };
  }, []);

  useEffect(() => {
    return () => {
      emitter.clear();
    };
  }, [emitter]);

  const updateFilterOptions = useCallback(
    (options: Partial<GameLikeFilterOptions> | null) => {
      setFilters((prev) => {
        if (options === null) return {};
        return { ...prev, ...options };
      });
    },
    []
  );

  const ctxValue: GameLikeWorkspaceContext<T, W> = {
    workspace,
    buildWorkspaceSetter,
    emitter,
    filters,
    updateFilterOptions,
  };

  return ctxValue;
}

export function useActiveInstanceWatcher<
  K extends GameLikeType,
  T extends GameCenterContext[K]['workspace']['active']
>(props: {
  type: K;
  key: string;
  getInstanceById: (id: string) => Promise<NonNullable<T>>;
  disabled?: boolean;
}): void {
  const { type, key, getInstanceById, disabled } = props;
  const [searchParams] = useSearchParams();
  const id = searchParams.get(key);

  const user = useUser();
  const [inited, setInited] = useState(false);
  const [activeInstance, setActiveInstance_unstable] = useGameLikeWorkspace(
    type,
    'active'
  );

  // NOTE(drew): This is an unstable reference, but I'm not sure why: the types
  // are a bit too hard to follow. Instead wrap it. Otherwise you get an
  // infinite loop.
  const setActiveInstance = useLiveCallback(setActiveInstance_unstable);

  const activeInstanceRef = useRef(activeInstance);
  // This is an async, unqueued op, so it's best to prevent multiples.
  const runningRef = useRef(false);

  useEffect(() => {
    if (disabled || runningRef.current) return;
    async function init() {
      runningRef.current = true;
      if (id) {
        if (!activeInstanceRef.current) {
          try {
            const instance = await getInstanceById(id);
            if (!activeInstanceRef.current) {
              if (instance.uid === user.id || instance.isPrime) {
                setActiveInstance(instance);
              } else {
                setActiveInstance(null);
              }
            }
          } catch (error) {
            logger.error('fetch error', error);
          }
        }
      } else {
        if (activeInstanceRef.current) {
          setActiveInstance(null);
        }
      }
      setInited(true);
      runningRef.current = false;
    }
    init();
    return () => {
      setInited(false);
    };
  }, [disabled, getInstanceById, id, setActiveInstance, user.id]);

  useEffect(() => {
    activeInstanceRef.current = activeInstance;
  }, [activeInstance]);

  const navigate = useNavigate();
  useEffect(() => {
    if (!inited || runningRef.current) return;

    searchParams.set(key, activeInstance?.id ?? '');
    if (!activeInstance?.id) searchParams.delete(key);

    navigate(
      { search: searchParams.toString(), hash: window.location.hash },
      { replace: true }
    );
  }, [activeInstance?.id, inited, key, navigate, searchParams]);
}

export function useGameLikeEmitterForDiscover<T extends GameLike>(
  emitter: Emitter<GameLikeEvents<T>>,
  dao: Repository<T>,
  tagId: number
): void {
  const user = useUser();
  useEffect(() => {
    const duplicateItem = (item: T) => {
      const handle = item.isPrime
        ? tagMatched(item, tagId)
        : tagId === virtualTags.my.id && item.uid === user.id;
      if (!handle) return;
      dao.addItem(item);
    };
    const publishItem = (item: T) => {
      const handle = item.isPrime && tagMatched(item, tagId);
      if (!handle) return;
      dao.addItem(item);
    };
    const updateItem = (item: Partial<T>) => {
      dao.updateItem(item);
    };
    const ac = new AbortController();
    const { signal } = ac;
    emitter.on('created', dao.addItem, { signal });
    emitter.on('updated', updateItem, { signal });
    emitter.on('duplicated', duplicateItem, { signal });
    emitter.on('published', publishItem, { signal });
    emitter.on('deleted', dao.deleteItem, { signal });
    return () => ac.abort();
  }, [emitter, user.id, dao, tagId]);
}

export function useGameLikeEmitterForList<T extends GameLike>(
  emitter: Emitter<GameLikeEvents<T>>,
  dao: Repository<T>,
  type: GameLikeQueryType,
  keyword?: string | number | null
): void {
  const user = useUser();
  useEffect(() => {
    const duplicateItem = (item: T) => {
      let handle = false;
      switch (type) {
        case GameLikeQueryType.My:
          handle = item.uid === user.id;
          break;
        case GameLikeQueryType.ByTags:
          handle =
            item.isPrime &&
            (item.tags || []).findIndex((t) => t.id === keyword) >= 0;
          break;
        case GameLikeQueryType.Search:
          break;
        case GameLikeQueryType.Untagged:
          handle = true;
          break;
        case GameLikeQueryType.Played:
          break;
        default:
          assertExhaustive(type);
          break;
      }
      if (!handle) return;
      dao.addItem(item);
    };

    const updateItem = (item: Partial<T>) => {
      dao.updateItem(item);
    };
    emitter.on('created', dao.addItem);
    emitter.on('updated', updateItem);
    emitter.on('duplicated', duplicateItem);
    emitter.on('deleted', dao.deleteItem);
    return () => {
      emitter.off('created', dao.addItem);
      emitter.off('updated', updateItem);
      emitter.off('duplicated', duplicateItem);
      emitter.off('deleted', dao.deleteItem);
    };
  }, [dao, emitter, keyword, type, user.id]);
}

export function useSetupGameLikeSearch<K extends GameLikeType>(props: {
  type: K;
  embed?: boolean;
  search?: string;
  breadcrumb?: string;
}): {
  q: string;
  breadcrumb: string;
  inited: boolean;
  showFilter: boolean;
  filterOptions: GameCenterContext[K]['filters'];
} {
  const location = useLocation();
  const search = props.search || location.search;
  const q = (useQueryParam('q', search) || '').trim();
  const [filterOptions, updateFilterOptions] = useGameLikeFilterOptions(
    props.type
  );
  const navigate = useNavigate();
  const searchRef = useRef(search);
  const filterOptionsRef = useRef(filterOptions);
  const [inited, setInited] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const filtersAsQueryString = useGameLikeFiltersAsQueryString(
    props.type,
    search
  );

  useEffect(() => {
    searchRef.current = location.search;
  }, [location.search]);

  useEffect(() => {
    filterOptionsRef.current = filterOptions;
  }, [filterOptions]);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const entries = Object.entries(FilterPresets);
    const newFilterOptions: Partial<GameLikeFilterOptions> = {};
    let n = 0;
    for (const [name, filter] of entries) {
      const keys = params.getAll(name);
      const options: typeof filter.options = [];
      keys.forEach((k) => {
        // note: types are a struggle here and seem to be working against us.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const option = (filter.options as any[]).find((o) => o.key === k);
        if (option) options.push(option);
      });
      if (options.length > 0) {
        uncheckedIndexAccess_UNSAFE(newFilterOptions)[name] = options;
        n++;
      }
    }
    // not all search filters are presets.
    const playerCount = parsePlayerCountFilter(params.get('playerCount'));
    if (playerCount) {
      newFilterOptions.playerCount = [playerCount];
      n++;
    }

    if (!isEqual(newFilterOptions, filterOptionsRef.current)) {
      updateFilterOptions(newFilterOptions);
    }
    setInited(true);
    setShowFilter(n > 0);
  }, [updateFilterOptions, search]);

  useEffect(() => {
    return () => {
      setInited(false);
      setShowFilter(false);
      updateFilterOptions(null);
    };
  }, [updateFilterOptions]);

  useEffect(() => {
    if (props.embed) return;
    if (filtersAsQueryString === stripLeft(searchRef.current, '?')) return;
    navigate({
      pathname: location.pathname,
      search: filtersAsQueryString,
    });
  }, [props.embed, filtersAsQueryString, location.pathname, navigate]);

  let breadcrumb = props.breadcrumb ?? 'Search Result';
  if (q.length > 0) {
    breadcrumb = `${breadcrumb}: ${q}`;
  }

  return { q, breadcrumb, inited, showFilter, filterOptions };
}

interface GameCenterContext {
  game: GameLikeWorkspaceContext<Game, GameWorkspace>;
  gamePack: GameLikeWorkspaceContext<GamePack, GamePackWorkspace>;
}

const Context = React.createContext<GameCenterContext | null>(null);

const useGameCenterContext = (): GameCenterContext => {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('GameCenterContext is not in the tree!');
  }
  return ctx;
};

export const useGameLikeWorkspace = <
  K extends GameLikeType,
  P extends keyof GameCenterContext[K]['workspace']
>(
  type: K,
  op: P
): [
  GameCenterContext[K]['workspace'][P],
  (v: GameCenterContext[K]['workspace'][P]) => void
] => {
  const ctx = useGameCenterContext();
  return [
    uncheckedIndexAccess_UNSAFE(ctx[type].workspace)[op as string],
    // TODO: typesafe
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ctx[type].buildWorkspaceSetter(op),
  ];
};

export const useGameLikeEventEmitter = <K extends GameLikeType>(
  type: K
): GameCenterContext[K]['emitter'] => {
  const ctx = useGameCenterContext();
  return ctx[type].emitter;
};

export const useGameLikeFilterOptions = <K extends GameLikeType>(
  type: K
): [
  GameCenterContext[K]['filters'],
  GameCenterContext[K]['updateFilterOptions']
] => {
  const ctx = useGameCenterContext();
  return [ctx[type].filters, ctx[type].updateFilterOptions];
};

export function useGameLikeFiltersAsQueryString(
  type: GameLikeType,
  initial?: string
): string {
  const ref = useRef(initial);
  const [filterOptions] = useGameLikeFilterOptions(type);

  useEffect(() => {
    ref.current = initial;
  }, [initial]);

  return useMemo(() => {
    const params = new URLSearchParams(ref.current);
    const entries = Object.entries(filterOptions);
    for (const [name, v] of entries) {
      params.delete(name);
      const keys = v?.map((o) => o.key) || [];
      for (const key of keys) {
        params.append(name, key);
      }
    }
    return params.toString();
  }, [filterOptions]);
}

export const GameCenterContextProvider = (props: {
  children?: ReactNode;
}): JSX.Element => {
  const game = useSetupGameLikeWorkspaceContext<Game, GameWorkspace>();
  const gamePack = useSetupGameLikeWorkspaceContext<
    GamePack,
    GamePackWorkspace
  >();

  const ctxValue: GameCenterContext = useMemo(
    () => ({ game, gamePack }),
    [game, gamePack]
  );
  return (
    <Context.Provider value={ctxValue}>
      <GameEditorStoreProvider>{props.children}</GameEditorStoreProvider>
    </Context.Provider>
  );
};
