import { EnumsIcebreakerGameStyle } from '@lp-lib/api-service-client/public';
import {
  type IcebreakerBlock,
  type IcebreakerCard,
  IcebreakerMode,
  IcebreakerOnStageSelection,
  type IcebreakerOption,
} from '@lp-lib/game';

import logger from '../../../../logger/logger';
import { fromMediaDTO } from '../../../../utils/api-dto';
import { MediaUtils } from '../../../../utils/media';
import { type FirebaseService, FirebaseValueHandle } from '../../../Firebase';
import {
  type IcebreakerGamePlayCard,
  type IcebreakerGamePlayCardMap,
  type IcebreakerGamePlayOption,
  type IcebreakerGameProgress,
  type IcebreakerRoot,
} from './types';

export const log = logger.scoped('icebreaker');

export const maxCardOptions = 4;

export class IcebreakerFBUtils {
  static Path(
    venueId: string,
    kind: 'root' | 'cards' | 'progress' | 'game' | 'teams'
  ): string {
    if (kind === 'root') return `icebreaker/${venueId}`;
    return `icebreaker/${venueId}/${kind}`;
  }

  static RootHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<IcebreakerRoot> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'root'))
    );
  }

  static CardsHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<IcebreakerGamePlayCardMap> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'cards'))
    );
  }

  static ProgressHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<IcebreakerGameProgress> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'progress'))
    );
  }
}

export class IcebreakerUtils {
  static IcebreakerCardToGamePlay(
    card: IcebreakerCard,
    index: number,
    block: IcebreakerBlock
  ): IcebreakerGamePlayCard | null {
    const gamePlayOptions = card.options
      .map(this.IcebreakerOptionToGamePlay)
      .filter((option) =>
        this.IsIcebreakerGamePlayOptionValid(option, card, block)
      );
    if (!gamePlayOptions.length) {
      return null;
    }
    return {
      ...card,
      options: gamePlayOptions,
      index,
      phase: 'active',
    };
  }

  static IsIcebreakerGamePlayOptionValid(
    option: IcebreakerGamePlayOption,
    card: IcebreakerCard,
    block: IcebreakerBlock
  ): boolean {
    if (!!option.text) return true;
    if (!this.ShouldSelectOnStagePlayer(block))
      return !!option.audienceBackgroundUrl;
    if (!card.hiddenToAudience) return !!option.onStageBackgroundUrl;
    return !!option.onStageBackgroundUrl || !!option.audienceBackgroundUrl;
  }

  static IcebreakerOptionToGamePlay(
    option: IcebreakerOption
  ): IcebreakerGamePlayOption {
    return {
      text: option.text,
      onStageBackgroundUrl:
        MediaUtils.PickMediaUrl(
          fromMediaDTO(option.onStageBackground?.media)
        ) || '',
      audienceBackgroundUrl:
        MediaUtils.PickMediaUrl(
          fromMediaDTO(option.audienceBackground?.media)
        ) || '',
      hiddenToAudienceText: option.hiddenToAudienceText || '',
    };
  }

  static ShouldRevealAutoProgress(
    card: IcebreakerGamePlayCard,
    block: IcebreakerBlock
  ) {
    if (block.fields.onStageCardSelection) return false;
    if (card.hiddenToAudience) return true;
    return false;
  }

  static ShouldSelectOnStagePlayer(block: IcebreakerBlock) {
    return block.fields.onStageSelection !== IcebreakerOnStageSelection.None;
  }

  static MaxCardOptions(fields: {
    mode?: IcebreakerMode;
    style?: EnumsIcebreakerGameStyle;
  }) {
    if (this.IsTabooStyle(fields)) {
      return 6;
    }
    return 4;
  }

  static IsTabooStyle(fields: {
    mode?: IcebreakerMode;
    style?: EnumsIcebreakerGameStyle;
  }) {
    return (
      fields.mode === IcebreakerMode.HeadsUp &&
      fields.style === EnumsIcebreakerGameStyle.IcebreakerGameStyleTaboo
    );
  }

  static GetCardOptionBackgroundStyle(index: number) {
    return [
      'linear-gradient(180deg, #ff3975 0%, #bd0039 100%)',
      'linear-gradient(180deg, #3988ff 0%, #0042a5 100%)',
      'linear-gradient(180deg, #FBB707 0%, #906802 100%)',
      'linear-gradient(180deg, #8C6FFF 0%, #452AB1 100%)',
    ][index % 4];
  }
}
