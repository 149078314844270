import isNumber from 'lodash/isNumber';
import pluralize from 'pluralize';
import { useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from 'react-use';
import { match } from 'ts-pattern';

import { type IcebreakerBlock } from '@lp-lib/game';

import rotateStar from '../../../../assets/img/rotate-star.png';
import { ClientTypeUtils } from '../../../../types';
import { getStaticAssetPath } from '../../../../utils/assets';
import { useUser } from '../../../UserContext';
import { useMyClientType } from '../../../Venue/VenuePlaygroundProvider';
import { IcebreakerBlockVoterList } from './IcebreakerBlockParticipants';
import {
  useCurrentCard,
  useCurrentPlayer,
  useIcebreakerSharedAPI,
  useIcebreakerTextFormat,
  useIsGamePlaying,
  useIsMyTurn,
  useMyVotedIndex,
  useVotedOffStageParticipants,
} from './IcebreakerBlockProvider';
import {
  type IcebreakerGamePlayCard,
  type IcebreakerGamePlayOption,
} from './types';
import { IcebreakerUtils } from './utils';

function useCardSize(showCount: number) {
  return match(showCount)
    .with(1, () => ({
      width: 384,
      height: 384,
    }))
    .with(2, () => ({
      width: 384,
      height: 384,
    }))
    .otherwise(() => ({
      width: 360,
      height: 200,
    }));
}

function useBackgroundStyle(index: number) {
  return IcebreakerUtils.GetCardOptionBackgroundStyle(index);
}

function getTextSize(text: string) {
  if (text.length <= 50) {
    return 'text-3.5xl';
  }
  if (text.length <= 100) {
    return 'text-xl';
  }
  if (text.length <= 150) {
    return 'text-base';
  }
  if (text.length <= 250) {
    return 'text-sm';
  }

  return 'text-sms';
}

function IcebreakerCardChangeAnimation() {
  const ref = useRef<HTMLDivElement | null>(null);
  const card = useCurrentCard();
  const prevCardId = usePrevious(card?.id);
  const [animating, setAnimating] = useState(false);

  useEffect(() => {
    if (prevCardId === card?.id || animating || !ref.current) return;
    setAnimating(true);
    const a = ref.current.animate(
      [
        {
          backgroundPosition: '100% 0%',
        },
        {
          backgroundPosition: '0% 100%',
        },
      ],
      {
        duration: 250,
        fill: 'forwards',
      }
    );
    a.finished.finally(() => {
      setAnimating(false);
    });
  }, [prevCardId, card?.id, animating]);

  return (
    <div
      className={`absolute inset-0 overflow-hidden rounded-3.75xl ${
        animating ? '' : 'hidden pointer-events-none'
      }`}
    >
      <div
        ref={ref}
        className='w-full h-full'
        style={{
          background: `linear-gradient(
            225deg,
            rgba(255,255,255,0) 0%,
            rgba(255,255,255,0) 34%,
            rgba(255,255,255,0.65) 50%,
            rgba(255,255,255,0) 66%,
            rgba(255,255,255,0) 100%
          )`,
          backgroundSize: '200% 200%',
        }}
      />
    </div>
  );
}

function IcebreakerBlockOption(props: {
  index: number;
  total: number;
  option: IcebreakerGamePlayOption;
  card: IcebreakerGamePlayCard;
  block: IcebreakerBlock;
  hidden: boolean;
}) {
  const { option, index, total, card, block, hidden } = props;

  const isHost = ClientTypeUtils.isHost(useMyClientType());
  const isMyTurn = useIsMyTurn();
  const isHiddenOption =
    !isHost && !isMyTurn && card.hiddenToAudience && card.phase === 'active';
  const api = useIcebreakerSharedAPI();
  const isGamePlaying = useIsGamePlaying();
  const myVotedIndex = useMyVotedIndex();
  const offStageVotedParticipants = useVotedOffStageParticipants(index);
  const bgStyle = useBackgroundStyle(index);
  const user = useUser();
  const { width, height } = useCardSize(total);
  const currentPlayer = useCurrentPlayer();

  const clickable =
    !isHost &&
    isGamePlaying &&
    card.phase === 'active' &&
    (isMyTurn
      ? block.fields.onStageCardSelection
      : block.fields.offStageCardVoting);

  const background = useMemo(() => {
    if (isHiddenOption) {
      return `url(${
        option.audienceBackgroundUrl ||
        getStaticAssetPath('images/icebreaker-option-cover.png')
      }) center center / cover no-repeat`;
    } else {
      if (option.onStageBackgroundUrl) {
        return `url(${option.onStageBackgroundUrl}) center center / cover no-repeat`;
      }
    }
    return bgStyle;
  }, [
    bgStyle,
    isHiddenOption,
    option.audienceBackgroundUrl,
    option.onStageBackgroundUrl,
  ]);
  const format = useIcebreakerTextFormat();
  const text = format(
    isHiddenOption ? option.hiddenToAudienceText : option.text,
    currentPlayer
  );

  const handleClick = async () => {
    if (!clickable) return;

    await api.vote(user.id, index);
    if (isMyTurn && block.fields.cardClickProgressesGame) {
      await api.revealCard(index, user.id);
    }
  };

  return (
    <div
      style={{
        background,

        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)',
        width,
        height,
      }}
      className={`relative p-4
        rounded-3.75xl flex justify-center items-center text-center
        ${
          clickable &&
          myVotedIndex !== null &&
          (myVotedIndex === index ? 'border-2 border-white' : 'opacity-50')
        }
        ${clickable ? 'cursor-pointer' : ''}
        
        ${hidden ? 'animatejit-[fade-out_1s_ease_1_forwards]' : ''}
         transition-opacity duration-200
      `}
      onClick={handleClick}
    >
      {text && (
        <p
          className={`${getTextSize(text)} font-bold`}
          style={{
            textShadow: '0px 0px 10px #000',
          }}
        >
          {text}
        </p>
      )}

      {block.fields.offStageCardVoting && card.phase === 'active' && (
        <p className='absolute bottom-4 text-base font-bold'>
          {pluralize('player', offStageVotedParticipants.length, true)}
        </p>
      )}
      <IcebreakerCardChangeAnimation />
    </div>
  );
}

function IcebreakerBlockOptionRevealOnStage(props: {
  option: IcebreakerGamePlayOption;
  index: number;
}) {
  const { option, index } = props;

  const bgStyle = useBackgroundStyle(index);
  const background = option.onStageBackgroundUrl
    ? `url(${option.onStageBackgroundUrl}) center center / cover no-repeat`
    : bgStyle;
  const currentPlayer = useCurrentPlayer();
  const format = useIcebreakerTextFormat();
  const text = format(option.text, currentPlayer);

  const participants = useVotedOffStageParticipants(index);

  return (
    <div className='absolute flex justify-center gap-5 animatejit-[fade-in_1s_ease_1]'>
      <div className='relative flex justify-center items-center'>
        <div className='absolute w-152 h-152 animate-rotate -z-1'>
          <img
            src={rotateStar}
            alt='rotate-star'
            className='w-full h-full object-contain'
          ></img>
        </div>

        <div
          style={{
            background,
            width: 384,
            height: 384,
            boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)',
          }}
          className={`p-4 rounded-3.75xl ${getTextSize(
            text
          )} font-bold flex justify-center items-center text-center`}
        >
          {text}
        </div>
      </div>

      <IcebreakerBlockVoterList participants={participants} />
    </div>
  );
}

export function IcebreakerBlockCard(props: {
  card: IcebreakerGamePlayCard;
  block: IcebreakerBlock;
}): JSX.Element {
  const { card, block } = props;

  const revealSpecificCard = isNumber(card.revealIndex);

  return (
    <div
      key={card.id}
      className='flex-1 w-full flex justify-center items-center flex-wrap content-center gap-2.5'
    >
      {card.options.map((option, index) => {
        return (
          <IcebreakerBlockOption
            key={index}
            index={index}
            option={option}
            total={card.options.length}
            card={card}
            block={block}
            hidden={revealSpecificCard}
          />
        );
      })}

      {isNumber(card.revealIndex) && (
        <IcebreakerBlockOptionRevealOnStage
          option={card.options[card.revealIndex]}
          index={card.revealIndex}
        />
      )}
    </div>
  );
}

function TabooCard(props: { card: IcebreakerGamePlayCard }) {
  const { card } = props;
  const isMyTurn = useIsMyTurn();
  const currentPlayer = useCurrentPlayer();

  const [guessWord, tabooWords] = useMemo(
    () => [
      card.options.length > 0 ? card.options[0].text : 'No Guess Word',
      card.options.slice(1).map((option) => option.text),
    ],
    [card.options]
  );
  return (
    <div
      className='h-4/5 border border-black rounded-xl transform rotate-3 p-5'
      style={{
        aspectRatio: 7 / 10,
        background:
          'linear-gradient(180deg, #FF3975 0%, #BD0039 100%), linear-gradient(180deg, #FF0935 0.6%, #1C1A1B 100%)',
        boxShadow: '0px 2.627px 7.88px 0px rgba(0, 0, 0, 0.25)',
      }}
    >
      {isMyTurn ? (
        <div className='w-full h-full'>
          <div className='font-Montserrat font-bold h-1/5 flex items-center justify-center text-xl text-center'>
            {guessWord}
          </div>
          <div className='h-4/5 bg-white rounded-xl flex flex-col items-center justify-center gap-0.5'>
            <div className='text-base font-bold text-red-002'>
              Can’t say these:
            </div>
            {tabooWords.map((word, index) => (
              <div
                key={index}
                className='text-base font-bold text-black text-center'
              >
                {word}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className='w-full h-full flex items-center justify-center font-bold'>
          Only {currentPlayer?.firstName || currentPlayer?.username} can see
        </div>
      )}
    </div>
  );
}

export function IcebreakerBlockTabooCard(props: {
  card: IcebreakerGamePlayCard;
  block: IcebreakerBlock;
}): JSX.Element {
  return (
    <div className='w-full h-full flex items-center justify-center'>
      <TabooCard card={props.card} />
    </div>
  );
}
