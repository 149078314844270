import React, {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLatest } from 'react-use';
import { proxy, useSnapshot } from 'valtio';

import { type PuzzleBlock, type PuzzleBlockDetailScore } from '@lp-lib/game';

import { useInterval } from '../../../../hooks/useInterval';
import { useIsController } from '../../../../hooks/useMyInstance';
import { type TeamId } from '../../../../types';
import { uuidv4 } from '../../../../utils/common';
import { uncheckedIndexAccess_UNSAFE } from '../../../../utils/uncheckedIndexAccess_UNSAFE';
import { useClock } from '../../../Clock';
import {
  useDatabaseRef,
  useFirebaseBatchWrite,
  useFirebaseContext,
  useFirebaseValue,
} from '../../../Firebase';
import { useMyTeamId } from '../../../Player';
import { useIsStreamSessionAlive } from '../../../Session';
import { useTeams } from '../../../TeamAPI/TeamV1';
import { useVenueId } from '../../../Venue/VenueProvider';
import { useGameSessionLocalTimer } from '../../hooks';
import { updateBlockDetailScore } from '../../store';
import { useGamePlayEmitter } from '../Common/GamePlay/GamePlayProvider';
import { type GamePlayEndedState } from '../Common/GamePlay/types';
import { BlockKnifeUtils } from '../Shared';
import {
  type Claim,
  type DropSpot,
  type DropSpotMap,
  type Game,
  type GameProgressSummary,
  GameState,
  type GameTeamInfo,
  type Piece,
  type PieceMap,
  type Position,
} from './types';
import { defaultLeaseMs, log, PuzzleUtils } from './utils';

interface PuzzleGameControlAPI {
  initGame: (block: PuzzleBlock) => Promise<void>;
  startGame: () => Promise<void>;
  stopGame: () => Promise<void>;
  resetGame: (debug: string) => Promise<void>;
  deinitGame: () => Promise<void>;
}

interface PuzzleGamePlayAPI {
  claimPiece: (pieceId: string) => Promise<boolean>;
  dropPiece: (
    pieceId: string,
    position: Position,
    score: number
  ) => Promise<void>;
}

type Context = {
  game: Nullable<Game>;
  updateGame: (next: Nullable<Game, false>) => Promise<void>;
  pieceMap: PieceMap;
  dropSpotMap: DropSpotMap;
  gradeOnPlacement: boolean;
  completionBonusPoints: number;
};

const context = React.createContext<Nullable<Context, false>>(null);

function usePuzzleContextProxy(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('PuzzleContext is not in the tree!');
  return ctx;
}

function usePuzzleContext(): Context {
  const proxy = usePuzzleContextProxy();
  return useSnapshot(proxy);
}

function useInitGame(options?: { enabled: boolean; readonly: boolean }) {
  const venueId = useVenueId();
  return useFirebaseValue<Nullable<Game, false>>(
    PuzzleUtils.GetFBPath(venueId, 'game'),
    {
      enabled: !!options?.enabled,
      seedValue: null,
      seedEnabled: false,
      readOnly: !!options?.readonly,
      resetWhenUmount: true,
    }
  );
}

export function usePuzzleGame(): Context['game'] {
  const ctx = usePuzzleContext();
  return ctx.game;
}

export function usePuzzlePieceMap(): PieceMap {
  return usePuzzleContext().pieceMap;
}

export function usePuzzlePieces(): Piece[] {
  const pieceMap = usePuzzlePieceMap();
  return useMemo(() => Object.values(pieceMap), [pieceMap]);
}

export function usePuzzleDropSpotMap(): DropSpotMap {
  return usePuzzleContext().dropSpotMap;
}

export function usePuzzleGradeOnPlacement(): boolean {
  return usePuzzleContext().gradeOnPlacement;
}

export function usePuzzleCompletionBonusPoints(): number {
  return usePuzzleContext().completionBonusPoints;
}

export function usePuzzleGameControl(): PuzzleGameControlAPI {
  const venueId = useVenueId();
  const { updateGame, game } = usePuzzleContext();
  const gameId = game?.id;
  const batchWrite = useFirebaseBatchWrite();
  const ref = useDatabaseRef(PuzzleUtils.GetFBPath(venueId, 'root'));
  const teams = useLatest(
    useTeams({
      updateStaffTeam: true,
      excludeStaffTeam: true,
    })
  );

  const resetGame = useCallback(
    async (debug: string) => {
      log.info(`reset game: ${debug}`);
      await ref.remove();
    },
    [ref]
  );

  const initGame = useCallback(
    async (block: PuzzleBlock) => {
      const updates = uncheckedIndexAccess_UNSAFE({});
      updates[PuzzleUtils.GetFBPath(venueId, 'grade-on-placement')] =
        block.fields.gradeOnPlacement;

      updates[PuzzleUtils.GetFBPath(venueId, 'completion-bonus-points')] =
        block.fields.completionBonusPoints;

      const { dropSpots, pieces } = PuzzleUtils.BuildPuzzleConfig(
        block.fields.dropSpots,
        block.fields.pieces,
        block.fields.gridSize.numRows,
        block.fields.gridSize.numCols
      );

      for (const team of teams.current) {
        updates[PuzzleUtils.GetFBPathByTeam(venueId, team.id, 'pieces')] =
          PuzzleUtils.BuildPieces(pieces);

        updates[PuzzleUtils.GetFBPathByTeam(venueId, team.id, 'drop-spots')] =
          PuzzleUtils.BuildDropSpots(dropSpots, block.fields.gridSize.numCols);
      }
      await batchWrite(updates);
      await updateGame({
        id: uuidv4(),
        state: GameState.Inited,
      });
    },
    [batchWrite, updateGame, teams, venueId]
  );

  const startGame = useCallback(async () => {
    if (!gameId) return;
    await updateGame({
      id: gameId,
      state: GameState.InProgress,
    });
  }, [gameId, updateGame]);

  const stopGame = useCallback(async () => {
    if (!gameId) return;
    await updateGame({ id: gameId, state: GameState.Ended });
  }, [updateGame, gameId]);

  const deinitGame = useCallback(async () => {
    if (!gameId) return;
    await updateGame({ id: gameId, state: GameState.None });
  }, [updateGame, gameId]);

  return {
    initGame,
    startGame,
    stopGame,
    resetGame,
    deinitGame,
  };
}

export function usePuzzleGamePlayAPI(
  clientId: string,
  teamId: TeamId
): PuzzleGamePlayAPI {
  const venueId = useVenueId();
  const firebaseContext = useFirebaseContext();
  const clock = useClock();
  const dropSpotMap = usePuzzleDropSpotMap();

  const claimPiece = useCallback(
    async (pieceId: string) => {
      const pieceRef = firebaseContext.svc.prefixedSafeRef<Piece>(
        PuzzleUtils.GetFBPiecePath(venueId, teamId, pieceId)
      );
      const result = await pieceRef.transaction((piece) => {
        if (piece) {
          const now = clock.now();
          const claimable =
            !piece.claim ||
            piece.claim.clientId === clientId ||
            now > piece.claim.timestamp + defaultLeaseMs;

          if (claimable) {
            piece.claim = {
              clientId,
              timestamp: now,
            };
            piece.updatedAt = now;
            return piece;
          }
        }
      });

      const claim = result.snapshot?.val()?.claim;
      return result.committed && claim?.clientId === clientId;
    },
    [venueId, teamId, clientId, clock, firebaseContext]
  );

  const dropPiece = useCallback(
    async (pieceId: string, position: Position, score: number) => {
      const pieceRef = firebaseContext.svc.prefixedRef<Piece>(
        PuzzleUtils.GetFBPiecePath(venueId, teamId, pieceId)
      );

      await pieceRef.transaction((piece) => {
        if (piece) {
          const claim = PuzzleUtils.GetValidClaim(piece.claim, clock);
          // you cannot drop a piece you don't have a claim on.
          if (claim?.clientId !== clientId) return;

          piece.position = position;
          piece.updatedAt = clock.now();
          // revoke the user's claim on the piece.
          piece.claim = null;

          // note: we always grade, but we don't always surface it to the user.
          const dropSpot =
            position.location === 'tray'
              ? null
              : dropSpotMap[
                  PuzzleUtils.GridPositionLabel(position.row, position.col)
                ];
          piece.grade = PuzzleUtils.GradePlacement(piece, dropSpot);
          piece.score = piece.grade === 'correct' ? score : 0;
          return piece;
        }
      });
    },
    [venueId, teamId, clientId, clock, firebaseContext, dropSpotMap]
  );

  return {
    claimPiece,
    dropPiece,
  };
}

export function usePiece(pieceId: string): Piece {
  const pieceMap = usePuzzlePieceMap();
  return pieceMap[pieceId];
}

export function usePieceClaim(piece: Piece): Claim | null {
  const clock = useClock();
  const [claim, setClaim] = useState(
    PuzzleUtils.GetValidClaim(piece.claim, clock)
  );

  useInterval(
    () => {
      setClaim(PuzzleUtils.GetValidClaim(piece.claim, clock));
    },
    claim ? 1000 : null
  );

  useEffect(() => {
    setClaim(PuzzleUtils.GetValidClaim(piece.claim, clock));
  }, [piece.claim, clock]);

  return claim;
}

export function useDropSpot(row: number, col: number): DropSpot {
  const dropSpotMap = usePuzzleDropSpotMap();
  return dropSpotMap[PuzzleUtils.GridPositionLabel(row, col)];
}

export function usePuzzleAllCorrect(): boolean {
  const pieces = usePuzzlePieces();
  return useMemo(() => {
    if (pieces.length === 0) return false;
    return !pieces.find((p) => p.grade !== 'correct');
  }, [pieces]);
}

export function useEmitGamePlayEndedState(block: PuzzleBlock): void {
  const emitter = useGamePlayEmitter();
  const [finalState, setFinalState] = useState<Nullable<GamePlayEndedState>>();
  const goalMedia = BlockKnifeUtils.GetGoalCompletionMedia(block);
  const time = useGameSessionLocalTimer();
  const gameState = usePuzzleGame()?.state;
  const allCorrect = usePuzzleAllCorrect();

  // timesup
  useEffect(() => {
    if (finalState) return;
    if (gameState === GameState.Ended) {
      emitter.emit('ended', block.id, 'timesup');
      setFinalState('timesup');
    }
  }, [time, gameState, emitter, block.id, finalState]);

  // finished
  useEffect(() => {
    if (finalState || !allCorrect || gameState !== GameState.InProgress) return;

    if (goalMedia)
      emitter.emit('ended-awaiting-goal-media', block.id, 'finished', {
        animationMedia: goalMedia,
      });
    else
      emitter.emit('ended', block.id, 'finished', {
        animationMedia: goalMedia,
      });
    setFinalState('finished');
  }, [
    allCorrect,
    block.fields.goalAnimationMedia,
    block.id,
    emitter,
    finalState,
    gameState,
    goalMedia,
  ]);

  useEffect(() => {
    return () => {
      setFinalState(undefined);
    };
  }, [block.id]);
}

export function useGameProgressSummary(): GameProgressSummary {
  const venueId = useVenueId();
  const completionBonusPoints = usePuzzleCompletionBonusPoints();

  const [teamInfo] = useFirebaseValue<Nullable<GameTeamInfo>>(
    PuzzleUtils.GetFBPath(venueId, 'teams'),
    {
      enabled: true,
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );

  return useMemo(() => {
    if (!teamInfo) return {};

    return Object.keys(teamInfo).reduce<GameProgressSummary>(
      (accum, teamId) => {
        const pieces = Object.values(teamInfo[teamId]?.pieces ?? {});
        const numTotalPieces = pieces.length;
        const correctPieces = pieces.filter((p) => p.grade === 'correct');
        const bonusPoints =
          correctPieces.length === numTotalPieces ? completionBonusPoints : 0;

        accum[teamId] = {
          numCorrectPieces: correctPieces.length,
          numTotalPieces,
          totalScore:
            correctPieces.reduce(
              (accum, curr) => accum + Math.max(curr.score ?? 0, 0),
              0
            ) + bonusPoints,
        };
        return accum;
      },
      {}
    );
  }, [completionBonusPoints, teamInfo]);
}

export function usePuzzleScorer(teamId: TeamId): void {
  const progressSummary = useGameProgressSummary();
  const isParticipated = !!progressSummary[teamId];
  const teamScore = progressSummary[teamId]?.totalScore ?? 0;
  useEffect(() => {
    // It is tricky that if score is written to BlockDetailsScore, the team will
    // show up in the scoreboard, which leads staff teams to be exposed. In the
    // future, may need to add the check in updateBlockDetailScore.
    if (!isParticipated) return;

    async function run() {
      await updateBlockDetailScore<PuzzleBlockDetailScore>(teamId, {
        score: teamScore,
        submittedAt: Date.now(),
      });
    }
    run();
  }, [isParticipated, teamId, teamScore]);
}

export function PuzzleProvider(props: {
  children?: ReactNode;
}): JSX.Element | null {
  const venueId = useVenueId();
  const isController = useIsController();
  const isSessionAlive = useIsStreamSessionAlive();
  const teamId = useMyTeamId() ?? '';
  const [game, updateGame] = useInitGame({
    enabled: isSessionAlive,
    readonly: !isController,
  });
  const [pieceMap] = useFirebaseValue<Nullable<PieceMap>>(
    PuzzleUtils.GetFBPathByTeam(venueId, teamId, 'pieces'),
    {
      enabled: isSessionAlive && !!teamId,
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );

  const [dropSpotMap] = useFirebaseValue<Nullable<DropSpotMap>>(
    PuzzleUtils.GetFBPathByTeam(venueId, teamId, 'drop-spots'),
    {
      enabled: isSessionAlive && !!teamId,
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );

  const [gradeOnPlacement] = useFirebaseValue<boolean>(
    PuzzleUtils.GetFBPath(venueId, 'grade-on-placement'),
    {
      enabled: isSessionAlive && !!teamId,
      seedValue: false,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );

  const [completionBonusPoints] = useFirebaseValue<number>(
    PuzzleUtils.GetFBPath(venueId, 'completion-bonus-points'),
    {
      enabled: isSessionAlive && !!teamId,
      seedValue: 0,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );

  const ref = useRef<Context>(
    proxy({
      game,
      updateGame,
      pieceMap: pieceMap ?? {},
      dropSpotMap: dropSpotMap ?? {},
      gradeOnPlacement: gradeOnPlacement ?? false,
      completionBonusPoints: completionBonusPoints ?? 0,
    })
  );

  useLayoutEffect(() => {
    ref.current.game = game;
    ref.current.updateGame = updateGame;
  }, [game, updateGame]);

  useLayoutEffect(() => {
    ref.current.pieceMap = pieceMap ?? {};
  }, [pieceMap]);

  useLayoutEffect(() => {
    ref.current.dropSpotMap = dropSpotMap ?? {};
  }, [dropSpotMap]);

  useLayoutEffect(() => {
    ref.current.gradeOnPlacement = gradeOnPlacement ?? false;
  }, [gradeOnPlacement]);

  useLayoutEffect(() => {
    ref.current.completionBonusPoints = completionBonusPoints ?? 0;
  }, [completionBonusPoints]);

  return (
    <context.Provider value={ref.current}>{props.children}</context.Provider>
  );
}
