import React, {
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { usePrevious } from 'react-use';
import { proxy, ref, useSnapshot } from 'valtio';

import {
  type DtoPromptTemplate,
  type DtoTrackableMessage,
  type OpenaiChatCompletionMessage,
} from '@lp-lib/api-service-client/public';
import { type AIChatBlock } from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { useMyInstance } from '../../../../hooks/useMyInstance';
import { useStatsAwareTaskQueue } from '../../../../hooks/useTaskQueue';
import { apiService } from '../../../../services/api-service';
import { type TeamId } from '../../../../types';
import { uncheckedIndexAccess_UNSAFE } from '../../../../utils/uncheckedIndexAccess_UNSAFE';
import { markSnapshottable, ValtioUtils } from '../../../../utils/valtio';
import { AIChatParserUtils } from '../../../AIChat';
import { Clock } from '../../../Clock';
import { type FirebaseService, useFirebaseContext } from '../../../Firebase';
import {
  type NarrowedChatCompletionMessage,
  type OutputParser,
} from '../../../OpenAI';
import { useMyTeamId } from '../../../Player';
import { usePromptTemplate } from '../../../PromptTemplate';
import { RoundRobinPlayerAPI } from '../../../RoundRobinPlayer';
import { useStreamSessionId } from '../../../Session';
import { useIsTeamCaptainScribe } from '../../../TeamAPI/TeamV1';
import { useTeamMembersGetter } from '../../../TeamAPI/TeamV1';
import { useTeam } from '../../../TeamAPI/TeamV1';
import { useVenueId } from '../../../Venue/VenueProvider';
import {
  useFetchGameSessionGamePack,
  useGameSessionLocalTimer,
} from '../../hooks';
import { updateBlockDetailScore } from '../../store';
import { useGamePlayEmitter } from '../Common/GamePlay/GamePlayProvider';
import { type GamePlayEndedState } from '../Common/GamePlay/types';
import {
  type AIChatMessage,
  type AIChatMessageFBRef,
  type AIChatMessageStatus,
  type GameResult,
  type ParsedAssistantMessage,
  type TeamState,
  type TeamSummaryMap,
} from './types';
import { AIChatFBUtils, log } from './utils';

export type SharedState = {
  teamStateMap: Nullable<TeamSummaryMap>;
};

class AIChatGameSharedAPI {
  private _state;
  constructor(
    venueId: string,
    svc: FirebaseService,
    private summaryHandle = AIChatFBUtils.TeamSummaryMapHandle(svc, venueId)
  ) {
    this._state = markSnapshottable(proxy<SharedState>(this.initialState()));
  }

  get state(): Readonly<SharedState> {
    return this._state;
  }

  on(): void {
    this.summaryHandle.on((val) =>
      ValtioUtils.set(this._state, 'teamStateMap', val)
    );
  }

  off(): void {
    this.summaryHandle.off();
  }

  reset() {
    ValtioUtils.reset(this._state, this.initialState());
  }

  private initialState(): SharedState {
    return {
      teamStateMap: null,
    };
  }
}

type GameControlState = {
  inspectingTeamId: Nullable<TeamId>;
};

class AIChatGameControlAPI {
  private _state;
  constructor(
    venueId: string,
    svc: FirebaseService,
    private log: Logger,
    private rootHandle = AIChatFBUtils.RootHandle(svc, venueId)
  ) {
    this._state = markSnapshottable(
      proxy<GameControlState>(this.initialState())
    );
  }

  set inspectingTeamId(teamId: Nullable<TeamId>) {
    this._state.inspectingTeamId = teamId;
  }

  get state(): Readonly<GameControlState> {
    return this._state;
  }

  async resetGame() {
    this.log.info('reset game');
    await this.rootHandle.remove();
  }

  private initialState(): GameControlState {
    return {
      inspectingTeamId: null,
    };
  }
}

export class AIChatGamePlayAPI {
  private _state;
  private model: Nullable<string>;
  private template: Nullable<DtoPromptTemplate>;
  private parser: OutputParser<ParsedAssistantMessage> | null;

  constructor(
    venueId: string,
    private teamId: TeamId,
    svc: FirebaseService,
    private log: Logger,
    getTeamMembers: ReturnType<typeof useTeamMembersGetter>,
    private clock = Clock.instance(),
    private messagesHandle = AIChatFBUtils.MessagesHandle(svc, venueId, teamId),
    private summaryHandle = AIChatFBUtils.SummaryHandle(svc, venueId, teamId),
    readonly roundRobinAPI = new RoundRobinPlayerAPI(
      svc,
      teamId,
      AIChatFBUtils.AbsoluteTeamPath(venueId, teamId, 'player'),
      getTeamMembers
    )
  ) {
    this._state = markSnapshottable(proxy<TeamState>(this.initialState()));
    this.parser = null;
  }

  configure(model: string | undefined, template: Nullable<DtoPromptTemplate>) {
    this.model = model;
    this.template = template;
    this.parser = AIChatParserUtils.GetOutputParser(template, this.log);
  }

  async load() {
    if (this._state.loaded) return;
    const val = await this.messagesHandle.get();
    if (!val) {
      this.log.info('load empty messages', { teamId: this.teamId });
      this._state.messages = [];
      this._state.loaded = true;
      return;
    }
    const messages = Object.values(val);
    for (const message of messages) {
      this._state.messages.push(this.maybeParse(message));
    }
    this.log.info('loaded messages', {
      teamId: this.teamId,
      numOfMessages: messages.length,
    });
    this._state.loaded = true;
  }

  async on() {
    await this.load();
    this.messagesHandle.ref.on('child_added', async (snap) => {
      const message = snap.val();
      if (!message) return;
      const idx = this.state.messages.findIndex((m) => m.id === message.id);
      if (idx >= 0) return;
      const parsedMessage = this.maybeParse(message);
      this._state.messages.push(parsedMessage);
      await this.updateSummary(parsedMessage);
    });
    this.messagesHandle.ref.on('child_changed', async (snap) => {
      const message = snap.val();
      if (!message) return;
      const idx = this.state.messages.findIndex((m) => m.id === message.id);
      if (idx < 0) return;
      const parsedMessage = this.maybeParse(message);
      this._state.messages[idx] = parsedMessage;
      await this.updateSummary(parsedMessage);
    });
    this.summaryHandle.on((val) => {
      this._state.gameResult = val?.result ?? 'none';
    });
    this.roundRobinAPI.on();
  }

  off() {
    this.messagesHandle.ref.off('child_added');
    this.messagesHandle.ref.off('child_changed');
    this.summaryHandle.off();
    this.roundRobinAPI.off();
  }

  get state(): Readonly<TeamState> {
    return this._state;
  }

  async kickoff(prompt: string, temperature: number) {
    // NOTE(jialin): this assumes that the template is already configured
    if (!this._state.loaded) throw new Error("Can't init before load");
    if (await this.inited()) {
      this.log.info('conversation has inited', { teamId: this.teamId });
      return;
    }
    this.log.info('init conversation', {
      teamId: this.teamId,
      templateId: this.template?.id ?? null,
    });
    const kickoffMessage: NarrowedChatCompletionMessage = {
      role: 'system',
      content: this.template
        ? this.template.systemPrompt + `\n\n` + prompt
        : prompt,
    };
    await this.sendMessage(
      { uid: 'system', displayName: 'system' },
      kickoffMessage,
      [],
      temperature
    );
  }

  async send(
    sender: {
      uid: string;
      displayName: string;
    },
    text: string,
    temperature = 1,
    faultInjection = false
  ) {
    await this.sendMessage(
      sender,
      { role: 'user', content: text },
      this.historyMessages(),
      temperature,
      faultInjection
    );
    await this.roundRobinAPI.nextPlayer('ai-chat-pick-after-send');
  }

  async retry(messageId: string, temperature = 1) {
    const ref = this.messagesHandle.ref.child(
      messageId
    ) as never as AIChatMessageFBRef;
    await this.createChatCompletion(
      ref,
      this.historyMessages(),
      temperature,
      false
    );
  }

  async grade(teamId: TeamId, points: number) {
    await updateBlockDetailScore(teamId, {
      score: points,
      submittedAt: Date.now(),
    });
  }

  async trackMessages(bizId: string, bizLabel: string) {
    const untrackedMessages = this.state.messages
      .filter((m) => !m.tracked && !m.status)
      .map<DtoTrackableMessage>((m) => ({
        body: {
          role: m.role,
          content: m.content,
          name: m.name,
          function_call: m.function_call,
        },
        messageId: m.id,
        senderDisplayName: m.sender.displayName,
        senderUid: m.sender.uid,
        createdAt: m.createdAt,
      }));
    this.log.info('track messages', {
      untrackedMessages: untrackedMessages.length,
    });
    if (untrackedMessages.length === 0) return;
    await apiService.aiChat.trackMessages({
      bizId,
      bizLabel,
      promptTemplateId: this.template?.id ?? '',
      messages: untrackedMessages,
    });
    const updates = uncheckedIndexAccess_UNSAFE({});
    for (const message of untrackedMessages) {
      updates[`${message.messageId}/tracked`] = true;
    }
    await this.messagesHandle.ref.update(updates);
  }

  reset() {
    this.roundRobinAPI.reset();
    ValtioUtils.reset(this._state, this.initialState());
  }

  private async sendMessage(
    sender: {
      uid: string;
      displayName: string;
    },
    message: NarrowedChatCompletionMessage,
    historyMessages: NarrowedChatCompletionMessage[],
    temperature: number,
    faultInjection = false
  ) {
    await this.pushMessageToFirebase(sender, {
      ...message,
      createdAt: this.clock.now(),
    });
    await this.createChatCompletion(
      this.newMessageFBRef(),
      [...historyMessages, message],
      temperature,
      faultInjection
    );
  }

  private async createChatCompletion(
    ref: AIChatMessageFBRef,
    messages: NarrowedChatCompletionMessage[],
    temperature: number,
    faultInjection = false
  ) {
    await this.pushMessageToFirebase(
      {
        uid: 'assistant',
        displayName: 'assistant',
      },
      {
        role: 'assistant',
        status: 'loading',
        createdAt: this.clock.now(),
      },
      ref
    );
    ref.onDisconnect().update({ status: 'error' });
    try {
      if (faultInjection) {
        throw new Error('simulate fault');
      }
      const resp = await apiService.openai.createChatCompletion({
        messages: messages,
        temperature: temperature,
        model: this.model ?? undefined,
      });
      const choice = resp.data.choices[0];
      if (!choice) {
        await ref.remove();
        return;
      }
      await this.pushMessageToFirebase(
        {
          uid: 'assistant',
          displayName: 'assistant',
        },
        {
          ...choice.message,
          createdAt: this.clock.now(),
        },
        ref
      );
    } catch (error) {
      await ref.update({ status: 'error' });
    } finally {
      ref.onDisconnect().cancel();
    }
  }

  private async pushMessageToFirebase(
    sender: {
      uid: string;
      displayName: string;
    },
    message: (NarrowedChatCompletionMessage | OpenaiChatCompletionMessage) & {
      status?: AIChatMessageStatus;
      createdAt: number;
    },
    existRef?: AIChatMessageFBRef
  ) {
    const ref = existRef ?? this.newMessageFBRef();
    await ref.set({
      id: ref.key,
      sender,
      tracked: false,
      ...message,
    } as never);
    return ref;
  }

  private newMessageFBRef() {
    return this.messagesHandle.ref.push() as never as AIChatMessageFBRef;
  }

  private maybeParse(message: AIChatMessage) {
    if (message.role !== 'assistant' || !message.content) return message;
    const parsed = this.parser?.parse(message.content);
    if (parsed) {
      message.parsed = ref(parsed);
    }
    return message;
  }

  private async updateSummary(message: AIChatMessage) {
    if (!message.parsed) return;
    const result = message.parsed.result;
    const txn = await this.summaryHandle.ref.transaction((val) => {
      const now = this.clock.now();
      if (!val || val.updatedAt < now) {
        return {
          result,
          updatedAt: now,
          attempts: this._state.messages.filter((m) => m.role === 'user')
            .length,
        };
      }
    });
    if (txn.committed) {
      this.log.info('updated summary', {
        teamId: this.teamId,
        result,
        messageId: message.id,
      });
    }
  }

  private async inited() {
    const recent = (await this.messagesHandle.ref.limitToFirst(1).get()).val();
    this.log.info('checking if inited', { recent });
    if (!recent) return false;
    const messages = Object.values(recent);
    return messages.length > 0 && messages[0].role === 'system';
  }

  private historyMessages() {
    return this._state.messages.map<NarrowedChatCompletionMessage>((m) => ({
      role: m.role,
      content: m.content,
      name: m.name,
      function_call: m.function_call,
    }));
  }

  private initialState(): TeamState {
    return {
      loaded: false,
      messages: [],
      gameResult: 'none',
    };
  }
}

type Context = {
  gameSharedAPI: AIChatGameSharedAPI;
  gameControlAPI: AIChatGameControlAPI;
  gamePlayAPI: AIChatGamePlayAPI;
};

const context = React.createContext<Context | null>(null);

function useAIChatContext(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('AIChatContext is not in the tree!');
  return ctx;
}

export function useAIChatGamePlayAPI(): Context['gamePlayAPI'] {
  return useAIChatContext().gamePlayAPI;
}

export function useAIChatGameControlAPI(): Context['gameControlAPI'] {
  return useAIChatContext().gameControlAPI;
}

export function useAIChatGameSharedAPI(): Context['gameSharedAPI'] {
  return useAIChatContext().gameSharedAPI;
}

export function useSetupGamePlay(
  api: AIChatGamePlayAPI,
  model: string | undefined,
  template: {
    instance: Nullable<DtoPromptTemplate>;
    isLoading: boolean;
  },
  enabled: boolean
) {
  const { addTask } = useStatsAwareTaskQueue({
    shouldProcess: true,
    stats: 'task-queue-ai-chat-gameplayapi-ms',
  });
  useEffect(() => {
    if (template.isLoading || !enabled) return;
    addTask(async function init() {
      api.configure(model, template.instance);
      await api.on();
    });
    return () => {
      addTask(async function deinit() {
        api.off();
      });
    };
  }, [addTask, api, enabled, model, template.instance, template.isLoading]);
}

export function useSubscribeSharedState() {
  const api = useAIChatGameSharedAPI();
  useEffect(() => {
    api.on();
    return () => api.off();
  }, [api]);
}

export function useMessages(api: AIChatGamePlayAPI) {
  return useSnapshot(api.state).messages as AIChatMessage[];
}

export function useLastMessage() {
  const messages = useMessages(useAIChatGamePlayAPI());
  return messages[messages.length - 1];
}

export function useIsConversationInited() {
  const messages = useMessages(useAIChatGamePlayAPI());
  return messages.length > 0 && messages[0].role === 'system';
}

export function useIsConversationLoaded() {
  const api = useAIChatGamePlayAPI();
  return useSnapshot(api.state).loaded;
}

export function useGameResult(): GameResult {
  const api = useAIChatGamePlayAPI();
  return useSnapshot(api.state).gameResult;
}

export function useEmitGamePlayEndedState(block: AIChatBlock): void {
  const emitter = useGamePlayEmitter();
  const [finalState, setFinalState] = useState<Nullable<GamePlayEndedState>>();
  const currTime = useGameSessionLocalTimer();
  const prevTime = usePrevious(currTime);
  const timesup = prevTime === 1 && currTime === 0;
  const gameResult = useGameResult();

  const emitEnd = useLiveCallback(() => {
    const endMedia =
      gameResult === 'win' ? block.fields.winMedia : block.fields.loseMedia;
    if (endMedia)
      emitter.emit('ended-awaiting-goal-media', block.id, 'finished', {
        animationMedia: endMedia,
      });
    else
      emitter.emit('ended', block.id, 'finished', {
        animationMedia: endMedia,
      });
    setFinalState('finished');
  });

  // timesup
  useEffect(() => {
    if (finalState || !timesup) return;
    emitEnd();
  }, [emitEnd, finalState, timesup]);

  // finished
  useEffect(() => {
    if (finalState || gameResult === 'none') return;
    emitEnd();
  }, [emitEnd, finalState, gameResult]);
}

export function useAutoGrading(teamId: TeamId, points: number) {
  const gameResult = useGameResult();
  const api = useAIChatGamePlayAPI();

  const grade = useLiveCallback(() => {
    api.grade(teamId, points);
  });

  useEffect(() => {
    if (gameResult !== 'win') return;
    grade();
  }, [gameResult, grade]);
}

export function useNarrowedPromptTemplate(templateId: Nullable<string>) {
  const { data: instance, isLoading } = usePromptTemplate(templateId);
  return { instance, isLoading };
}

export function useTeamSummaryMap() {
  const api = useAIChatGameSharedAPI();
  return useSnapshot(api.state).teamStateMap;
}

export function useHostInspectingTeamId() {
  const api = useAIChatGameControlAPI();
  return useSnapshot(api.state).inspectingTeamId;
}

export function useTrackMessages() {
  const api = useAIChatGamePlayAPI();
  const messages = useMessages(api);
  const numOfMessages = messages.length;
  const lastCreatedAt = messages[numOfMessages - 1]?.createdAt;
  const sessionId = useStreamSessionId();
  const team = useTeam(useMyTeamId());
  const me = useMyInstance();
  const isTeamCaptain = useIsTeamCaptainScribe(me?.teamId, me?.clientId);
  const gamePack = useFetchGameSessionGamePack();
  const { addTask } = useStatsAwareTaskQueue({
    shouldProcess: true,
    stats: 'task-queue-ai-chat-track-messages-ms',
  });

  const trackMessages = useLiveCallback(async () => {
    if (
      !team ||
      !sessionId ||
      !isTeamCaptain ||
      !gamePack ||
      numOfMessages === 0
    )
      return;
    await api.trackMessages(
      `${sessionId}_${team.id}`,
      `${gamePack.name} (${team.name})`
    );
  });

  useEffect(() => {
    addTask(async function run() {
      await trackMessages();
    });
  }, [trackMessages, numOfMessages, lastCreatedAt, addTask]);
}

export function AIChatProvider(props: {
  children?: ReactNode;
}): JSX.Element | null {
  const venueId = useVenueId();
  const { svc } = useFirebaseContext();
  const teamId = useMyTeamId() ?? '';
  const getTeamMembers = useTeamMembersGetter();

  const ctx = useMemo(() => {
    return {
      gameSharedAPI: new AIChatGameSharedAPI(venueId, svc),
      gameControlAPI: new AIChatGameControlAPI(venueId, svc, log),
      gamePlayAPI: new AIChatGamePlayAPI(
        venueId,
        teamId,
        svc,
        log,
        getTeamMembers
      ),
    };
  }, [getTeamMembers, svc, teamId, venueId]);

  return <context.Provider value={ctx}>{props.children}</context.Provider>;
}
