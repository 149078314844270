import { useContext, useMemo } from 'react';
import React from 'react';
import { proxy } from 'valtio';

import { type Block, type BlockType } from '@lp-lib/game';

import { useInstance } from '../../hooks/useInstance';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService, BlockUpdateOperation } from '../../services/api-service';
import { type Game } from '../../types/game';
import {
  markSnapshottable,
  useSnapshot,
  type ValtioSnapshottable,
  ValtioUtils,
} from '../../utils/valtio';
import { type BlockEditorState, BlockEditorStore } from '../RoutedBlock';

export type GameEditorState = {
  game: Game | null;
  selectedBlockId: string | null;
};

export class GameEditorStore {
  private _state;
  readonly blockEditorStore;
  constructor() {
    this._state = markSnapshottable(
      proxy<GameEditorState>(this.initialState())
    );
    this.blockEditorStore = new BlockEditorStore();
  }

  get state(): Readonly<ValtioSnapshottable<GameEditorState>> {
    return this._state;
  }

  private initialState(): GameEditorState {
    return {
      game: null,
      selectedBlockId: null,
    };
  }

  async setEditingGame(
    game: Game | null,
    options?: {
      blocks?: Block[];
      blockId?: string;
      fetchBlocks?: boolean;
    }
  ) {
    this._state.game = game;
    if (!this._state.game) {
      this.setSelectedBlockId(null);
      return;
    }
    if (game?.blocks) {
      this.blockEditorStore.setBlocks(game.blocks);
    }
    if (options?.blocks) {
      this.blockEditorStore.setBlocks(options.blocks);
    }
    if (options?.blockId) {
      this.setSelectedBlockId(options.blockId);
    } else if (this.blockEditorStore.state.blocks.length > 0) {
      this.setSelectedBlockId(this.blockEditorStore.state.blocks[0].id);
    }
    if (options?.fetchBlocks) {
      await this.fetchEditingBlocks(this._state.game.id, true);
    }
  }

  updateGame(source: Game) {
    if (this._state.game?.id !== source.id) return;
    ValtioUtils.update(this._state.game, source);
  }

  deleteGame(gameId: string) {
    if (this._state.game?.id !== gameId) return;
    this._state.game = null;
  }

  setSelectedBlockId(selectedBlockId: string | null) {
    if (this._state.selectedBlockId !== selectedBlockId) {
      this._state.selectedBlockId = selectedBlockId;
    }
  }

  private setBlocks(payload: { gameId: string; blocks: Block[] }) {
    const { gameId, blocks } = payload;
    if (this._state.game?.id !== gameId) return;
    this.blockEditorStore.setBlocks(blocks);
  }

  async fetchEditingBlocks(gameId: string, setDefaultBlockId?: boolean) {
    try {
      const blocks = (await apiService.block.getBlocksByGameId(gameId)).data
        .blocks;
      this.setBlocks({ gameId, blocks });
      if (setDefaultBlockId) this.setSelectedBlockId(blocks[0]?.id);
    } catch (err: UnassertedUnknown) {
      // TODO: (RGS)
      console.log(err);
    }
  }

  async moveBlocks(dragPosition: number, targetPosition: number) {
    if (!this._state.game) return;
    const game = this._state.game;

    const blocks = (
      await apiService.block.moveBlocks({
        operation: BlockUpdateOperation.MOVE,
        gameId: game.id,
        dragPosition,
        targetPosition,
      })
    ).data.blocks;

    this.setBlocks({ gameId: game.id, blocks });
  }

  async deleteBlock(blockId: string) {
    const game = this._state.game;
    if (!game) return;

    const selectedBlockId = this.blockEditorStore.prevBlockId(blockId);

    await apiService.block.delete(blockId);
    const blocks = (await apiService.block.getBlocksByGameId(game.id)).data
      .blocks;
    this.setBlocks({
      gameId: game.id,
      blocks,
    });
    this.setSelectedBlockId(
      selectedBlockId || this.blockEditorStore.state.blocks[0]?.id
    );
  }

  async detachBlock(blockId: string) {
    const game = this._state.game;
    if (!game) return;

    const selectedBlockId = this.blockEditorStore.prevBlockId(blockId);

    const remoteBlocks = (
      await apiService.block.detachGameBlock(game.id, blockId)
    ).data.blocks;
    this.setBlocks({ gameId: game.id, blocks: remoteBlocks });
    this.setSelectedBlockId(
      selectedBlockId || this.blockEditorStore.state.blocks[0]?.id
    );
  }

  async attachBlock(blockId: string, atIndex: number) {
    const game = this._state.game;
    if (!game) return;

    const remoteBlocks = (
      await apiService.block.attachGameBlock({
        blockId,
        gameId: game.id,
        targetPosition: atIndex,
      })
    ).data.blocks;
    this.setBlocks({ gameId: game.id, blocks: remoteBlocks });
  }

  async duplicateBlock(blockId: string) {
    const game = this._state.game;
    if (!game) return;

    const blocks = (await apiService.block.duplicateGameBlock(game.id, blockId))
      .data.blocks;

    this.setBlocks({ gameId: game.id, blocks });
    const selectedBlockId = this.blockEditorStore.nextBlockId(blockId);
    this.setSelectedBlockId(selectedBlockId);
  }

  async createBlock(gameId: string, position = 0, type: BlockType) {
    if (position < 0 || !gameId || !type) return;

    const blocks = (
      await apiService.block.createGameBlock(gameId, {
        position,
        type,
      })
    ).data.blocks;

    if (!blocks || blocks.length === 0) throw new Error('createBlock failed');
    let newBlock = blocks.find((b) => b.position === position);
    if (!newBlock) {
      newBlock = blocks[0];
    }

    this.setBlocks({ gameId, blocks });
    this.setSelectedBlockId(newBlock.id);
  }
}

type Context = {
  store: GameEditorStore;
};

const context = React.createContext<Context | null>(null);

export function GameEditorStoreProvider(props: {
  children: React.ReactNode;
}): JSX.Element {
  const ctxValue = useInstance(() => ({ store: new GameEditorStore() }));
  return <context.Provider value={ctxValue}>{props.children}</context.Provider>;
}

function useGameEditorStoreContext(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('GameEditorStore is not in the tree!');
  return ctx;
}

export function useGameEditorStore() {
  return useGameEditorStoreContext().store;
}

function useStoreSnapshot() {
  return useSnapshot(useGameEditorStore().state);
}

export function useEditingGameId() {
  return useStoreSnapshot().game?.id;
}

export function useEditingGame() {
  const game = (useSnapshot(useGameEditorStore().state) as GameEditorState)
    .game;
  const blocks = useSnapshot(useGameEditorStore().blockEditorStore.state)
    .blocks as BlockEditorState['blocks'];
  return useMemo(() => {
    if (!game) return game;
    return {
      ...game,
      blocks,
    };
  }, [blocks, game]);
}

export function useSelectedBlockId() {
  return useStoreSnapshot().selectedBlockId;
}

export function useRefreshBlocksForGameEditor(
  gameId?: Game['id']
): (gameId?: Game['id']) => void {
  const store = useGameEditorStore();
  return useLiveCallback(async (overrideGameId?: Game['id']) => {
    const finalGameId = overrideGameId ?? gameId;
    if (finalGameId) store.fetchEditingBlocks(finalGameId);
  });
}
