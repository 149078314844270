import { type ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import { EnumsOrgSubscriptionPlan } from '@lp-lib/api-service-client/public';

import { type Organization } from '../../types';
import { err2s } from '../../utils/common';
import { Loading } from '../Loading';
import { SwitcherControlled } from '../Switcher';
import { useOrganizations } from './hooks';

type Props = {
  initOrgIds?: string[] | null;
  title?: ReactNode;
  columns?: {
    names: string[];
    render: (org: Organization) => ReactNode[];
  };
  maxTableHeight?: `max-h-${number}`;
  onChange?: (v: { selectAll: boolean; orgIds: string[] }) => void;
  disabled?: boolean;
  compareOrganizations?: (a: Organization, b: Organization) => number;
  filterOrganization?: (organization: Organization) => boolean;
};

function OrganizationItem(props: {
  organization: Organization;
  checked: boolean;
  onChange: () => void;
  disabled: boolean;
  columns?: Props['columns'];
}): JSX.Element {
  const { organization, checked, onChange, disabled, columns } = props;

  return (
    <tr>
      <td className='flex items-center justify-start'>
        <input
          type='checkbox'
          className='checkbox-dark'
          checked={checked}
          onChange={() => onChange()}
          disabled={disabled}
        />
        <div className='ml-3'>{organization.name}</div>
      </td>
      {columns?.render(organization).map((node, index) => (
        <th key={index}>{node}</th>
      ))}
    </tr>
  );
}

function OrganizationList(
  props: Omit<Props, 'title'> & { selectAll: boolean }
): JSX.Element | null {
  const {
    columns,
    maxTableHeight,
    selectAll,
    initOrgIds,
    filterOrganization,
    compareOrganizations,
  } = props;
  const { data, isLoading, error } = useOrganizations();
  const [checkedState, setCheckedState] = useState<(string | null)[]>([]);
  const onChange = useRef(props.onChange);
  const disabled = props.disabled || selectAll;

  const organizations = useMemo(() => {
    let res = data;
    if (filterOrganization) res = res?.filter(filterOrganization);
    if (compareOrganizations)
      res = res?.sort((a, b) => compareOrganizations(a, b));
    return res;
  }, [data, filterOrganization, compareOrganizations]);

  useEffect(() => {
    if (!organizations) return;
    const orgIds = organizations.map((o) =>
      initOrgIds?.includes(o.id) ? o.id : null
    );
    setCheckedState(orgIds);
  }, [organizations, initOrgIds]);

  useEffect(() => {
    if (!organizations || !selectAll) return;
    const orgIds = organizations.map((o) => o.id);
    setCheckedState(orgIds);
    onChange.current?.({ selectAll, orgIds });
  }, [organizations, selectAll]);

  if (isLoading) return <Loading />;
  if (error)
    return <div className='text-lp-red-002 text-sms'>{err2s(error)}</div>;

  const handleOnChange = (position: number) => {
    if (!organizations) return;
    const updatedCheckedState = checkedState.map((item, index) => {
      if (index === position) {
        return item ? null : organizations[index].id;
      }
      return item;
    });
    setCheckedState(updatedCheckedState);
    const orgIds = organizations.map((o) => o.id);
    const selectedOrgIds = updatedCheckedState.filter((s): s is string => !!s);
    onChange.current?.({
      selectAll: orgIds.length === selectedOrgIds.length,
      orgIds: selectedOrgIds,
    });
  };

  const handleSelectAll = () => {
    if (!organizations) return;
    const orgIds = organizations.map((o) => o.id);
    setCheckedState(orgIds);
    onChange.current?.({ selectAll, orgIds });
  };

  const handleSelectPaid = () => {
    if (!organizations) return;
    const orgIds = organizations
      .filter(
        (o) =>
          o.subscription.plan !==
          EnumsOrgSubscriptionPlan.OrgSubscriptionPlanFree
      )
      .map((o) => o.id);
    setCheckedState(
      organizations.map((o) => (orgIds.includes(o.id) ? o.id : null))
    );
    onChange.current?.({ selectAll, orgIds });
  };

  const handleSelectNone = () => {
    if (!organizations) return;
    const orgIds = organizations.map(() => null);
    setCheckedState(orgIds);
    onChange.current?.({ selectAll, orgIds: [] });
  };

  return (
    <div
      className={`w-full ${maxTableHeight} border border-secondary rounded-xl py-4 px-5 overflow-auto scrollbar ${
        disabled ? 'opacity-40' : ''
      }`}
    >
      <table className='w-full text-sms text-left'>
        <thead className='uppercase'>
          <tr>
            <th>
              <span>ORG</span>
              <span className='normal-case text-3xs text-primary font-light ml-2'>
                <span onClick={handleSelectAll} className='cursor-pointer'>
                  All
                </span>
                <span className='text-secondary mx-1'>/</span>
                <span onClick={handleSelectPaid} className='cursor-pointer'>
                  Paid
                </span>
                <span className='text-secondary mx-1'>/</span>
                <span onClick={handleSelectNone} className='cursor-pointer'>
                  None
                </span>
              </span>
            </th>
            {columns?.names.map((name, index) => (
              <th key={index}>{name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {organizations?.map((o, index) => (
            <OrganizationItem
              key={o.id}
              organization={o}
              columns={props.columns}
              checked={!!checkedState[index]}
              onChange={() => handleOnChange(index)}
              disabled={disabled}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export function OrganizationSelector(props: Props): JSX.Element {
  const [selectAll, setSelectAll] = useState(false);

  return (
    <div className='w-full h-full'>
      {props.title ?? (
        <div className='text-2xl font-medium'>Target Organizations</div>
      )}
      <div className='flex items-center mt-4 mb-2'>
        <span className='text-white font-bold mr-4'>Select All</span>
        <SwitcherControlled
          name='select-all'
          className=''
          checked={selectAll}
          onChange={(checked: boolean): void => {
            setSelectAll(checked);
          }}
          disabled={props.disabled}
        />
      </div>
      <OrganizationList selectAll={selectAll} {...props} />
    </div>
  );
}
