import {
  type DtoSharedAsset,
  type EnumsMediaScene,
  type EnumsSharedAssetPurpose,
} from '@lp-lib/api-service-client/public';
import {
  type Media,
  type MediaData,
  MediaType,
  VolumeLevel,
} from '@lp-lib/game';

import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';
import { useOpenShareAssetPickerModal } from '../SharedAsset';
import { MiniMediaUploader } from './MiniMediaUploader';
import { VolumeSelect } from './VolumeSelect';

type SharedProps = {
  /**
   * Sitewide unique id for Uppy.
   *
   * See: https://uppy.io/docs/uppy/#id-39-uppy-39
   */
  id: string;

  mediaData?: MediaData | null;
  media?: Media | null;

  volumeSelectable?: boolean;
  volumeStyle?: 'col' | 'row';

  onChange: (mediaData: MediaData | null, media: Media | null) => void;

  styles?: Partial<{
    bg: string;
  }>;

  objectFit?: 'contain' | 'cover';

  scene?: EnumsMediaScene;

  sharedAssetPurposes?: EnumsSharedAssetPurpose[];
  onSharedAssetSelected?: (item: DtoSharedAsset) => void;
  disabled?: boolean;
};

type ImageVideoMiniEditorProps = {
  video?: boolean;
} & SharedProps;

type AudioVideoMiniEditorProps = {
  audio: true;
} & SharedProps;

type MiniMediaEditorProps =
  | ImageVideoMiniEditorProps
  | AudioVideoMiniEditorProps;

export function MiniMediaEditor(props: MiniMediaEditorProps): JSX.Element {
  const {
    id,
    mediaData,
    media,
    onChange,
    volumeSelectable,
    volumeStyle = 'col',
    sharedAssetPurposes,
    onSharedAssetSelected,
  } = props;

  const openShareAssetPickerModal = useOpenShareAssetPickerModal();

  const handleUploadSuccess = (media: Media) => {
    onChange(
      {
        id: media.id,
        volumeLevel: VolumeLevel.Full,
      },
      media
    );
  };

  const handleDelete = () => {
    onChange(null, null);
  };

  const handleVolumeChange = (volumeLevel: VolumeLevel) => {
    if (!media) return;

    onChange(
      {
        id: media?.id,
        volumeLevel,
      },
      media
    );
  };

  return (
    <div
      className={`
        flex ${volumeStyle === 'col' ? 'flex-col' : 'flex-row'}
        gap-1 items-center
      `}
    >
      <div className='relative group'>
        {!!uncheckedIndexAccess_UNSAFE(props)['audio'] ? (
          <MiniMediaUploader
            uploaderId={id}
            audio
            media={media}
            onUploadSuccess={handleUploadSuccess}
            onDelete={handleDelete}
            customBgStyle={props.styles?.bg}
            scene={props.scene}
            objectFit={props.objectFit}
            disabled={props.disabled}
          />
        ) : (
          <MiniMediaUploader
            uploaderId={id}
            video={!!uncheckedIndexAccess_UNSAFE(props)['video']}
            media={media}
            onUploadSuccess={handleUploadSuccess}
            onDelete={handleDelete}
            customBgStyle={props.styles?.bg}
            scene={props.scene}
            objectFit={props.objectFit}
            disabled={props.disabled}
          />
        )}
        {onSharedAssetSelected && (
          <div
            className='group-hover:flex hidden absolute right-1 bottom-1 cursor-pointer text-xs'
            onClick={() =>
              openShareAssetPickerModal({
                purposes: sharedAssetPurposes,
                onSelected: onSharedAssetSelected,
              })
            }
          >
            🏞️
          </div>
        )}
      </div>

      {volumeSelectable && (
        <VolumeSelect
          volumeLevel={mediaData?.volumeLevel}
          onChange={handleVolumeChange}
          disabled={!media || media.type === MediaType.Image}
        />
      )}
    </div>
  );
}
