import { useNavigate } from '@remix-run/react';
import { useCallback, useMemo } from 'react';
import useSWR, { type SWRResponse } from 'swr';
import useSWRMutation from 'swr/mutation';

import {
  type DtoOrgSubscription,
  type DtoProduct,
  EnumsExConnectType,
  EnumsOrgSubscriptionPlan,
  type ModelsPrice,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../../services/api-service';
import {
  type Organization,
  type Organizer,
  OrganizerRoleUtils,
  type OrgConnectionBase,
} from '../../../types';
import {
  FeatureChecker,
  type IFeatureChecker,
} from '../../Product/FeatureChecker';
import { ProductSearchParam } from '../../Product/utils';
import { useUser, useUserContext } from '../../UserContext';

export function useOrganizations(): SWRResponse<Organization[], Error> {
  return useSWR(
    '/organizations',
    async () =>
      (
        await apiService.organization.getAllOrganizations()
      ).data.organizations.sort((a, b) => a.name.localeCompare(b.name)) || []
  );
}

export function useOrganization(id: string): SWRResponse<Organization, Error> {
  return useSWR(
    `/organizations/${id}`,
    async () =>
      (await apiService.organization.getOrganization(id)).data.organization
  );
}

export function useOrganizationSubscription(
  id?: string | null
): SWRResponse<DtoOrgSubscription | null, Error> {
  return useSWR(
    id ? `/organizations/${id}/subscription` : null,
    () => {
      if (!id) {
        return null;
      }
      return apiService.organization
        .getSubscriptionDetails(id)
        .then((r) => r.data);
    },
    { shouldRetryOnError: false }
  );
}

type UpdateOrganizationSubscriptionArgs = {
  arg: {
    organization: Organization;
    product: DtoProduct;
    price: ModelsPrice | null;
    trial: boolean;
  };
};

export function useUpdateOrganizationSubscription(
  orgId: string,
  opts?: {
    // relative path to redirect to after successful checkout
    checkoutSuccess?: string;
    // relative path to redirect to in case checkout is canceled
    checkoutCancel?: string;
  }
) {
  const navigate = useNavigate();

  return useSWRMutation(
    `/organizations/${orgId}/subscription`,
    async (_, { arg }: UpdateOrganizationSubscriptionArgs) => {
      const { organization, product, price, trial } = arg;
      const isCancellation = price === null || price.amount === 0;

      if (organization.subscription.subscriptionId) {
        if (isCancellation) {
          await apiService.organization.cancelSubscriptionPlan(organization.id);
        } else if (price) {
          await apiService.organization.upgradeDowngradeSubscriptionPlan(
            organization.id,
            {
              productId: product.id,
              priceId: price.id,
            }
          );
        } else {
          throw new Error('cannot checkout. invalid price.');
        }
      } else if (price) {
        if (price.amount === 0) {
          await apiService.organization.upgradeDowngradeSubscriptionPlan(
            organization.id,
            {
              productId: product.id,
              priceId: price.id,
            }
          );
        } else {
          navigate({
            pathname: '/subscription/change/checkout',
            search: new URLSearchParams({
              [ProductSearchParam.TargetProduct]: product.id,
              [ProductSearchParam.TargetPrice]: price.id,
              [ProductSearchParam.Trial]: trial.toString(),
              [ProductSearchParam.RedirectTo]: opts?.checkoutSuccess || '',
            }).toString(),
          });
          return {
            checkout: true,
          };
        }
      }
    }
  );
}

export function useMyOrganization(): Organization | null {
  const user = useUser();
  return user.organizer?.organization || null;
}

export function useUpdateMyOrganization() {
  const updateMyOrganizer = useUpdateMyOrganizer();
  const organizer = useMyOrganizer();

  return useCallback(
    (updated: Organization) => {
      if (!organizer) {
        return;
      }
      updateMyOrganizer({
        ...organizer,
        organization: updated,
      });
    },
    [organizer, updateMyOrganizer]
  );
}

export function useMyOrganizer(): Organizer | null {
  const user = useUser();
  return user.organizer || null;
}

export function useUpdateMyOrganizer() {
  const { updateUser } = useUserContext();
  return useCallback(
    (updated: Organizer) => {
      updateUser({
        organizer: updated,
      });
    },
    [updateUser]
  );
}

export function useMyOrgId(): string | null {
  const user = useUser();
  return user.organizer?.orgId || null;
}

export function useMyOrgConnection(): OrgConnectionBase | null {
  return useMyOrganization()?.connection || null;
}

export function useIsMyOrgSlackConnected(): boolean {
  const connection = useMyOrgConnection();
  return useMemo(() => {
    return (
      !!connection &&
      connection.type === EnumsExConnectType.ExConnectTypeSlack &&
      connection.status === 'active'
    );
  }, [connection]);
}

export function useIsMySlackUserConnected(): boolean {
  const isMyOrgSlackConnected = useIsMyOrgSlackConnected();
  const organizer = useMyOrganizer();
  return isMyOrgSlackConnected && !!organizer?.exUserId;
}

export function useMyOrgSubscription(): DtoOrgSubscription | null {
  return useMyOrganization()?.subscription || null;
}

export function useIsMyOrganizationFree(): boolean {
  return useMyOrgSubscription()?.plan === 'free';
}

export function useMyOrganizationFeatureChecker(): IFeatureChecker {
  const user = useUser();
  return useMemo(() => FeatureChecker.GetFeatureChecker(user), [user]);
}

const CANCEL_OFFER_COUPON_IDS = ['ECAIF7Sh', 'YxIdAw18'];

function useDuringCancelOffer(organization: Organization | null) {
  const { data: sub } = useOrganizationSubscription(
    // free plan must not be in cancel offer
    organization &&
      organization?.subscription.plan !==
        EnumsOrgSubscriptionPlan.OrgSubscriptionPlanFree
      ? organization?.id
      : null
  );
  return (
    sub?.discount?.coupon?.id &&
    CANCEL_OFFER_COUPON_IDS.includes(sub.discount.coupon.id)
  );
}

export function useCanScheduleLiveEvents() {
  const user = useUser();
  const org = useMyOrganization();
  const duringCancelOffer = useDuringCancelOffer(org);
  if (duringCancelOffer) {
    return false;
  }
  const isOwnerOrAdmin = OrganizerRoleUtils.isOwnerOrAdmin(user.organizer);
  return org?.settings?.nonAdminScheduleLiveEventsDisabled
    ? isOwnerOrAdmin
    : true;
}
