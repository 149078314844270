import { useNavigate } from '@remix-run/react';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { $path } from 'remix-routes';

import {
  type DtoProduct,
  type ModelsGamePackPrice,
  type ModelsPrice,
} from '@lp-lib/api-service-client/public';

import {
  type AnalyticsPurchaseDetails,
  useBookNowAnalytics,
} from '../../analytics/bookNow';
import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import { OrganizerRoleUtils } from '../../types';
import type { GamePack } from '../../types/game';
import { fromDTOGamePack } from '../../utils/api-dto';
import { getStaticAssetPath } from '../../utils/assets';
import { MediaUtils } from '../../utils/media';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { GamePackUtils } from '../Game/GamePack/utils';
import { useFetchGameSessionGamePack } from '../Game/hooks';
import { FilledCheckIcon } from '../icons/CheckIcon';
import { Loading } from '../Loading';
import { CompaniesUsingLunaPark } from '../Marketing/CompaniesUsingLunaPark';
import { PurchaseMethod } from '../OneTimePurchase/type';
import { useUser } from '../UserContext';
import { useVenueOwner } from '../Venue';
import { HeadcountInput } from './HeadcountInput';
import { useMostPopularProduct } from './useProducts';
import {
  CONFIRM_SUBSCRIPTION_CHANGE_URL,
  ProductSearchParam,
  ProductUtils,
  useOpenLetsChatWindow,
} from './utils';

export type TriggerBookNowOpts = {
  redirectExistingSubscribers?: string;
};

export function useTriggerBookNow(opts: TriggerBookNowOpts = {}) {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const navigate = useNavigate();
  return useLiveCallback(
    async (gamePack: GamePack | string, trigger?: string) => {
      const redirectExistingSubscribers = opts.redirectExistingSubscribers;
      if (redirectExistingSubscribers) {
        navigate(redirectExistingSubscribers);
        return;
      }

      let pack: GamePack;
      if (typeof gamePack === 'string') {
        const resp = await apiService.gamePack.getGamePackById(gamePack);
        pack = fromDTOGamePack(resp.data.gamePack);
      } else {
        pack = gamePack;
      }

      await triggerModal({
        kind: 'custom',
        element: (p) => (
          <BookNowModal
            gamePack={pack}
            onClose={p.internalOnCancel}
            trigger={trigger}
          />
        ),
      });
    }
  );
}

export function useTriggerBookNowWithGameSessionGamePack(
  opts: TriggerBookNowOpts = {}
) {
  const triggerBookNow = useTriggerBookNow(opts);
  const gamePack = useFetchGameSessionGamePack();

  return useLiveCallback(async (trigger?: string) => {
    if (gamePack) {
      await triggerBookNow(gamePack, trigger);
    }
  });
}

export function BookNowModal(props: {
  gamePack: GamePack;
  onClose?: () => void;
  trigger?: string;
}) {
  const here = `${window.location.pathname}${window.location.search}`;
  const analytics = useBookNowAnalytics();
  const navigate = useNavigate();
  const user = useUser();
  const { data, isLoading, error } = useMostPopularProduct();
  const isOwnerOrAdmin = useMemo(() => {
    return OrganizerRoleUtils.isOwnerOrAdmin(user?.organizer?.role);
  }, [user]);

  const handleContinueBookingSubscription = useLiveCallback(
    async (product: DtoProduct, price: ModelsPrice) => {
      const organization = user?.organizer?.organization;
      if (!isOwnerOrAdmin || !organization) return;
      if (organization.subscription.subscriptionId) {
        const params = new URLSearchParams();
        params.set(ProductSearchParam.TargetProduct, product.id);
        params.set(ProductSearchParam.TargetPrice, price.id);
        params.set(ProductSearchParam.RedirectTo, here);
        navigate(`${CONFIRM_SUBSCRIPTION_CHANGE_URL}?${params.toString()}`);
      } else {
        const searchParams = new URLSearchParams();
        searchParams.set('pack-id', props.gamePack.id);
        searchParams.set('product-id', product.id);
        searchParams.set('price-id', price.id);
        searchParams.set('purchase-method', PurchaseMethod.Subscription);
        searchParams.set('cancel-url', here);
        searchParams.set('headcount', price.maxSeats.toString());
        searchParams.set('redirect-to', here);
        navigate($path('/onboarding/billing', searchParams));
      }
    }
  );

  const handleContinueBookingOneOff = useLiveCallback(
    (price: ModelsGamePackPrice, headcount: number | undefined) => {
      const searchParams = new URLSearchParams();
      searchParams.set('pack-id', props.gamePack.id);
      searchParams.set('price-id', price.id);
      searchParams.set('purchase-method', PurchaseMethod.OneTimePurchase);
      searchParams.set('cancel-url', here);
      if (headcount) {
        searchParams.set('headcount', headcount.toString());
      }
      navigate(`/checkout?${searchParams.toString()}`);
    }
  );

  const onClose = props.onClose;
  const handleClose = useMemo(() => {
    return onClose
      ? () => {
          analytics.trackBookNowModalDismissed();
          onClose();
        }
      : undefined;
  }, [analytics, onClose]);

  const hasEmittedAnalytics = useRef(false);
  useEffect(() => {
    if (hasEmittedAnalytics.current || !data?.product) return;
    hasEmittedAnalytics.current = true;
    analytics.trackBookNowModalViewed({
      trigger: props.trigger,
      canPurchase: isOwnerOrAdmin,
      hasOneTimePurchasePrice:
        GamePackUtils.ActivePrices(props.gamePack).length > 0,
      gamePackId: props.gamePack.id,
      gamePackName: props.gamePack.name,
      productId: data?.product.id,
      productName: data?.product.name,
    });
  }, [
    analytics,
    isOwnerOrAdmin,
    data?.product?.id,
    data?.product?.name,
    props.gamePack,
    props.trigger,
    data?.product,
  ]);

  if (isLoading) {
    return (
      <div className='w-full h-full flex items-center justify-center'>
        <div className='animatejit-[fade-in_1s_ease_3s_forwards] opacity-0'>
          <Loading text='' />
        </div>
      </div>
    );
  }

  const product = data?.product;
  if (!product || error) {
    // something is incorrect.
    props.onClose?.();
    return <></>;
  }

  return (
    <div className='w-full h-full flex items-center justify-center animatejit-[fade-in_1_300ms_ease]'>
      <BookNowWidget
        onContinueBookingSubscription={handleContinueBookingSubscription}
        onContinueBookingOneOff={handleContinueBookingOneOff}
        gamePack={props.gamePack}
        subscriptionProduct={product}
        disabled={!isOwnerOrAdmin}
        onClose={handleClose}
      />
      <footer className='vh-sm:hidden absolute bottom-12 left-0 right-0 flex flex-col items-center justify-center gap-4'>
        <CompaniesPromotion />
      </footer>
    </div>
  );
}

function BookNowIntercom() {
  const { showNewMessage, hide, isOpen } = useIntercom();
  const handleClick = useLiveCallback(() => {
    showNewMessage();
  });

  useEffect(() => {
    return () => hide();
  }, [hide]);

  return (
    <button
      type='button'
      className='btn text-icon-gray text-xs underline hover:no-underline'
      onClick={handleClick}
      disabled={isOpen}
    >
      Questions? We’re here to help.
    </button>
  );
}

export function BookNowWidget(props: {
  gamePack?: GamePack | null;
  subscriptionProduct: DtoProduct;
  onContinueBookingSubscription: (
    product: DtoProduct,
    price: ModelsPrice
  ) => void;
  onContinueBookingOneOff?: (
    price: ModelsGamePackPrice,
    headcount: number | undefined
  ) => void;
  disabled?: boolean;
  onClose?: () => void;
  productDescriptionTitle?: string;
  hideTalkToSomeone?: boolean;
}) {
  const [headcount, setHeadcount] = useState<number | undefined>(15);
  const otpCheckout = useFeatureQueryParam('otp-checkout');
  const hasOneTimePurchasePricingTable =
    otpCheckout && GamePackUtils.ActivePrices(props.gamePack).length > 0;

  return (
    <div className='flex items-center'>
      <ProductDescription
        isSubscription={!hasOneTimePurchasePricingTable}
        headcount={headcount}
        onHeadcountChange={setHeadcount}
        gamePack={props.gamePack}
        subscriptionProduct={props.subscriptionProduct}
        title={props.productDescriptionTitle}
      />
      <InstantQuote
        headcount={headcount}
        gamePack={props.gamePack}
        subscriptionProduct={props.subscriptionProduct}
        isSubscription={!hasOneTimePurchasePricingTable}
        onContinueBookingSubscription={props.onContinueBookingSubscription}
        onContinueBookingOneOff={props.onContinueBookingOneOff}
        disabled={props.disabled}
        onClose={props.onClose}
        hideTalkToSomeone={props.hideTalkToSomeone}
      />
    </div>
  );
}

const headcountMax = 999;

const bookNowMembershipBg = getStaticAssetPath(
  'images/book-now-membership-bg.png'
);

function ProductDescription(props: {
  isSubscription: boolean;
  headcount: number | undefined;
  onHeadcountChange: (value: number | undefined) => void;
  gamePack?: GamePack | null;
  subscriptionProduct: DtoProduct;
  title?: string;
}) {
  const {
    isSubscription,
    headcount,
    onHeadcountChange,
    gamePack,
    subscriptionProduct,
  } = props;

  const analytics = useBookNowAnalytics();
  const trackHeadcountChanged = useMemo(
    () =>
      debounce(
        (headcount) =>
          analytics.trackBookNowModalHeadcountChanged({ headcount }),
        500,
        { trailing: true }
      ),
    [analytics]
  );

  const handleHeadcountChange = useLiveCallback((value: number | undefined) => {
    onHeadcountChange(value);
    trackHeadcountChanged(value);
  });

  const gamePackCover = gamePack?.cover;
  const title =
    props.title || isSubscription
      ? 'Upgrade Your Membership'
      : 'Plan This Experience';

  return (
    <div className='bg-main-layer w-96 min-h-[450px] border border-secondary flex flex-col items-center gap-5 rounded-xl'>
      <div className='text-tertiary text-xl font-bold pt-6.5 px-10'>
        {title}
      </div>

      <div className='px-10 w-full'>
        <div className='relative w-full h-[170px] bg-layer-002 rounded-xl border border-secondary overflow-hidden'>
          {isSubscription ? (
            <>
              <img
                className='relative w-full h-full object-cover'
                alt=''
                src={bookNowMembershipBg}
              />
              <div className='absolute inset-0 flex items-center justify-center'>
                <div className='font-Montserrat font-black text-3.5xl uppercase text-tertiary w-min text-center'>
                  {subscriptionProduct.name}
                </div>
              </div>
            </>
          ) : gamePackCover ? (
            <img
              className='relative w-full h-full object-cover'
              alt=''
              src={MediaUtils.PickMediaUrl(gamePackCover) ?? ''}
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className='px-10 w-full'>
        <HeadcountInput
          value={headcount}
          onChange={handleHeadcountChange}
          min={gamePack?.playerRange.min}
          max={headcountMax}
        />
      </div>

      <div className='px-10 w-full flex justify-center italic text-sms text-icon-gray'>
        {isSubscription ? <>Cancel Anytime</> : <>Book now, increase later!</>}
      </div>
    </div>
  );
}

const OneOffExperienceFeatures = React.memo(() => {
  return (
    <div className='flex-1 w-full pt-7.5 px-7.5'>
      <div className='text-sms font-bold'>One Off Experience</div>
      <div className='pt-2.5 space-y-2.5'>
        {[
          'Available 24/7, Schedule Anytime',
          'Trusted By Global HR Leaders',
          'Cancel Anytime',
        ].map((feature, index) => (
          <div key={index} className='flex items-start'>
            <div className='pt-0.5 text-green-001'>
              <FilledCheckIcon className='w-3.5 h-3.5 fill-current' />
            </div>
            <div className='pl-2 text-sms text-black'>{feature}</div>
          </div>
        ))}
      </div>
    </div>
  );
});

const SubscriptionFeatures = React.memo((props: { product: DtoProduct }) => {
  return (
    <div className='flex-1 w-full pt-7.5 px-7.5'>
      <div className='text-sms font-bold'>Membership</div>
      <div className='pt-2.5 space-y-2.5'>
        {props.product.features.map((feature) => (
          <div key={feature.id} className='flex items-start'>
            <div className='pt-0.5 text-green-001'>
              <FilledCheckIcon className='w-3.5 h-3.5 fill-current' />
            </div>
            <div className='pl-2 text-sms text-black'>
              {feature.description ?? feature.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

function InstantQuote(props: {
  headcount: number | undefined;
  gamePack?: GamePack | null;
  subscriptionProduct: DtoProduct;
  isSubscription: boolean;
  onContinueBookingSubscription: (
    product: DtoProduct,
    price: ModelsPrice
  ) => void;
  onContinueBookingOneOff?: (
    price: ModelsGamePackPrice,
    headcount: number | undefined
  ) => void;
  disabled?: boolean;
  onClose?: () => void;
  hideTalkToSomeone?: boolean;
}) {
  const {
    headcount,
    gamePack,
    subscriptionProduct,
    isSubscription,
    onContinueBookingSubscription,
    onContinueBookingOneOff,
    disabled,
    onClose,
    hideTalkToSomeone,
  } = props;

  const analytics = useBookNowAnalytics();
  const [subscriptionPrice, subscriptionPriceElement, letsChatSubscription] =
    useMemo(() => {
      const prices =
        ProductUtils.ActivePricesBySmallestBillingInterval(subscriptionProduct);
      if (prices.length === 0) return [undefined, null, false];

      let price: ModelsPrice | undefined = prices[0];
      if (headcount && headcount > price.maxSeats) {
        price = ProductUtils.FindPrice(prices, headcount);
        if (!price) {
          return [
            undefined,
            <div className='w-full text-center text-sms'>
              Let’s chat about your needs!
            </div>,
            true,
          ];
        }
      }

      return [
        price,
        <>
          <div>Instant Quote</div>
          <div>
            ${price.amount} USD
            <span className='text-xs'>
              /{ProductUtils.FormatInterval(price.billingInterval, true)}
            </span>
          </div>
        </>,
        false,
      ];
    }, [headcount, subscriptionProduct]);

  const [oneOffPrice, oneOffPriceElement, letsChatOneOff] = useMemo(() => {
    const prices = GamePackUtils.ActivePrices(gamePack);
    if (prices.length === 0) return [undefined, null, false];

    let price: ModelsGamePackPrice | undefined = prices[0];
    if (headcount && headcount > price.maxPlayers) {
      price = GamePackUtils.FindOneTimePrice(prices, headcount);
      if (!price) {
        return [
          undefined,
          <div className='w-full text-center text-sms'>
            Let’s chat about your needs!
          </div>,
          true,
        ];
      }
    }

    return [
      price,
      <>
        <div>Instant Quote</div>
        <div>${price.amount} USD</div>
      </>,
      false,
    ];
  }, [headcount, gamePack]);

  const navigateLetsChatTypeform = useOpenLetsChatWindow();

  const handleContinue = useLiveCallback(() => {
    if (isSubscription) {
      const purchaseDetails: AnalyticsPurchaseDetails = {
        purchaseMethod: PurchaseMethod.Subscription,
        productId: subscriptionProduct.id,
        productName: subscriptionProduct.name,
        priceId: subscriptionPrice?.id,
        headcount,
      };
      if (letsChatSubscription) {
        analytics.trackBookNowModalLetsChatClicked(purchaseDetails);
        navigateLetsChatTypeform();
        return;
      }
      if (subscriptionPrice) {
        analytics.trackBookNowModalContinueClicked(purchaseDetails);
        onContinueBookingSubscription(subscriptionProduct, subscriptionPrice);
        return;
      }
    } else if (gamePack) {
      const purchaseDetails: AnalyticsPurchaseDetails = {
        purchaseMethod: PurchaseMethod.OneTimePurchase,
        gamePackId: gamePack.id,
        gamePackName: gamePack.name,
        headcount,
      };
      if (letsChatOneOff) {
        analytics.trackBookNowModalLetsChatClicked(purchaseDetails);
        navigateLetsChatTypeform();
        return;
      }
      if (oneOffPrice) {
        analytics.trackBookNowModalContinueClicked(purchaseDetails);
        if (onContinueBookingOneOff) {
          onContinueBookingOneOff(oneOffPrice, headcount);
        }
        return;
      }
    }
  });

  return (
    <div className='relative bg-white w-90 min-h-85 max-h-full flex flex-col items-center gap-5 rounded-tr-xl rounded-br-xl text-black'>
      {isSubscription ? (
        <SubscriptionFeatures product={subscriptionProduct} />
      ) : (
        <OneOffExperienceFeatures />
      )}

      <div className='mt-auto px-7.5 pb-2.5 w-full'>
        <div className='w-full flex items-baseline justify-between border-b border-secondary pb-4.5 mb-4.5 text-xl font-medium'>
          {!headcount ? (
            <div className='w-full text-center text-sms'>
              Specify a headcount for an instant quote!
            </div>
          ) : gamePack && headcount < gamePack.playerRange.min ? (
            <div className='w-full text-center text-sms text-red-006'>
              Headcount must be larger than {gamePack.playerRange.min}.
            </div>
          ) : headcount > headcountMax ? (
            <div className='w-full text-center text-sms text-red-006'>
              Headcount may not exceed {headcountMax}.
            </div>
          ) : isSubscription ? (
            subscriptionPriceElement
          ) : (
            oneOffPriceElement
          )}
        </div>

        <div className='w-full flex flex-col justify-center gap-3'>
          <button
            type='button'
            className='w-full h-15 btn-primary'
            onClick={handleContinue}
            disabled={
              disabled ||
              (isSubscription && !subscriptionPrice && !letsChatSubscription) ||
              (!isSubscription && !oneOffPrice && !letsChatOneOff)
            }
          >
            {(isSubscription && letsChatSubscription) ||
            (!isSubscription && letsChatOneOff) ? (
              <>Let’s Chat!</>
            ) : isSubscription ? (
              <>Continue</>
            ) : (
              <>Book For Your Group</>
            )}
          </button>
          <BookNowIntercom />
        </div>
      </div>
      {!hideTalkToSomeone && <TalkToSomeoneToPurchase />}
      {onClose && (
        <div className='absolute -top-6 left-0 right-2 flex justify-end'>
          <button
            type='button'
            className='text-icon-gray hover:underline text-sms'
            onClick={onClose}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
}

function TalkToSomeoneToPurchase() {
  const venueOwner = useVenueOwner();
  const user = useUser();
  const isOwnerOrAdmin = useMemo(() => {
    return OrganizerRoleUtils.isOwnerOrAdmin(user?.organizer?.role);
  }, [user]);

  if (!isOwnerOrAdmin) {
    let someone = venueOwner.username;
    if (user.id === venueOwner.id) {
      someone = 'your account owner';
    }

    return (
      <div className='absolute bottom-0 left-0 right-0'>
        <div className='transform translate-y-full pt-2 px-4 text-center text-xs text-icon-gray'>
          Talk to {someone} to continue with this purchase.
        </div>
      </div>
    );
  }
  return null;
}

function CompaniesPromotion() {
  return (
    <div className='vh-sm:hidden w-full flex flex-col items-center'>
      <p className='text-sms text-icon-gray text-center italic'>
        Hundreds of companies subscribe to Luna Park to elevate their cultures
      </p>
      <CompaniesUsingLunaPark />
    </div>
  );
}
