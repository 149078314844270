import shuffle from 'lodash/shuffle';
import React, { type ReactNode, useContext, useEffect, useMemo } from 'react';
import { proxy } from 'valtio';

import { EnumsMediaScene } from '@lp-lib/api-service-client/public';
import {
  type DrawingPrompt,
  type DrawingPromptBlock,
  type DrawingPromptBlockDetailScore,
} from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';
import { VolumeLevel } from '@lp-lib/media';

import { useMyInstance } from '../../../../hooks/useMyInstance';
import { apiService } from '../../../../services/api-service';
import { type TeamId } from '../../../../types';
import { type DrawingSubmissionPayload } from '../../../../types/drawing';
import { randomPick, sleep, uuidv4 } from '../../../../utils/common';
import { loadImageAsPromise } from '../../../../utils/media';
import { uncheckedIndexAccess_UNSAFE } from '../../../../utils/uncheckedIndexAccess_UNSAFE';
import {
  markSnapshottable,
  useSnapshot,
  ValtioUtils,
} from '../../../../utils/valtio';
import { Clock } from '../../../Clock';
import {
  type FirebaseService,
  useFirebaseContext,
  useIsFirebaseConnected,
} from '../../../Firebase';
import { increment } from '../../../Firebase/utils';
import { useMyTeamId } from '../../../Player';
import { useIsStreamSessionAlive } from '../../../Session';
import { useTeams } from '../../../TeamAPI/TeamV1';
import { useVenueId } from '../../../Venue/VenueProvider';
import { useGameSessionStatusChangedAt } from '../../hooks';
import { updateBlockDetailScore } from '../../store';
import { PromptPoolManager } from '../Common/GamePlay/utils';
import {
  type CurrentMatch,
  type DrawingTitle,
  type DrawingTitleVote,
  type Game,
  type GameProgressSummary,
  type MatchPromptState,
  type MiniDrawing,
  type PickedDrawing,
  type TeamState,
  type TeamStateMap,
  type VoteTitlePayload,
} from './types';
import { DrawingPromptFBUtils, DrawingPromptUtils, log } from './utils';

export type DrawingPromptSharedState = {
  game: Nullable<Game>;
  teamStateMap: Nullable<TeamStateMap>;
  matchPromptState: Nullable<MatchPromptState>;
};

class DrawingPromptSharedAPI {
  private _state;
  constructor(
    venueId: string,
    svc: FirebaseService,
    private gameHandle = DrawingPromptFBUtils.GameHandle(svc, venueId),
    private teamStateHandle = DrawingPromptFBUtils.TeamStateHandle(
      svc,
      venueId
    ),
    private mpsHandle = DrawingPromptFBUtils.MatchPromptStateHandle(
      svc,
      venueId
    )
  ) {
    this._state = markSnapshottable(
      proxy<DrawingPromptSharedState>(this.initialState())
    );
  }
  get state() {
    return this._state;
  }
  on(): void {
    this.gameHandle.on((val) => ValtioUtils.set(this._state, 'game', val));
    this.teamStateHandle.on((val) =>
      ValtioUtils.set(this._state, 'teamStateMap', val)
    );
    this.mpsHandle.on((val) =>
      ValtioUtils.set(this._state, 'matchPromptState', val)
    );
  }
  off(): void {
    this.gameHandle.off();
    this.teamStateHandle.off();
    this.mpsHandle.off();
  }

  async getGame(): Promise<Game | null> {
    return await this.gameHandle.get();
  }

  getPrompt(teamId: Nullable<TeamId>): DrawingPrompt | null {
    if (!teamId) return null;
    return this.state.teamStateMap?.[teamId]?.prompt ?? null;
  }

  async getNumOfPickedDrawings(excludedId?: string) {
    const state = await this.teamStateHandle.get();
    let n = 0;
    for (const teamState of Object.values(state ?? {})) {
      if (
        teamState.pickedDrawing &&
        teamState.pickedDrawing.id !== excludedId
      ) {
        n += 1;
      }
    }
    return n;
  }

  reset() {
    ValtioUtils.reset(this._state, this.initialState());
  }

  private initialState(): DrawingPromptSharedState {
    return {
      game: null,
      teamStateMap: null,
      matchPromptState: null,
    };
  }
}

class DrawingPromptGameControlAPI {
  private _drawingPlayerIds: string[] = [];
  constructor(
    venueId: string,
    svc: FirebaseService,
    private log: Logger,
    private rootHandle = DrawingPromptFBUtils.RootHandle(svc, venueId),
    private gameHandle = DrawingPromptFBUtils.GameHandle(svc, venueId),
    private mpsHandle = DrawingPromptFBUtils.MatchPromptStateHandle(
      svc,
      venueId
    ),
    private promptManager = new PromptPoolManager<DrawingPrompt>(),
    private clock = Clock.instance()
  ) {}

  async initGame(
    teamIds: TeamId[],
    prompts: DrawingPrompt[],
    forceTeamVote?: boolean
  ) {
    this.log.info('init game', { numOfPrompts: prompts.length });
    if (prompts.length === 0) return;
    this.promptManager.init(prompts);
    const updates = uncheckedIndexAccess_UNSAFE({});
    // Each team get a prompt
    for (const teamId of teamIds) {
      updates[DrawingPromptFBUtils.TeamPath(teamId, 'prompt')] =
        this.promptManager.pick();
    }
    await this.rootHandle.update(updates);
    if (forceTeamVote) {
      await this.gameHandle.update({
        forceTeamVote,
      });
    }
  }

  async configureTitleCreation(titleCreationTimeSec: number) {
    this.log.info('configure title creation', { titleCreationTimeSec });
    await this.gameHandle.update({
      titleCreationTimeSec,
    });
  }

  async configureMatchPrompt(matchTimePerDrawing: number) {
    this.log.info('configure match prompt', { matchTimePerDrawing });
    await this.gameHandle.update({
      matchTimePerDrawing,
    });
  }

  async revealCurrentMatch(index: number, debug?: string) {
    this.log.info('enter reveal phase', {
      drawingIndex: index,
      debug,
    });
    await this.mpsHandle.ref.child('currentMatch').update({
      drawingIndex: index,
      phase: 'reveal',
    });
  }

  private async revealVote(
    drawingTeamId: TeamId,
    vote: DrawingTitleVote,
    correct: boolean,
    score: {
      correctPoints: number;
      incorrectPoints: number;
    }
  ) {
    this.log.info('reveal vote', { drawingTeamId, vote, correct });
    const result = await this.mpsHandle.ref
      .child('currentMatch')
      .transaction((v) => {
        // inpossible, abort transaction
        if (!v) return;
        return {
          ...v,
          revealedTitles: [
            ...(v.revealedTitles ?? []),
            { id: vote.titleId, revealedAt: this.clock.now() },
          ],
        };
      });
    if (!result.committed) return;
    const teamPoints = DrawingPromptUtils.GetVotePoints(
      drawingTeamId,
      vote,
      correct,
      score
    );
    this.log.info('reveal vote, team points', { teamPoints });
    const promises: Promise<void>[] = [];
    for (const [teamId, points] of teamPoints) {
      promises.push(
        updateBlockDetailScore<DrawingPromptBlockDetailScore>(teamId, {
          score: increment(points),
          submittedAt: Date.now(),
        })
      );
    }
    await Promise.all(promises);
  }

  async revealVotes(
    drawingTeamId: TeamId,
    drawingId: string,
    correctTitleId: string,
    totalDrawings: number,
    score: {
      correctPoints: number;
      incorrectPoints: number;
    },
    revealWaitMs = DrawingPromptUtils.GetRevealWaitSecPerTitle() * 1000
  ) {
    this.log.info('reveal votes');
    const matchPromptState = await this.mpsHandle.get();
    let correctTitleVote: DrawingTitleVote | null = null;
    const votes: DrawingTitleVote[] = [];
    const voteMap = matchPromptState?.votes ?? {};
    for (const [, vote] of Object.entries(voteMap)) {
      if (vote.drawingId === drawingId) {
        if (vote.titleId === correctTitleId) {
          correctTitleVote = vote;
        } else {
          votes.push(vote);
        }
      }
    }
    if (correctTitleVote === null) {
      correctTitleVote = {
        drawingId,
        titleId: correctTitleId,
        titleTeamId: drawingTeamId,
        voters: [],
      };
    }
    const sortedVotes = votes.sort((a, b) => a.voters.length - b.voters.length);
    for (const vote of sortedVotes) {
      await this.revealVote(drawingTeamId, vote, false, score);
      await sleep(revealWaitMs);
    }
    await this.revealVote(drawingTeamId, correctTitleVote, true, score);
    await sleep(revealWaitMs);
    const currIndex = matchPromptState?.currentMatch?.drawingIndex ?? 0;
    if (currIndex + 1 >= totalDrawings) {
      return -1;
    } else {
      return currIndex + 1;
    }
  }

  async nextMatch(nextIndex: number) {
    this.log.info('move to next match', { drawingIndex: nextIndex });
    await this.mpsHandle.ref.child('currentMatch').set({
      drawingIndex: nextIndex,
      phase: 'vote',
      revealedTitles: [],
      drawingSwitchedAt: this.clock.now(),
    });
  }

  async endMatch() {
    this.log.info('end match');
  }

  set drawingPlayerIds(playerIds: string[]) {
    this._drawingPlayerIds = playerIds;
  }

  get drawingPlayerIds(): string[] {
    return this._drawingPlayerIds;
  }

  async resetGame() {
    this.log.info('reset game');
    this._drawingPlayerIds = [];
    await this.rootHandle.remove();
  }
}

class DrawingPromptGamePlayAPI {
  private _state;
  constructor(
    venueId: string,
    private teamId: TeamId,
    svc: FirebaseService,
    readonly log: Logger,
    private clock = Clock.instance(),
    private promptHandle = DrawingPromptFBUtils.PromptHandle(
      svc,
      venueId,
      teamId
    ),
    private drawingsHandle = DrawingPromptFBUtils.DrawingsHandle(
      svc,
      venueId,
      teamId
    ),
    private votesHandle = DrawingPromptFBUtils.VotesHandle(
      svc,
      venueId,
      teamId
    ),
    private pickedDrawingHandle = DrawingPromptFBUtils.PickedDrawingHandle(
      svc,
      venueId,
      teamId
    ),
    private titlesHandle = DrawingPromptFBUtils.TitlesHandle(
      svc,
      venueId,
      teamId
    ),
    private mpsHandle = DrawingPromptFBUtils.MatchPromptStateHandle(
      svc,
      venueId
    )
  ) {
    this._state = markSnapshottable(proxy<TeamState>(this.initialState()));
  }

  async submitDrawing(payload: DrawingSubmissionPayload) {
    this.log.info('submit drawing');
    const mediaResp = await apiService.media.upload(payload.blob, {
      scene: EnumsMediaScene.MediaSceneDrawingPrompt,
    });
    this.log.info('submitted drawing media', { media: mediaResp.data.media });
    const resp = await apiService.drawing.create({
      sessionId: payload.sessionId,
      orgId: payload.orgId,
      artworkMediaData: {
        id: mediaResp.data.media.id,
        volumeLevel: VolumeLevel.Full,
      },
      data: payload.data,
    });
    await this.drawingsHandle.ref.child(payload.playerId).set({
      id: resp.data.drawing.id,
      url: mediaResp.data.media.url,
      playerId: payload.playerId,
    });
    // no need to log data
    const { data: _, ...rest } = resp.data.drawing;
    this.log.info('submitted drawing', { drawing: rest });
    return resp.data.drawing;
  }

  async voteDrawing(playerId: string, drawingId: string) {
    this.log.info('vote drawing', { playerId, drawingId });
    await this.votesHandle.ref.child(playerId).set(drawingId);
  }

  async unvoteDrawing(playerId: string, drawingId: string) {
    this.log.info('unvote drawing', { playerId, drawingId });
    await this.votesHandle.ref.child(playerId).remove();
  }

  async pickTeamDrawing(
    fallbackEnabled: boolean,
    triggeredBy: 'all-voted' | 'times-up' | 'single-drawing'
  ) {
    const ret = await Promise.all([
      this.votesHandle.get(),
      this.pickedDrawingHandle.get(),
    ]);
    const voteMap = ret[0] ?? {};
    const pickedDrawing = ret[1];
    if (pickedDrawing) {
      this.log.info('team drawing already picked', { pickedDrawing });
      return;
    }
    this.log.info('pick team drawing from votes', {
      teamId: this.teamId,
      voteMap,
      debug: triggeredBy,
    });
    let maxVotes = 0;
    const drawingVotes: { [key: string]: number } = {};
    for (const drawingId of Object.values(voteMap)) {
      const votes = (drawingVotes[drawingId] ?? 0) + 1;
      if (votes > maxVotes) {
        maxVotes = votes;
      }
      drawingVotes[drawingId] = votes;
    }
    let picked: PickedDrawing | null = null;
    const maxVotedDrawings = Object.entries(drawingVotes).filter(
      ([, votes]) => votes === maxVotes
    );
    if (maxVotedDrawings.length === 1) {
      picked = {
        id: maxVotedDrawings[0][0],
        teamId: this.teamId,
        reason: 'most-voted',
        pickedAt: this.clock.now(),
      };
    }
    if (!picked && fallbackEnabled) {
      const drawings = (await this.drawingsHandle.get()) || {};
      this.log.info('pick team drawing from submitted drawings', {
        teamId: this.teamId,
        drawings,
        debug: triggeredBy,
      });
      const drawing = randomPick(Object.values(drawings));
      if (drawing) {
        picked = {
          id: drawing.id,
          teamId: this.teamId,
          reason:
            triggeredBy === 'single-drawing'
              ? 'single-drawing'
              : 'random-picked',
          pickedAt: this.clock.now(),
        };
      }
    }
    if (!picked) {
      this.log.info('no team drawing picked', {
        teamId: this.teamId,
        winner: picked,
        debug: triggeredBy,
      });
      return;
    }
    this.log.info('team drawing picked', {
      teamId: this.teamId,
      winner: picked,
      debug: triggeredBy,
    });
    await this.pickedDrawingHandle.set(picked);
  }

  async submitTitle(payload: Omit<DrawingTitle, 'teamId' | 'id'>) {
    const titleId = uuidv4();
    this.log.info('submit drawing title', { payload, titleId });
    await this.titlesHandle.ref.child(`titles/${payload.drawingId}`).set({
      ...payload,
      text: payload.text,
      id: titleId,
      teamId: this.teamId,
    });
  }

  async updateTitleCreationIndex(next: number) {
    this.log.info('increment title index', { teamId: this.teamId, next });
    await this.titlesHandle.ref.child('currIndex').set(next);
  }

  async autoTitleDrawings(
    drawingIds: string[],
    titles: string[],
    maxAttempts = 10
  ) {
    this.log.info('auto title drawings without titles', {
      teamId: this.teamId,
      drawingIds,
      numOfTitles: titles.length,
    });
    if (titles.length === 0) {
      this.log.info('no titles given', { teamId: this.teamId });
      return;
    }
    const titleMap = uncheckedIndexAccess_UNSAFE(
      (await this.titlesHandle.get()) ?? {}
    );
    const promises: Promise<void>[] = [];
    for (const drawingId of drawingIds) {
      if (titleMap[drawingId]) {
        this.log.info('drawing has been titled', {
          teamId: this.teamId,
          title: titleMap[drawingId],
        });
        continue;
      }
      let attempts = maxAttempts;
      while (attempts > 0) {
        const picked = randomPick(titles);
        if (picked !== this.state.prompt?.correct) {
          promises.push(this.submitTitle({ drawingId, text: picked }));
          break;
        }
        attempts--;
      }
      if (attempts === 0) {
        this.log.info('no title picked for drawing', {
          teamId: this.teamId,
          drawingId,
        });
      }
    }
    await Promise.all(promises);
  }

  async checkIfAllTitled(numOfDrawingsNeedTitle: number) {
    const data = await this.titlesHandle.get();
    return Object.keys(data?.titles ?? {}).length === numOfDrawingsNeedTitle;
  }

  async voteTitle(payload: VoteTitlePayload) {
    this.log.info('vote title', { payload });
    const { drawingId, titleId, titleTeamId, voterId } = payload;
    const voteRef = this.mpsHandle.ref.child('votes').child(titleId);
    await voteRef.transaction((vote) => {
      if (!vote) {
        return {
          drawingId,
          titleId,
          titleTeamId,
          voters: [{ id: voterId, teamId: this.teamId }],
        };
      }
      return {
        ...vote,
        voters: [...vote.voters, { id: voterId, teamId: this.teamId }],
      };
    });
  }

  get state() {
    return this._state;
  }

  on(): void {
    this.promptHandle.on((val) => ValtioUtils.set(this._state, 'prompt', val));
    this.drawingsHandle.on((val) =>
      ValtioUtils.set(this._state, 'drawings', val)
    );
    this.votesHandle.on((val) => ValtioUtils.set(this._state, 'votes', val));
    this.pickedDrawingHandle.on((val) =>
      ValtioUtils.set(this._state, 'pickedDrawing', val)
    );
    this.titlesHandle.on((val) =>
      ValtioUtils.set(this._state, 'titleCreation', val)
    );
  }

  off(): void {
    this.promptHandle.off();
    this.drawingsHandle.off();
    this.votesHandle.off();
    this.pickedDrawingHandle.off();
    this.titlesHandle.off();
  }

  reset() {
    ValtioUtils.reset(this._state, this.initialState());
  }

  private initialState(): TeamState {
    return {
      prompt: null,
      drawings: null,
      votes: null,
      pickedDrawing: null,
      titleCreation: null,
    };
  }
}

type Context = {
  sharedAPI: DrawingPromptSharedAPI;
  gameControlAPI: DrawingPromptGameControlAPI;
  gamePlayAPI: DrawingPromptGamePlayAPI;
};

const context = React.createContext<Context | null>(null);

function useDrawingPromptContext(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('DrawingPromptContext is not in the tree!');
  return ctx;
}

export function useDrawingPromptGameControlAPI(): Context['gameControlAPI'] {
  return useDrawingPromptContext().gameControlAPI;
}

export function useDrawingPromptGamePlayAPI(): Context['gamePlayAPI'] {
  return useDrawingPromptContext().gamePlayAPI;
}

export function useDrawingPromptSharedAPI(): Context['sharedAPI'] {
  return useDrawingPromptContext().sharedAPI;
}

export function useDrawingGame(): Nullable<Game> {
  const api = useDrawingPromptSharedAPI();
  return useSnapshot(api.state).game;
}

export function useMyTeamPrompt(): Nullable<DrawingPrompt> {
  const api = useDrawingPromptGamePlayAPI();
  return useSnapshot(api.state).prompt;
}

export function useTeamDrawings(): MiniDrawing[] {
  const api = useDrawingPromptGamePlayAPI();
  const drawings = useSnapshot(api.state).drawings;
  return useMemo(() => Object.values(drawings ?? {}), [drawings]);
}

export function useMyDrawing(): MiniDrawing | null {
  const me = useMyInstance();
  const teamDrawings = useTeamDrawings();

  if (!me) return null;
  return teamDrawings.find((d) => d.playerId === me.id) ?? null;
}

export function useDrawing(
  teamId: Nullable<string>,
  drawingId: Nullable<string>
): MiniDrawing | null {
  const api = useDrawingPromptSharedAPI();
  const teamStateMap = useSnapshot(api.state).teamStateMap;
  return useMemo(() => {
    const teamState = teamStateMap?.[teamId ?? ''];
    return (
      Object.values(teamState?.drawings ?? {}).find(
        (d) => d.id === drawingId
      ) ?? null
    );
  }, [drawingId, teamId, teamStateMap]);
}

export function useMyTeamVotes(): Nullable<Record<string, string>> {
  const api = useDrawingPromptGamePlayAPI();
  return useSnapshot(api.state).votes;
}

export function useDrawingVoterIds(drawingId: string): string[] {
  const api = useDrawingPromptGamePlayAPI();
  const voteMap = useSnapshot(api.state).votes;
  return useMemo(() => {
    const ids: string[] = [];
    for (const [playerId, votedDrawingId] of Object.entries(voteMap ?? {})) {
      if (votedDrawingId === drawingId) {
        ids.push(playerId);
      }
    }
    return ids;
  }, [drawingId, voteMap]);
}

export function useDrawingVoted(voterId: string, drawingId: string): boolean {
  const api = useDrawingPromptGamePlayAPI();
  const voteMap = useSnapshot(api.state).votes;
  return voteMap?.[voterId] === drawingId;
}

export function useTeamPickedDrawing(): Nullable<PickedDrawing> {
  const api = useDrawingPromptGamePlayAPI();
  return useSnapshot(api.state).pickedDrawing;
}

export function useAllPickedDrawings(
  excludedId?: string | null
): PickedDrawing[] {
  const api = useDrawingPromptSharedAPI();
  const teamStateMap = useSnapshot(api.state).teamStateMap;
  return useMemo(() => {
    const pickedDrawings: PickedDrawing[] = [];
    for (const teamState of Object.values(teamStateMap ?? {})) {
      if (
        teamState.pickedDrawing &&
        teamState.pickedDrawing.id !== excludedId
      ) {
        pickedDrawings.push(teamState.pickedDrawing);
      }
    }
    return pickedDrawings.sort((a, b) => a.pickedAt - b.pickedAt);
  }, [excludedId, teamStateMap]);
}

export function useCurrTitleCreationIndex(): number {
  const api = useDrawingPromptGamePlayAPI();
  const titleCreation = useSnapshot(api.state).titleCreation;
  return titleCreation?.currIndex ?? 0;
}

export function useCurrentDrawingTitle(
  teamId: Nullable<string>,
  drawingId: Nullable<string>
): DrawingTitle | null {
  const api = useDrawingPromptSharedAPI();
  const teamStateMap = useSnapshot(api.state).teamStateMap;
  return useMemo(() => {
    const titles = teamStateMap?.[teamId ?? '']?.titleCreation?.titles ?? {};
    return titles[drawingId ?? ''] ?? null;
  }, [drawingId, teamId, teamStateMap]);
}

export function useCurrMatch(): CurrentMatch {
  const api = useDrawingPromptSharedAPI();
  const statusChangedAt = useGameSessionStatusChangedAt();
  const matchPromptState = useSnapshot(api.state).matchPromptState;
  return (
    (matchPromptState?.currentMatch as CurrentMatch) ?? {
      drawingIndex: 0,
      phase: 'vote',
      revealedTitles: [],
      drawingSwitchedAt: statusChangedAt,
    }
  );
}

export function useCurrPickedDrawing(): PickedDrawing | undefined {
  const allPickedDrawings = useAllPickedDrawings();
  const currMatch = useCurrMatch();
  return allPickedDrawings[currMatch.drawingIndex];
}

export function useDrawingTitles(
  teamId: Nullable<TeamId>,
  drawingId: Nullable<string>
): DrawingTitle[] {
  const api = useDrawingPromptSharedAPI();
  const teamStateMap = useSnapshot(api.state).teamStateMap;
  return useMemo(() => {
    const titles: DrawingTitle[] = [];
    if (!teamId || !drawingId) return titles;
    const prompt = teamStateMap?.[teamId]?.prompt;
    if (prompt) {
      titles.push({ id: prompt.id, drawingId, teamId, text: prompt.correct });
    }
    for (const teamState of Object.values(teamStateMap ?? {})) {
      const title = teamState.titleCreation?.titles?.[drawingId] ?? null;
      if (!title) continue;
      titles.push(title);
    }
    return shuffle(titles);
  }, [drawingId, teamId, teamStateMap]);
}

export function useDrawingTitleVoterIds(titleId: string): string[] {
  const api = useDrawingPromptSharedAPI();
  const matchPromptState = useSnapshot(api.state).matchPromptState;
  return useMemo(() => {
    const voteMap = matchPromptState?.votes ?? {};
    const vote = voteMap[titleId];
    return vote?.voters.map((v) => v.id) ?? [];
  }, [matchPromptState?.votes, titleId]);
}

export function useDrawingTitleVote(titleId: string): DrawingTitleVote | null {
  const api = useDrawingPromptSharedAPI();
  const matchPromptState = useSnapshot(api.state).matchPromptState;
  return useMemo(() => {
    const voteMap = matchPromptState?.votes ?? {};
    return (voteMap[titleId] as DrawingTitleVote | undefined) ?? null;
  }, [matchPromptState?.votes, titleId]);
}

export function useMyDrawingTitleVote(
  drawingId: string
): DrawingTitleVote | null {
  const me = useMyInstance();
  const api = useDrawingPromptSharedAPI();
  const matchPromptState = useSnapshot(api.state).matchPromptState;
  return useMemo(() => {
    if (!me?.id) return null;
    const voteMap = matchPromptState?.votes ?? {};
    for (const [, vote] of Object.entries(voteMap)) {
      if (
        vote.drawingId === drawingId &&
        vote.voters.find((v) => v.id === me.id)
      )
        return vote as DrawingTitleVote;
    }
    return null;
  }, [drawingId, matchPromptState?.votes, me?.id]);
}

export function useDrawingTitleVotes(drawingId: string): DrawingTitleVote[] {
  const api = useDrawingPromptSharedAPI();
  const matchPromptState = useSnapshot(api.state).matchPromptState;
  return useMemo(() => {
    const votes: DrawingTitleVote[] = [];
    const voteMap = matchPromptState?.votes ?? {};
    for (const [, vote] of Object.entries(voteMap)) {
      if (vote.drawingId === drawingId) {
        votes.push(vote as DrawingTitleVote);
      }
    }
    return votes;
  }, [drawingId, matchPromptState?.votes]);
}

function useAllTeamDrawingMap(): Record<TeamId, MiniDrawing[]> {
  const api = useDrawingPromptSharedAPI();
  const teamDataMap = useSnapshot(api.state).teamStateMap;
  return useMemo(() => {
    const m = uncheckedIndexAccess_UNSAFE({});
    for (const [teamId, data] of Object.entries(teamDataMap ?? {})) {
      m[teamId] = Object.values(data.drawings ?? {});
    }
    return m;
  }, [teamDataMap]);
}

export function useAllDrawings(): MiniDrawing[] {
  const api = useDrawingPromptSharedAPI();
  const teamDataMap = useSnapshot(api.state).teamStateMap;
  const teams = useTeams();
  return useMemo(() => {
    const drawings: MiniDrawing[] = [];
    const s = new Set<TeamId>();
    for (const team of teams) {
      s.add(team.id);
      const data = teamDataMap?.[team.id];
      drawings.push(...Object.values(data?.drawings ?? {}));
    }
    // if all people in the team left, that team not returned from useSelectTeams.
    // however if there are submitted drawings, we'd better to still show them
    // in the gallery.
    for (const [teamId, data] of Object.entries(teamDataMap ?? {})) {
      if (s.has(teamId)) continue;
      drawings.push(...Object.values(data?.drawings ?? {}));
      s.add(teamId);
    }
    return drawings;
  }, [teamDataMap, teams]);
}

export function usePreloadCanvasBackground(block: DrawingPromptBlock): void {
  useEffect(() => {
    if (!block.fields.canvasMedia) return;
    const url = DrawingPromptUtils.GetCanvasBgUrl(block.fields.canvasMedia);
    if (!url) return;
    loadImageAsPromise(url, true);
  }, [block.fields.canvasMedia]);
}

export function useGameProgressSummary(): GameProgressSummary {
  const api = useDrawingPromptSharedAPI();
  const teamDataMap = useSnapshot(api.state).teamStateMap;
  const teams = useTeams({
    excludeStaffTeam: true,
    updateStaffTeam: true,
  });
  return useMemo(() => {
    const summary: GameProgressSummary = {};
    for (const team of teams) {
      const data = teamDataMap?.[team.id];
      summary[team.id] = {
        prompt: data?.prompt ?? null,
        numOfDrawings: data?.drawings ? Object.keys(data.drawings).length : 0,
        numOfPlayers: team.membersCount,
        pickedDrawing: data?.pickedDrawing ?? null,
        mumOfTitles: Object.keys(data?.titleCreation?.titles ?? {}).length ?? 0,
      };
    }
    return summary;
  }, [teamDataMap, teams]);
}

export function useDrawingSubmissionProgress(): {
  drawingPlayerIds: string[];
  submittedPlayerIds: string[];
} {
  const controlAPI = useDrawingPromptGameControlAPI();
  const sharedAPI = useDrawingPromptSharedAPI();
  const teamDataMap = useSnapshot(sharedAPI.state).teamStateMap;
  return useMemo(() => {
    const submittedPlayerIds: string[] = [];
    for (const [, teamState] of Object.entries(teamDataMap ?? {})) {
      submittedPlayerIds.push(...Object.keys(teamState.drawings ?? {}));
    }
    return {
      drawingPlayerIds: controlAPI.drawingPlayerIds.sort(),
      submittedPlayerIds: submittedPlayerIds.sort(),
    };
  }, [controlAPI.drawingPlayerIds, teamDataMap]);
}

export function useTeamVotePhaseSkippable(): {
  skippable: boolean;
  teamIds: TeamId[];
} {
  const game = useDrawingGame();
  const allTeamDrawingMap = useAllTeamDrawingMap();
  const allTeamsHaveOnlyOneDrawing =
    Object.values(allTeamDrawingMap).filter((drawings) => drawings.length > 1)
      .length === 0;
  if (game?.forceTeamVote) return { skippable: false, teamIds: [] };
  return {
    skippable: allTeamsHaveOnlyOneDrawing,
    teamIds: Object.keys(allTeamDrawingMap),
  };
}

export function DrawingPromptProvider(props: {
  children?: ReactNode;
}): JSX.Element | null {
  const venueId = useVenueId();
  const { svc } = useFirebaseContext();

  const firebaseConnected = useIsFirebaseConnected();
  const isSessionAlive = useIsStreamSessionAlive();
  const ready = firebaseConnected && isSessionAlive;
  const teamId = useMyTeamId() ?? '';

  const ctx = useMemo(() => {
    return {
      sharedAPI: new DrawingPromptSharedAPI(venueId, svc),
      gameControlAPI: new DrawingPromptGameControlAPI(venueId, svc, log),
      gamePlayAPI: new DrawingPromptGamePlayAPI(venueId, teamId, svc, log),
    };
  }, [venueId, svc, teamId]);

  useEffect(() => {
    if (!ready) return;
    ctx.sharedAPI.on();
    return () => ctx.sharedAPI.off();
  }, [ctx.sharedAPI, ready]);

  useEffect(() => {
    if (!ready || !teamId) return;
    ctx.gamePlayAPI.on();
    return () => ctx.gamePlayAPI.off();
  }, [ctx.gamePlayAPI, ready, teamId]);

  return <context.Provider value={ctx}>{props.children}</context.Provider>;
}
