import pluralize from 'pluralize';
import { useMemo, useState } from 'react';

import { EnumsMediaScene } from '@lp-lib/api-service-client/public';
import {
  type AIChatBlock,
  type AIChatBlockMedia,
  type AIChatBot,
  type BlockFields,
} from '@lp-lib/game';

import { ArrowDownIcon, ArrowUpIcon } from '../../../icons/Arrows';
import { MiniMediaEditor } from '../../../MediaUploader/MiniMediaEditor';
import { usePromptTemplates } from '../../../PromptTemplate';
import {
  AdditionalSettings,
  AdditionalSharedSettingsEditor,
  BlockMediaEditor,
  EditorBody,
  EditorLayout,
  type EditorProps,
  type Option,
  RHFCheckbox,
  RHFCheckboxRaw,
  RHFSelectField,
  useEditor,
} from '../Common/Editor/EditorUtilities';
import { PointsInput } from '../Common/Editor/PointsUtilities';
import { AIChatUtils } from './utils';

function secondsToTimeOption(secs: number): Option {
  if (secs === 0) {
    return { label: `No Timer`, value: secs };
  }
  if (secs < 60) {
    return { label: `${secs} ${pluralize('second', secs)}`, value: secs };
  }

  const mins = secs / 60;
  return { label: `${mins} ${pluralize('minute', mins)}`, value: secs };
}

const timeOptions: Option[] = [
  30, 60, 120, 180, 240, 300, 360, 420, 480, 540, 600, 900, 1800,
].map(secondsToTimeOption);

const modelOptions: Option[] = [
  {
    label: 'gpt-4o',
    value: 'gpt-4o',
  },
  {
    label: 'gpt-4o-mini',
    value: 'gpt-4o-mini',
  },
  {
    label: 'gpt-4',
    value: 'gpt-4',
  },
  {
    label: 'gpt-3.5-turbo',
    value: 'gpt-3.5-turbo-16k',
  },
];

function usePromptTemplateOptions(): Option[] {
  const { data: templates } = usePromptTemplates();
  const options = useMemo(() => {
    return (templates ?? []).map<Option>((l) => ({
      label: `${l.name}`,
      value: l.id,
    }));
  }, [templates]);
  return options;
}

function usePromptTemplate(id?: string | null) {
  const { data: templates } = usePromptTemplates();
  return useMemo(() => {
    if (!templates || !id) return null;
    return templates.find((t) => t.id === id);
  }, [id, templates]);
}

function BotEditor(props: {
  block: AIChatBlock;
  onUpdate: (updated: AIChatBot) => void;
}) {
  const { block, onUpdate } = props;
  const [bot, setBot] = useState<AIChatBot>({
    ...block.fields.bot,
  });
  const [customize, setCustomize] = useState(
    !!block.fields.bot?.avatarMediaData
  );
  const defaultAvatar = AIChatUtils.GetBotDefaultAvatar();

  const handleUpdate = (updates: Partial<AIChatBot>) => {
    setBot((current) => {
      const next = { ...current, ...updates };
      onUpdate(next);
      return next;
    });
  };

  return (
    <div className='flex flex-col gap-4'>
      <RHFCheckboxRaw
        name='botEnabled'
        label='Show AI Avatar'
        value={bot.enabled}
        onChange={(v) => handleUpdate({ enabled: v })}
        description={
          'Enabled: Will show the provided avatar image next to the AI’s text.'
        }
      />
      <div className='flex justify-between gap-4'>
        <div className='w-25'>
          {customize ? (
            <MiniMediaEditor
              id={`${block.id}-bot-avatar`}
              mediaData={bot.avatarMediaData}
              media={bot.avatarMedia}
              onChange={(mediaData, media) => {
                handleUpdate({
                  avatarMediaData: mediaData,
                  avatarMedia: media,
                });
              }}
            />
          ) : (
            <img src={defaultAvatar} alt='default avatar' />
          )}
        </div>
        <RHFCheckboxRaw
          name='botCustomizeEnabled'
          label='Customize Avatar'
          value={customize}
          onChange={(v) => setCustomize(v)}
          description={{
            enabled: 'Enabled: Upload Avatar.',
            disabled: 'Disabled: Use Default Avatar',
          }}
        />
      </div>
      <label>
        <div className='mb-1 text-base font-bold'>Avatar Name</div>
        <input
          name='title'
          className='field h-13.5 m-0 w-full'
          placeholder='Max 50 characters'
          maxLength={50}
          defaultValue={bot.name}
          onBlur={(event) => {
            handleUpdate({ name: event.target.value });
          }}
        />
      </label>
    </div>
  );
}

export function AIChatBlockEditor(
  props: EditorProps<AIChatBlock>
): JSX.Element | null {
  const { block } = props;
  const blockId = block.id;
  const { updateField } = useEditor(props);

  const templateOptions = usePromptTemplateOptions();
  const template = usePromptTemplate(block.fields.promptTemplateId);
  const [expandTemplate, setExpandTemplate] = useState(false);
  const [systemPromptLength, setSystemPromptLength] = useState(
    block.fields.systemPrompt.length
  );
  const characters = (template?.systemPrompt.length ?? 0) + systemPromptLength;

  const selectOnChange = (
    name: keyof BlockFields<AIChatBlock>,
    value: Nullable<Option['value'], false>
  ): void => {
    updateField(name, value);
  };

  return (
    <EditorLayout
      bottomAccessory={
        <AdditionalSettings>
          <AdditionalSharedSettingsEditor {...props} />
        </AdditionalSettings>
      }
    >
      <EditorBody>
        <h2 className='text-2xl text-white'>AI Chat</h2>
        <div className='w-full flex text-white gap-6 my-7.5'>
          <div className='w-3/5 flex flex-col gap-4'>
            <label className='flex flex-col gap-2'>
              <RHFSelectField<AIChatBlock>
                className='w-full h-10 text-white'
                label='Model'
                name='model'
                options={modelOptions}
                onChange={selectOnChange}
                value={block.fields.model || modelOptions[0].value}
              />
            </label>
            <label className='flex justify-between'>
              <div>
                <div className='mb-1 text-base font-bold'>Temperature</div>
                <div className='text-3xs text-icon-gray w-60'>
                  Controls randomness: Lowering results in less random
                  completions. As the temperature approaches zero, the model
                  will become deterministic and repetitive.
                </div>
              </div>
              <div className='flex gap-1'>
                <div className='flex flex-col gap-1 items-center justify-center'>
                  <div className='text-2xs'>System Prompt</div>
                  <input
                    name='systemPromptTemperature'
                    className='field w-20 h-10 m-0 text-center'
                    placeholder='0-2'
                    min={0}
                    max={2}
                    type='number'
                    defaultValue={block.fields.systemPromptTemperature ?? 0.1}
                    onBlur={(event) => {
                      let val = parseFloat(event.target.value);
                      if (Number.isNaN(val) || val < 0 || val > 2) {
                        val = 1;
                      }
                      event.target.value = val.toString();
                      updateField('systemPromptTemperature', val);
                    }}
                  />
                </div>
                <div className='flex flex-col gap-1 items-center justify-center'>
                  <div className='text-2xs'>Response</div>
                  <input
                    name='temperature'
                    className='field w-20 h-10 m-0 text-center'
                    placeholder='0-2'
                    min={0}
                    max={2}
                    type='number'
                    defaultValue={block.fields.temperature}
                    onBlur={(event) => {
                      let val = parseFloat(event.target.value);
                      if (Number.isNaN(val) || val < 0 || val > 2) {
                        val = 1;
                      }
                      event.target.value = val.toString();
                      updateField('temperature', val);
                    }}
                  />
                </div>
              </div>
            </label>
            <label className='flex flex-col gap-2'>
              <RHFSelectField<AIChatBlock>
                className='w-full h-10 text-white'
                label='Prompt Template'
                name='promptTemplateId'
                options={templateOptions}
                onChange={selectOnChange}
                value={block.fields.promptTemplateId ?? ''}
              />
              {template && (
                <div>
                  <div
                    className='flex items-center gap-1'
                    onClick={() => setExpandTemplate(!expandTemplate)}
                  >
                    <div>Rules</div>
                    {expandTemplate ? <ArrowUpIcon /> : <ArrowDownIcon />}
                  </div>
                  {expandTemplate && (
                    <textarea
                      disabled
                      className={`h-80 py-2 mb-0 scrollbar resize-none field`}
                    >
                      {template.systemPrompt}
                    </textarea>
                  )}
                </div>
              )}
            </label>
            <label className='flex flex-col'>
              <div className='flex items-center justify-between'>
                <div className='mb-1 text-base font-bold'>System Prompt</div>
                <div className='text-2xs'>Characters Used: {characters}</div>
              </div>
              <textarea
                className={`h-80 py-2 mb-0 scrollbar resize-none field`}
                placeholder={`Prompt instructing the AI about its identity and purpose.`}
                defaultValue={block.fields.systemPrompt}
                onChange={(event) => {
                  setSystemPromptLength(event.target.value.length);
                }}
                onBlur={(event) => {
                  updateField('systemPrompt', event.target.value);
                }}
              />
            </label>
          </div>
          <div className='w-2/5 flex flex-col gap-4'>
            <BotEditor
              block={block}
              onUpdate={(updated) => updateField('bot', updated)}
            />
            <label>
              <RHFSelectField<AIChatBlock>
                className='w-full h-10 text-white'
                label='Time Limit'
                name='gameTimeSec'
                options={timeOptions}
                onChange={(_, value) => {
                  if (typeof value !== 'number') return;
                  updateField('gameTimeSec', value);
                }}
                value={block.fields.gameTimeSec}
              />
            </label>
            <label className='w-full flex flex-col gap-1'>
              <span className='text-white font-bold'>Winning Points</span>
              <PointsInput
                defaultValue={block.fields.winningPoints}
                max={1000}
                min={0}
                placeholder={'Max 1000 points'}
                onChange={(value) => {
                  updateField('winningPoints', value);
                }}
              />
              <div className='text-icon-gray text-3xs font-medium'>
                Points teams get for satisfying the win conditions
              </div>
            </label>
            <label>
              <RHFCheckbox<AIChatBlock>
                label='Decreasing Points Timer'
                name='decreasingPointsTimer'
                value={block.fields.decreasingPointsTimer}
                onChange={(_, checked: boolean): void => {
                  updateField('decreasingPointsTimer', checked);
                }}
                description={{
                  enabled:
                    'Enabled: Amount of points earned for a correct piece decreases as the Game Timer runs out.',
                  disabled:
                    'Disabled: Amount of points earned for a correct piece does not change as the Game Timer runs out.',
                }}
              />
            </label>
            {block.fields.decreasingPointsTimer && (
              <label>
                <RHFCheckbox<AIChatBlock>
                  label='Start Descending Immediately'
                  name='startDescendingImmediately'
                  value={block.fields.startDescendingImmediately ?? false}
                  onChange={(_, checked: boolean): void => {
                    updateField('startDescendingImmediately', checked);
                  }}
                  description={{
                    enabled: 'Enabled: Points start descending immediately',
                    disabled:
                      'Disabled: Points start descending after 25% of time.',
                  }}
                />
              </label>
            )}
            <label>
              <RHFCheckbox<AIChatBlock>
                label='Round Robin Mode'
                name='roundRobinMode'
                value={block.fields.roundRobinMode}
                onChange={(_, checked: boolean): void => {
                  updateField('roundRobinMode', checked);
                }}
                description={{
                  enabled: 'Enabled: Each member answers one by one.',
                  disabled: 'Disabled: Only Team Captain answers.',
                }}
              />
            </label>
          </div>
        </div>
        <hr className='w-full my-5 border border-secondary' />
        <div className='w-4/5 grid grid-cols-2 gap-6'>
          <label>
            <BlockMediaEditor<AIChatBlockMedia>
              blockId={blockId}
              title='Intro Media'
              field='introMedia'
              video={true}
              scene={EnumsMediaScene.MediaSceneBlockMedia}
              volumeSelectable
              mediaData={block.fields.introMediaData}
              media={block.fields.introMedia}
              extraNotice='Media will display to the audience before the block starts. '
            />
          </label>
          <label>
            <BlockMediaEditor<AIChatBlockMedia>
              blockId={blockId}
              title='Outro Media'
              field='outroMedia'
              video={true}
              scene={EnumsMediaScene.MediaSceneBlockMedia}
              volumeSelectable
              mediaData={block.fields.outroMediaData}
              media={block.fields.outroMedia}
              extraNotice='Media will display to the audience when the answer is revealed.'
            />
          </label>
          <label>
            <BlockMediaEditor<AIChatBlockMedia>
              blockId={blockId}
              title='Win Media'
              field='winMedia'
              image={false}
              video={true}
              scene={EnumsMediaScene.MediaSceneBlockMedia}
              mediaData={block.fields.winMediaData}
              media={block.fields.winMedia}
              extraNotice='Media will display to the audience on win. (Should be same length as Lose media)'
            />
          </label>
          <label>
            <BlockMediaEditor<AIChatBlockMedia>
              blockId={blockId}
              title='Lose Media'
              field='loseMedia'
              image={false}
              video={true}
              scene={EnumsMediaScene.MediaSceneBlockMedia}
              mediaData={block.fields.loseMediaData}
              media={block.fields.loseMedia}
              extraNotice='Media will display to the audience on loss. (Should be same length as Win media)'
            />
          </label>
          <label>
            <BlockMediaEditor<AIChatBlockMedia>
              blockId={blockId}
              title='Background Media'
              field='backgroundMedia'
              video={true}
              scene={EnumsMediaScene.MediaSceneBlockBackground}
              volumeSelectable
              mediaData={block.fields.backgroundMediaData}
              media={block.fields.backgroundMedia}
              extraNotice='Media will display behind the gameplay for the duration of the game'
              loopSelectable
            />
          </label>
        </div>
      </EditorBody>
    </EditorLayout>
  );
}
