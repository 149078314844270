import capitalize from 'lodash/capitalize';
import isNumber from 'lodash/isNumber';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';

import {
  assertExhaustive,
  type IcebreakerBlock,
  IcebreakerMode,
  IcebreakerSelectNextStrategy,
} from '@lp-lib/game';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { CardsIcon } from '../../../icons/CardsIcon';
import { FilledCheckIcon } from '../../../icons/CheckIcon';
import { TimerIcon } from '../../../icons/TimerIcon';
import { Loading } from '../../../Loading';
import { useMyTeamId, useParticipantsByClientIds } from '../../../Player';
import { useSoundEffect } from '../../../SFX';
import { useTeam, useTeamMembers } from '../../../TeamAPI/TeamV1';
import { useUser } from '../../../UserContext';
import { useGameSessionLocalTimer } from '../../hooks';
import { ProgressRing } from '../Common/GamePlay/ProgressRing';
import { longDuration } from '../Puzzle/utils';
import {
  IcebreakerBlockCard,
  IcebreakerBlockTabooCard,
} from './IcebreakerBlockCard';
import {
  IcebreakerBlockParticipantPicker,
  OnStagePlayer,
} from './IcebreakerBlockParticipants';
import {
  useCurrentCard,
  useCurrentPlayer,
  useIcebreakerSharedAPI,
  useIcebreakerTextFormat,
  useIsGamePlaying,
  useIsMyTurn,
  useMyVotedIndex,
  useShowGetReady,
  useShowNextPlayerPicker,
  useShowNextPlayerPickerOnComplete,
  useTeamProgressData,
} from './IcebreakerBlockProvider';
import { type IcebreakerGamePlayCard } from './types';
import { IcebreakerUtils } from './utils';

function MoveOnCountdown() {
  const [remain, setRemain] = useState(3);

  useEffectOnce(() => {
    const timer = setInterval(() => {
      setRemain((r) => (r > 0 ? r - 1 : 0));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });

  return (
    <div className='text-xl font-medium text-center h-15 flex items-center'>
      Move on in {remain}s...
    </div>
  );
}

function Instruction(props: { block: IcebreakerBlock }): JSX.Element {
  const { block } = props;

  const isMyTurn = useIsMyTurn();
  const currentPlayer = useCurrentPlayer();
  const format = useIcebreakerTextFormat();

  const instruction = format(
    isMyTurn
      ? block.fields.instructionsOnStage
      : block.fields.instructionsNotOnStage,
    currentPlayer
  );

  return <>{instruction}</>;
}

function IcebreakerPlaygroundLayout(props: {
  left: React.ReactNode;
  right: React.ReactNode;
  block: IcebreakerBlock;
}) {
  if (props.block.fields.mode === IcebreakerMode.ChatPack) {
    return (
      <div className='w-full h-full flex flex-col items-center justify-center gap-4'>
        <div className='text-3.5xl font-bold text-center'>
          {props.block.fields.gameName}
        </div>
        <div
          className='relative z-5 w-full h-full
            rounded-2.5xl bg-white bg-opacity-20
            border border-white border-opacity-20
          '
        >
          {props.right}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className='ml-75 text-3.5xl font-bold text-center'>
        {props.block.fields.gameName}
      </div>

      <div className='flex items-center'>
        <div
          className='relative z-10 w-75 h-140
            rounded-2.5xl bg-[#fff6e8]
          '
        >
          {props.left}
        </div>

        <div
          className='relative z-5 w-240 h-130 pl-40 -ml-40
            rounded-2.5xl bg-white bg-opacity-20
            border border-white border-opacity-20
          '
        >
          {props.right}
        </div>
      </div>
    </div>
  );
}

function IcebreakerPlaygroundLeftPanel(props: {
  block: IcebreakerBlock;
  currentCard: IcebreakerGamePlayCard;
}) {
  const { block, currentCard } = props;

  const api = useIcebreakerSharedAPI();
  const isMyTurn = useIsMyTurn();
  const currentPlayer = useCurrentPlayer();
  const myVotedIndex = useMyVotedIndex();
  const isGamePlaying = useIsGamePlaying();
  const user = useUser();

  return (
    <div className='w-full h-full flex flex-col'>
      {IcebreakerUtils.ShouldSelectOnStagePlayer(block) && (
        <div className='relative w-full h-75 flex justify-center items-center'>
          <OnStagePlayer participant={currentPlayer} />
        </div>
      )}

      <div className='w-full flex-1 overflow-hidden px-5 py-5 flex flex-col justify-between items-center gap-4'>
        <p
          className={`flex-1 text-main-layer text-xl font-bold overflow-auto scrollbar-always leading-6`}
        >
          <Instruction block={block} />
        </p>

        {isMyTurn &&
          currentCard.phase === 'active' &&
          block.fields.mode !== IcebreakerMode.HeadsUp &&
          !block.fields.cardClickProgressesGame && (
            <button
              type='button'
              disabled={
                !isGamePlaying ||
                (block.fields.onStageCardSelection && myVotedIndex === null)
              }
              onClick={() => {
                // if the card is on stage card selection, reveal the voted card
                if (
                  block.fields.onStageCardSelection &&
                  isNumber(myVotedIndex)
                ) {
                  api.revealCard(myVotedIndex, user.id);
                  return;
                }
                // if the card is hidden to audience, reveal all the cards
                if (currentCard.hiddenToAudience) {
                  api.revealCard(undefined, user.id);
                  return;
                }
                // otherwise, complete the card
                api.completeCard(user.id);
              }}
              className='flex-none btn bg-green-001 hover:bg-[#33c35b] transition-colors w-50 h-15 flex justify-center items-center gap-2.5'
            >
              <FilledCheckIcon className='w-7.5 h-7.5 fill-current' />
              <p className='text-base font-medium'>I'm Done</p>
            </button>
          )}
        {isMyTurn &&
          currentCard.phase === 'reveal' &&
          !IcebreakerUtils.ShouldRevealAutoProgress(currentCard, block) && (
            <button
              type='button'
              onClick={() => api.completeCard()}
              className='flex-none btn bg-green-001 hover:bg-[#33c35b] transition-colors w-50 h-15 flex justify-center items-center gap-2.5'
            >
              <p className='text-base font-medium'>
                {block.fields.selectNextStrategy ===
                IcebreakerSelectNextStrategy.KeepCurrent
                  ? 'Next'
                  : 'Next Player'}
              </p>
            </button>
          )}
      </div>
      {block.fields.mode === IcebreakerMode.HeadsUp && (
        <div className='mt-auto h-11 w-full bg-main-layer rounded-b-2.5xl'>
          <OnStagePointsBanner />
        </div>
      )}
    </div>
  );
}

function OnStageHUD(props: { block: IcebreakerBlock }) {
  const onStageTimeSec = props.block.fields.onStageTimeSec;
  const time = useGameSessionLocalTimer();
  const durationFormattedMMSS = onStageTimeSec >= longDuration;
  if (onStageTimeSec === 0) return null;
  return (
    <div className='flex flex-col items-center gap-2'>
      <ProgressRing
        className=''
        textClassName={durationFormattedMMSS ? 'text-lg' : 'text-xl'}
        currentTime={time}
        totalTime={onStageTimeSec}
        withPingAnimations
        durationFormattedMMSS={durationFormattedMMSS}
      />
    </div>
  );
}

function OnStagePointsBanner() {
  const currentPlayer = useCurrentPlayer();
  const progressData = useTeamProgressData(currentPlayer?.teamId);
  const currentScore = progressData?.score ?? 0;

  const currentTeam = useTeam(currentPlayer?.teamId);
  const teamMembers = useTeamMembers(currentPlayer?.teamId) ?? [];
  const participants = useParticipantsByClientIds(teamMembers.map((m) => m.id));
  const playerNames = useMemo(() => {
    if (!participants || participants.length === 0) return [];
    const playerNames = participants.map((p) => p.firstName ?? p.username);
    if (playerNames.length <= 3) return playerNames.join(', ');
    return `${playerNames[0]}, ${playerNames[1]}, +${
      playerNames.length - 2
    } more`;
  }, [participants]);
  const myTeamId = useMyTeamId();
  const isMyTeam = myTeamId && myTeamId === currentPlayer?.teamId;

  return (
    <div className='w-full h-full px-4 py-1.5 flex items-center justify-between gap-2'>
      <div className='flex-1 min-w-0'>
        <div
          className='text-sms font-bold truncate'
          style={{ color: currentTeam?.color ?? 'white' }}
        >
          {currentTeam?.name}
          {isMyTeam ? ' (Your team)' : ''}
        </div>
        {teamMembers.length > 1 && (
          <div className='text-xs text-icon-gray truncate'>{playerNames}</div>
        )}
      </div>
      <div className={`flex-none text-right text-sm font-bold`}>
        {currentScore}
      </div>
    </div>
  );
}

function HeadsUpControls() {
  const api = useIcebreakerSharedAPI();
  const isGamePlaying = useIsGamePlaying();
  const time = useGameSessionLocalTimer();
  const user = useUser();
  const myTeamId = useMyTeamId();

  const [evaluating, setEvaluating] = useState(false);
  const handleMarkCorrect = useLiveCallback(async () => {
    if (!myTeamId || evaluating) return;
    setEvaluating(true);
    try {
      await api.markHeadsUpCardCorrect(user.id, myTeamId);
    } finally {
      setEvaluating(false);
    }
  });

  const handleMarkSkipped = useLiveCallback(async () => {
    if (evaluating) return;
    setEvaluating(true);
    try {
      await api.markHeadsUpCardSkipped(user.id);
    } finally {
      setEvaluating(false);
    }
  });

  const disabled = useMemo(() => {
    return !isGamePlaying || time === 0 || evaluating;
  }, [isGamePlaying, time, evaluating]);

  return (
    <div className='flex items-center gap-5'>
      <button
        type='button'
        disabled={disabled}
        onClick={handleMarkSkipped}
        className='flex-none btn-secondary w-50 h-15 font-medium flex justify-center items-center gap-2.5'
      >
        <CardsIcon className='w-7.5 h-7.5 fill-current' />
        Skip
      </button>

      <button
        type='button'
        disabled={disabled}
        onClick={handleMarkCorrect}
        className='flex-none btn bg-green-001 hover:bg-[#33c35b] transition-colors w-50 h-15 flex justify-center items-center gap-2.5'
      >
        {evaluating ? (
          <Loading text='' />
        ) : (
          <FilledCheckIcon className='w-7.5 h-7.5 fill-current' />
        )}
        <p className='text-base font-medium'>Got It</p>
      </button>
    </div>
  );
}

function HeadsUpCardResult() {
  const currentCard = useCurrentCard();
  const currentPlayer = useCurrentPlayer();
  const seen = useRef(new Set<string>());

  const [result, setResult] = useState<{
    card: IcebreakerGamePlayCard;
    text: string;
  } | null>(null);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (
      !currentPlayer ||
      !currentCard ||
      currentCard.phase !== 'completed' ||
      !currentCard.headsUpResult ||
      seen.current.has(currentCard.id)
    )
      return;

    seen.current.add(currentCard.id);
    const name = capitalize(currentPlayer.firstName ?? currentPlayer.username);
    const text =
      currentCard.headsUpResult === 'correct'
        ? `${name} got it!`
        : `${name} skipped`;
    setResult({
      card: currentCard,
      text,
    });
    if (timerRef.current) clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      setResult(null);
    }, 2000);
  }, [currentCard, currentPlayer]);

  useEffect(() => {
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <div
      className={`${
        result === null ? 'opacity-0' : 'opacity-100'
      } transition-opacity`}
    >
      <p
        className={`font-bold text-lg ${
          result?.card.headsUpResult === 'correct'
            ? 'text-green-001'
            : 'text-tertiary'
        }`}
      >
        {result ? <>{result.text}</> : <>&nbsp;</>}
      </p>
    </div>
  );
}

function IcebreakerPlaygroundRightPanel(props: {
  block: IcebreakerBlock;
  currentCard: IcebreakerGamePlayCard;
}) {
  const { block, currentCard } = props;

  const showNextPlayerPicker = useShowNextPlayerPicker();
  const showNextPlayerPickerOnComplete =
    useShowNextPlayerPickerOnComplete(block);
  const api = useIcebreakerSharedAPI();
  const isMyTurn = useIsMyTurn();
  const currentPlayer = useCurrentPlayer();
  const isGamePlaying = useIsGamePlaying();
  const showGetReady = useShowGetReady();

  if (!currentCard) return null;

  // host or coordinator can force pick next player
  if (showNextPlayerPicker) {
    return (
      <IcebreakerBlockParticipantPicker
        onPick={(p) => {
          api.nextPlayer(p.id);
          api.toggleNextPlayerPicker(false);
        }}
        onCancel={() => api.toggleNextPlayerPicker(false)}
        isParticipantDisabled={(p, ps) =>
          p.id === currentPlayer?.id && ps.length > 1
        }
      />
    );
  }

  // on stage player can choose next player if the card is ended
  if (showNextPlayerPickerOnComplete) {
    return (
      <IcebreakerBlockParticipantPicker
        onPick={(p) => api.nextCardAndPlayer(p.id)}
        isParticipantDisabled={(p, ps) =>
          p.id === currentPlayer?.id && ps.length > 1
        }
      />
    );
  }

  // onStageTimer and we need to show getReady screen
  if (showGetReady) {
    return (
      <div className='relative w-full h-full p-2.5 flex flex-col gap-2.5'>
        <div className='flex-1 flex items-center justify-center'>
          <div className='text-white font-bold text-5xl'>Get Ready!</div>
        </div>

        {isMyTurn && (
          <div className='flex-none flex items-center justify-center'>
            <button
              type='button'
              disabled={!isGamePlaying}
              onClick={() => {
                api.playerReady();
              }}
              className='flex-none btn-secondary w-50 h-15 font-medium flex justify-center items-center gap-2.5'
            >
              <TimerIcon className='w-7.5 h-7.5 fill-current' />
              Start Timer
            </button>
          </div>
        )}

        <div className='absolute right-0 top-0 transform translate-x-full'>
          <OnStageHUD block={block} />
        </div>
      </div>
    );
  }

  // game view
  return (
    <div className='relative w-full h-full p-2.5 flex flex-col justify-between items-center gap-2.5'>
      {IcebreakerUtils.IsTabooStyle(block.fields) ? (
        <IcebreakerBlockTabooCard card={currentCard} block={block} />
      ) : (
        <IcebreakerBlockCard card={currentCard} block={block} />
      )}

      {isMyTurn ? (
        <div
          className={
            currentCard.phase === 'active'
              ? ''
              : 'opacity-50 pointer-events-none'
          }
        >
          {block.fields.mode === IcebreakerMode.HeadsUp ? (
            <HeadsUpControls />
          ) : (
            <button
              type='button'
              disabled={!isGamePlaying}
              onClick={() => {
                api.nextCard();
              }}
              className='flex-none btn-secondary w-50 h-15 font-medium flex justify-center items-center gap-2.5'
            >
              <CardsIcon className='w-7.5 h-7.5 fill-current' />
              Draw Again
            </button>
          )}
        </div>
      ) : null}

      {!isMyTurn && block.fields.mode === IcebreakerMode.HeadsUp && (
        <HeadsUpCardResult />
      )}

      {currentCard.phase === 'reveal' &&
        IcebreakerUtils.ShouldRevealAutoProgress(currentCard, block) && (
          <MoveOnCountdown />
        )}

      {block.fields.onStageTimeSec > 0 && (
        <div className='absolute right-0 top-0 transform translate-x-full'>
          <OnStageHUD block={block} />
        </div>
      )}
    </div>
  );
}

function useCardSoundEffect(
  block: IcebreakerBlock,
  currentCard: IcebreakerGamePlayCard | null
) {
  const { play: playCardShowSFX } = useSoundEffect('icebreakerCardShow');
  const { play: playCardRevealSFX } = useSoundEffect('icebreakerCardReveal');
  const { play: playCardWhooshAwaySFX } = useSoundEffect('cardWhooshAway');

  useEffect(() => {
    if (!currentCard?.phase) return;

    const phase = currentCard.phase;

    if (block.fields.mode === IcebreakerMode.HeadsUp) {
      if (phase === 'completed') {
        const result = currentCard.headsUpResult;
        if (result === 'skipped') {
          playCardWhooshAwaySFX();
        } else {
          playCardRevealSFX();
        }
      }
    } else {
      switch (phase) {
        case 'active':
          playCardShowSFX();
          break;
        case 'reveal':
          playCardRevealSFX();
          break;
        case 'completed':
          break;
        default:
          assertExhaustive(phase);
      }
    }
  }, [
    block.fields.mode,
    currentCard?.phase,
    currentCard?.headsUpResult,
    playCardRevealSFX,
    playCardShowSFX,
    playCardWhooshAwaySFX,
  ]);
}

export function IcebreakerPlayground(props: {
  block: IcebreakerBlock;
}): JSX.Element | null {
  const { block } = props;

  const currentCard = useCurrentCard();
  useCardSoundEffect(block, currentCard);

  if (!currentCard) return null;

  return (
    <IcebreakerPlaygroundLayout
      block={block}
      left={
        <IcebreakerPlaygroundLeftPanel
          block={block}
          currentCard={currentCard}
        />
      }
      right={
        <IcebreakerPlaygroundRightPanel
          block={block}
          currentCard={currentCard}
        />
      }
    />
  );
}
