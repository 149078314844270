import pluralize from 'pluralize';
import { useMemo, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { useLatest } from 'react-use';

import {
  EnumsIcebreakerGameStyle,
  EnumsMediaScene,
  type ModelsMediaAsset,
} from '@lp-lib/api-service-client/public';
import {
  type IcebreakerBlock,
  type IcebreakerBlockMedia,
  type IcebreakerCard,
  IcebreakerMode,
  IcebreakerOnStageSelection,
  IcebreakerOnStageTimerMode,
  type IcebreakerOption,
  IcebreakerSelectNextStrategy,
} from '@lp-lib/game';
import { type Media } from '@lp-lib/media';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import {
  fromMediaDataDTO,
  fromMediaDTO,
  toMediaDataDTO,
  toMediaDTO,
} from '../../../../utils/api-dto';
import { uuidv4 } from '../../../../utils/common';
import { createCSVFile, csvToArray } from '../../../../utils/csv';
import { useAwaitFullScreenConfirmCancelModal } from '../../../ConfirmCancelModalContext';
import { ArrowDownIcon } from '../../../icons/Arrows';
import { MediaUploader } from '../../../MediaUploader/MediaUploader';
import { MiniMediaEditor } from '../../../MediaUploader/MiniMediaEditor';
import { MiniMediaUploader } from '../../../MediaUploader/MiniMediaUploader';
import { SwitcherControlled } from '../../../Switcher';
import {
  AdditionalSettings,
  AdditionalSharedSettingsEditor,
  BlockMediaEditor,
  CreatableDurationSelect,
  CSVUtils,
  EditorBody,
  EditorLayout,
  type EditorProps,
  formatDurationOption,
  type Option,
  RHFCheckbox,
  RHFSelectField,
  SimpleTooltip,
  useEditor,
} from '../Common/Editor/EditorUtilities';
import { PointsInput } from '../Common/Editor/PointsUtilities';
import { IcebreakerUtils } from './utils';

const gameTimeOptions: Option[] = [0, 300, 600, 900].map((secs) => {
  if (secs === 0) return { label: 'No Timer', value: 0 };
  return { label: formatDurationOption(secs), value: secs };
});

const onStageTimeOptions: Option[] = [0, 10, 30, 60].map((secs) => {
  if (secs === 0) return { label: 'No Timer', value: 0 };
  return { label: formatDurationOption(secs), value: secs };
});

const onStageTimerModeOptions: Option[] = [
  {
    label: 'Reset on Player Change',
    value: IcebreakerOnStageTimerMode.PlayerReset,
  },
  {
    label: 'Reset on Card Change',
    value: IcebreakerOnStageTimerMode.CardReset,
  },
];

const onStageSelectionOptions: Option[] = [
  { label: 'No On Stage Player', value: IcebreakerOnStageSelection.None },
  { label: 'Random', value: IcebreakerOnStageSelection.Random },
  { label: 'The %VIP%', value: IcebreakerOnStageSelection.VIP },
];

function getSelectNextStrategyOptions(selection: IcebreakerOnStageSelection) {
  return [
    { label: 'Default', value: IcebreakerSelectNextStrategy.Default },
    {
      label: 'Let the person on stage choose',
      value: IcebreakerSelectNextStrategy.OnStageChoose,
    },
    {
      label:
        selection === IcebreakerOnStageSelection.VIP
          ? `Keep VIPs on stage`
          : `Don’t change who is on stage`,
      value: IcebreakerSelectNextStrategy.KeepCurrent,
    },
  ];
}

const promptHeaders = [
  'Card 1 - On Stage',
  'Card 1 - Audience',
  'Card 2 - On Stage',
  'Card 2 - Audience',
  'Card 3 - On Stage',
  'Card 3 - Audience',
  'Card 4 - On Stage',
  'Card 4 - Audience',
];

function CardManagementHeader(props: {
  blockId: string;
  cardCount: number;
  handleAddBatch: (prompts: IcebreakerCard[]) => void;
  csvDownloadHref: string;
}) {
  const handleUpload = useLiveCallback((content: string | ArrayBuffer) => {
    const csv = csvToArray(content.toString());
    if (csv.length <= 1) return;
    const rows = csv.splice(1);
    const prompts = rows.map<IcebreakerCard>((row) => {
      const options = [
        { text: row[0] ?? '', hiddenToAudienceText: row[1] ?? '' },
        { text: row[2] ?? '', hiddenToAudienceText: row[3] ?? '' },
        { text: row[4] ?? '', hiddenToAudienceText: row[5] ?? '' },
        { text: row[6] ?? '', hiddenToAudienceText: row[7] ?? '' },
      ];
      const hiddenToAudience = options.some((o) => o.hiddenToAudienceText);
      // try to trim the list as much as possible.
      for (let i = options.length - 1; i > 0; i--) {
        const opt = options[i];
        if (opt.text.length === 0 && opt.hiddenToAudienceText.length === 0) {
          options.splice(i, 1);
        } else {
          // as soon as we find one, stop trimming.
          break;
        }
      }
      return {
        id: uuidv4(),
        texts: [],
        options: options,
        hiddenToAudience,
      };
    });
    props.handleAddBatch(prompts);
  });

  return (
    <div className='flex gap-10 items-center'>
      <div className='text-white font-bold'>Cards ({props.cardCount})</div>
      <CSVUtils
        onUpload={handleUpload}
        downloadHref={props.csvDownloadHref}
        downloadName={`icebreaker-prompts-${props.blockId}.csv`}
      />
    </div>
  );
}

function IcebreakerCardOptionEditor(props: {
  index: number;
  cardId: IcebreakerCard['id'];
  option: IcebreakerOption;
  onDelete: () => void;
  onUpdate: (
    id: string,
    index: number,
    updates: Partial<IcebreakerOption>
  ) => void;
  hiddenToAudience: boolean;
  hasOnStagePlayer: boolean;
}) {
  const {
    index,
    cardId,
    option,
    onDelete,
    onUpdate,
    hiddenToAudience,
    hasOnStagePlayer,
  } = props;

  const textEditor = (
    <label className='flex-1'>
      <div className='mb-1 text-base font-bold'>
        Card Text {index + 1}
        {`${hiddenToAudience ? ' – On Stage' : ''}`}
      </div>
      <input
        className='field h-10 m-0 min-w-60 w-full'
        placeholder='Max 300 characters'
        maxLength={300}
        defaultValue={option.text}
        onBlur={(event) =>
          onUpdate(cardId, index, {
            text: event.target.value,
          })
        }
      />
      <div className='mt-1 text-3xs font-medium text-icon-gray'>
        Use the variables %onStagePlayer% or %vipNames%, to display a first name
        in the card text.
      </div>
    </label>
  );

  const hiddenToAudienceTextEditor = (
    <label className='flex-1'>
      <div className='mb-1 text-base font-bold'>
        Card Text {index + 1} – Audience
      </div>
      <input
        className='field h-10 m-0 min-w-60 w-full'
        placeholder='Max 300 characters'
        maxLength={300}
        defaultValue={option.hiddenToAudienceText ?? undefined}
        onBlur={(event) =>
          onUpdate(cardId, index, {
            hiddenToAudienceText: event.target.value,
          })
        }
      />
      <div className='mt-1 text-3xs font-medium text-icon-gray'>
        Use the variables %onStagePlayer% or %vipNames%, to display a first name
        in the card text.
      </div>
    </label>
  );

  const onStageBackgroundEditor = (
    <label>
      <div className='mb-1 text-base font-bold'>&nbsp;</div>
      <MiniMediaEditor
        id={`card-${cardId}-option-${index}-on-stage-background`}
        video={false}
        scene={EnumsMediaScene.MediaSceneBlockMedia}
        objectFit='cover'
        mediaData={fromMediaDataDTO(option.onStageBackground?.data)}
        media={fromMediaDTO(option.onStageBackground?.media)}
        onChange={(data, media) =>
          onUpdate(cardId, index, {
            onStageBackground: {
              data: toMediaDataDTO(data),
              media: media ? toMediaDTO(media) : null,
            },
          })
        }
      />
    </label>
  );

  const audienceBackgroundEditor = (
    <label>
      <div className='mb-1 text-base font-bold'>
        {hiddenToAudience ? <>&nbsp;</> : <>Audience</>}
      </div>
      <MiniMediaEditor
        id={`card-${cardId}-option-${index}-audience-background`}
        video={false}
        scene={EnumsMediaScene.MediaSceneBlockMedia}
        objectFit='cover'
        mediaData={fromMediaDataDTO(option.audienceBackground?.data)}
        media={fromMediaDTO(option.audienceBackground?.media)}
        onChange={(data, media) =>
          onUpdate(cardId, index, {
            audienceBackground: {
              data: toMediaDataDTO(data),
              media: media ? toMediaDTO(media) : null,
            },
          })
        }
      />
    </label>
  );

  const deleteButton =
    index > 0 ? (
      <button
        type='button'
        className='btn text-red-002 text-2xs font-light'
        onClick={onDelete}
      >
        Delete Card
      </button>
    ) : null;

  // there are a few variants of this view.
  if (!hasOnStagePlayer) {
    // the config should use text, audienceBackground.
    return (
      <div className='w-full'>
        <div className='w-full flex gap-3 items-stretch'>
          {textEditor}
          {audienceBackgroundEditor}
        </div>
        {deleteButton}
      </div>
    );
  } else if (hiddenToAudience) {
    // the config should use them all.
    return (
      <div
        className={`w-full flex flex-col items-start gap-5 border-b border-secondary pb-6`}
      >
        <div className='w-full flex gap-3 items-stretch'>
          {textEditor}
          {onStageBackgroundEditor}
        </div>
        <div className='w-full flex gap-3 items-stretch'>
          {hiddenToAudienceTextEditor}
          {audienceBackgroundEditor}
        </div>
        {deleteButton}
      </div>
    );
  } else {
    // the config should use text, onStageBackground
    return (
      <div className='w-full'>
        <div className='w-full flex gap-3 items-stretch'>
          {textEditor}
          {onStageBackgroundEditor}
        </div>
        {deleteButton}
      </div>
    );
  }
}

function ConfirmCSVUploadModal(props: {
  block: IcebreakerBlock;
  onCancel: () => void;
  onAppend: (cards: IcebreakerCard[]) => void;
  onReplace: (cards: IcebreakerCard[]) => void;
  cards: IcebreakerCard[];
  showOnStageSelectionWarning: boolean;
}) {
  const {
    block,
    onCancel,
    onAppend,
    onReplace,
    cards,
    showOnStageSelectionWarning,
  } = props;

  const [onStageMedia, setOnStageMedia] = useState<Media | null>(null);
  const [audienceMedia, setAudienceMedia] = useState<Media | null>(null);
  const [options, setOptions] = useState<boolean[]>(
    new Array(IcebreakerUtils.MaxCardOptions(block.fields)).fill(true)
  );

  const handleSubmit = useLiveCallback((mode: 'replace' | 'append') => {
    const nextCards = cards.map((card) => {
      return {
        ...card,
        options: card.options.map((option, index) => {
          if (!options[index]) return option;
          return {
            ...option,
            onStageBackground: onStageMedia
              ? {
                  data: { id: onStageMedia.id },
                  media: toMediaDTO(onStageMedia),
                }
              : option.onStageBackground,
            audienceBackground: audienceMedia
              ? {
                  data: { id: audienceMedia.id },
                  media: toMediaDTO(audienceMedia),
                }
              : option.audienceBackground,
          };
        }),
      };
    });

    if (mode === 'replace') {
      onReplace(nextCards);
    } else {
      onAppend(nextCards);
    }
  });

  return (
    <div className='border border-secondary bg-black rounded-xl px-5 py-3 w-140 min-h-45'>
      <div className='w-full h-full flex flex-col text-white'>
        <div className='flex-none w-full pt-2 pb-4'>
          <div className='font-bold text-2xl'>
            Upload {cards.length} {pluralize('Card', cards.length)}
          </div>
          <div className='text-sm text-icon-gray'>
            Bulk upload card prompts.
          </div>
        </div>

        <div className='flex-grow flex-shrink-0 w-full pb-6 space-y-5'>
          <div className='grid grid-cols-2 pb-4'>
            <div className='col-span-2 text-base font-bold pb-2'>
              Attach Media
              <div className='text-xs text-icon-gray font-normal'>
                Optionally attach media to the imported cards.
              </div>
            </div>
            <div>
              <div className='text-center text-white text-sms font-bold'>
                On Stage Media
              </div>
              <div>
                <MiniMediaUploader
                  media={onStageMedia}
                  onUploadSuccess={setOnStageMedia}
                />
              </div>
            </div>
            <div>
              <div className='text-center text-white text-sms font-bold'>
                Audience Media
              </div>
              <div>
                <MiniMediaUploader
                  media={audienceMedia}
                  onUploadSuccess={setAudienceMedia}
                />
              </div>
            </div>
            <div className='pt-4 col-span-2 flex items-center gap-2 text-xs'>
              <div className='text-white font-bold mr-3'>Apply To Card</div>
              {options.map((checked, index) => (
                <label
                  key={index}
                  className='flex items-center cursor-pointer select-none'
                >
                  <input
                    type='checkbox'
                    className='checkbox-dark'
                    checked={checked}
                    onChange={() =>
                      setOptions((prev) =>
                        prev.map((v, i) => (i === index ? !v : v))
                      )
                    }
                  />
                  <span className='ml-2'>Option {index + 1}</span>
                </label>
              ))}
            </div>
          </div>

          <p className='text-sm'>
            Append or replace the existing cards with the uploaded cards?
            Replacing will remove all existing cards and replace them.
          </p>
          {showOnStageSelectionWarning && (
            <p className='text-sm text-tertiary'>
              Note: On Stage Selection is set to "No On Stage Player". As a
              result, all cards will be visible to the audience.
            </p>
          )}
        </div>

        <div className='mt-auto w-full py-2 flex items-center justify-end gap-4'>
          <button
            type='button'
            className='mr-auto btn text-xs text-icon-gray hover:underline'
            onClick={() => handleSubmit('replace')}
          >
            Replace All
          </button>
          <button
            type='button'
            className='btn-secondary w-40 py-2'
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-40 py-2'
            onClick={() => handleSubmit('append')}
          >
            Append
          </button>
        </div>
      </div>
    </div>
  );
}

function IcebreakerCardsManagement(props: {
  block: IcebreakerBlock;
  cards: IcebreakerCard[];
  onChange: (cards: IcebreakerCard[]) => void;
}) {
  const { block, cards, onChange } = props;
  const latestCards = useLatest(cards);
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const handleAdd = () => {
    onChange([
      ...cards,
      {
        id: uuidv4(),
        texts: [],
        options: [{ text: '' }],
        hiddenToAudience: false,
      },
    ]);
  };

  const handleAppendBatch = (batch: IcebreakerCard[]) => {
    let res = [...cards, ...batch];
    if (block.fields.onStageSelection === IcebreakerOnStageSelection.None) {
      res = res.map((card) => ({ ...card, hiddenToAudience: false }));
    }
    onChange(res);
  };

  const handleReplaceAll = (batch: IcebreakerCard[]) => {
    let res = [...batch];
    if (block.fields.onStageSelection === IcebreakerOnStageSelection.None) {
      res = res.map((card) => ({ ...card, hiddenToAudience: false }));
    }
    onChange(res);
  };

  const handleCSVUpload = async (cards: IcebreakerCard[]) => {
    const resp = await triggerModal({
      kind: 'custom',
      element: (p) => (
        <ConfirmCSVUploadModal
          block={block}
          onCancel={p.internalOnCancel}
          onAppend={(cards) => {
            handleAppendBatch(cards);
            p.internalOnConfirm();
          }}
          onReplace={(cards) => {
            handleReplaceAll(cards);
            p.internalOnConfirm();
          }}
          cards={cards}
          showOnStageSelectionWarning={
            block.fields.onStageSelection === IcebreakerOnStageSelection.None
          }
        />
      ),
    });
    if (resp.result === 'canceled') return;
  };

  const handleDelete = (card: IcebreakerCard) => {
    onChange(cards.filter((c) => c.id !== card.id));
  };

  const handleUpdate = (id: string, updates: Partial<IcebreakerCard>) => {
    onChange(
      cards.map((card) => (card.id === id ? { ...card, ...updates } : card))
    );
  };

  const handleUpdateOption = (
    id: string,
    index: number,
    updates: Partial<IcebreakerOption>
  ) => {
    const card = latestCards.current.find((c) => c.id === id);
    if (!card) return;
    const newOption = { ...card.options[index], ...updates };
    const newOptions = card.options.map((o, i) =>
      i === index ? newOption : o
    );
    const newCard = {
      ...card,
      options: newOptions,
    };
    const newCards = latestCards.current.map((c) =>
      c.id === id ? newCard : c
    );
    onChange(newCards);
  };

  const downloadHref = useMemo(() => {
    const data = [promptHeaders];
    for (const card of cards) {
      const row = card.options.flatMap((option) => [
        option.text,
        card.hiddenToAudience ? option.hiddenToAudienceText ?? '' : '',
      ]);
      data.push(row);
    }
    return createCSVFile(data);
  }, [cards]);

  return (
    <div className='mt-10 w-full'>
      <CardManagementHeader
        blockId={block.id}
        cardCount={cards.length}
        handleAddBatch={handleCSVUpload}
        csvDownloadHref={downloadHref}
      />

      <main className='mt-3 flex flex-col gap-6'>
        {cards.map((card) => (
          <div
            key={card.id}
            className='relative w-full bg-black rounded-xl p-6 pb-14'
          >
            <div className='w-full flex flex-col gap-5'>
              {card.options.map((option, index) => (
                <IcebreakerCardOptionEditor
                  key={`${index}-${option.text}-${option.hiddenToAudienceText}`} // note: we don't have a key :/
                  index={index}
                  cardId={card.id}
                  hasOnStagePlayer={IcebreakerUtils.ShouldSelectOnStagePlayer(
                    block
                  )}
                  hiddenToAudience={card.hiddenToAudience}
                  option={option}
                  onDelete={() => {
                    const newOptions = [...card.options];
                    newOptions.splice(index, 1);
                    handleUpdate(card.id, { options: newOptions });
                  }}
                  onUpdate={handleUpdateOption}
                />
              ))}
            </div>

            {card.options.length <
              IcebreakerUtils.MaxCardOptions(block.fields) && (
              <div className='mt-4'>
                <button
                  type='button'
                  className='underline text-icon-gray text-sm'
                  onClick={() => {
                    const newOptions = [...card.options, { text: '' }];
                    handleUpdate(card.id, { options: newOptions });
                  }}
                >
                  Add Card
                </button>
              </div>
            )}

            <div className='w-75 mt-7'>
              <div className='mb-1 flex items-center justify-between'>
                <div className='text-base font-bold'>Hidden to Audience</div>
                <SwitcherControlled
                  name={`card-${card.id}-hidden-to-audience`}
                  checked={card.hiddenToAudience}
                  onChange={(checked) =>
                    handleUpdate(card.id, { hiddenToAudience: checked })
                  }
                  disabled={!IcebreakerUtils.ShouldSelectOnStagePlayer(block)}
                />
              </div>
              <div className='text-3xs font-medium text-icon-gray'>
                When toggled on, the audience will see their own card that is
                different from the person on stage.
              </div>
            </div>

            <button
              type='button'
              onClick={() => handleDelete(card)}
              className={`absolute right-2.5 bottom-2.5 btn text-red-002 text-xs font-light`}
            >
              Delete Card
            </button>
          </div>
        ))}
      </main>

      <footer className='mt-3'>
        <button
          type='button'
          className='btn text-secondary text-sms underline'
          onClick={handleAdd}
        >
          Add More
        </button>
      </footer>
    </div>
  );
}

function ApplyCardMediaModal(props: {
  block: IcebreakerBlock;
  cards: IcebreakerCard[];
  onChange: (cards: IcebreakerCard[]) => void;
  onCancel: () => void;
  onClose: () => void;
}) {
  const { block } = props;
  const [media, setMedia] = useState<Media | null>(null);
  const [options, setOptions] = useState<boolean[]>(
    new Array(IcebreakerUtils.MaxCardOptions(block.fields)).fill(true)
  );
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
    getArrowProps,
  } = usePopperTooltip({
    trigger: 'click',
    placement: 'bottom',
    interactive: true,
    delayHide: 150,
  });

  const handleApply = useLiveCallback(
    async (to: { onStage: boolean; audience: boolean }) => {
      const resp = await triggerModal({
        kind: 'confirm-cancel',
        prompt: (
          <div className='p-5 text-white text-center'>
            <p className='text-2xl font-medium'>Are you sure?</p>
            <p className='mt-4 text-sms'>
              This action will{' '}
              {media ? 'apply the uploaded media to' : 'delete the media from'}{' '}
              {to.onStage && to.audience
                ? 'all'
                : to.onStage
                ? 'on stage'
                : 'audience'}{' '}
              cards.
            </p>
          </div>
        ),
        confirmBtnLabel: 'Apply',
        cancelBtnLabel: 'Cancel',
      });
      if (resp.result === 'canceled') return;

      const mediaAsset: ModelsMediaAsset | null = media
        ? {
            data: { id: media.id },
            media: toMediaDTO(media),
          }
        : null;

      props.onChange(
        props.cards.map((card) => ({
          ...card,
          options: card.options.map((option, index) => {
            if (!options[index]) return option;
            return {
              ...option,
              onStageBackground: to.onStage
                ? mediaAsset
                : option.onStageBackground,
              audienceBackground: to.audience
                ? mediaAsset
                : option.audienceBackground,
            };
          }),
        }))
      );
      props.onClose();
    }
  );

  return (
    <div className='border border-secondary bg-black rounded-xl px-5 py-3 w-150 min-h-45'>
      <div className='w-full h-full flex flex-col text-white'>
        <div className='flex-none w-full pt-2 pb-4'>
          <div className='font-bold text-2xl'>Apply Card Media</div>
          <div className='text-sm text-icon-gray'>
            Bulk edit card media for all cards in this block. Leave the media
            blank to bulk remove media.
          </div>
        </div>

        <div className='flex-grow flex-shrink-0 w-full py-6 space-y-5 flex justify-center gap-3'>
          <MediaUploader
            replace
            image
            video={false}
            audio={false}
            media={media}
            objectFit='object-contain'
            onUploadSuccess={setMedia}
            width='flex-1'
          />
          <div className='flex-none space-y-2 text-sm'>
            <div className='text-white font-bold'>Apply To Card</div>
            {options.map((checked, index) => (
              <label
                key={index}
                className='flex items-center cursor-pointer select-none'
              >
                <input
                  type='checkbox'
                  className='checkbox-dark'
                  checked={checked}
                  onChange={() =>
                    setOptions((prev) =>
                      prev.map((v, i) => (i === index ? !v : v))
                    )
                  }
                />
                <span className='ml-2'>Option {index + 1}</span>
              </label>
            ))}
          </div>
        </div>

        <div className='mt-auto w-full py-2 flex items-center justify-end gap-4'>
          <button
            type='button'
            className='btn-secondary w-40 py-2'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            ref={setTriggerRef}
            type='button'
            className='btn-primary w-40 py-2 flex items-center justify-center gap-2'
          >
            Apply To
            <ArrowDownIcon className='w-3 h-3 fill-current' />
            {visible && (
              <div
                ref={setTooltipRef}
                {...getTooltipProps({
                  className: `w-44 flex flex-col items-start gap-0 rounded-md text-white text-xs px-1 py-2 bg-black border border-secondary z-40`,
                })}
              >
                <div
                  {...getArrowProps({
                    className:
                      'h-4 absolute w-4 pointer-events-off left-0 top-0 mt-n1 before:tooltipArrowBefore after:tooltipArrowAfter',
                  })}
                />
                <div
                  className='w-full hover:bg-lp-gray-001 py-2 px-3 font-bold flex items-center transition-colors'
                  onClick={() =>
                    handleApply({ onStage: true, audience: false })
                  }
                >
                  On Stage Media
                </div>
                <div
                  className='w-full hover:bg-lp-gray-001 py-2 px-3 font-bold flex items-center transition-colors'
                  onClick={() =>
                    handleApply({ onStage: false, audience: true })
                  }
                >
                  Audience Media
                </div>
                <div
                  className='w-full hover:bg-lp-gray-001 py-2 px-3 font-bold flex items-center transition-colors'
                  onClick={() => handleApply({ onStage: true, audience: true })}
                >
                  All Card Media
                </div>
              </div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

function ApplyCardMediaButton(props: {
  block: IcebreakerBlock;
  cards: IcebreakerCard[];
  onChange: (cards: IcebreakerCard[]) => void;
}) {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const handleClick = useLiveCallback(() => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <ApplyCardMediaModal
          {...props}
          onCancel={p.internalOnCancel}
          onClose={p.internalOnConfirm}
        />
      ),
    });
  });

  return (
    <button
      type='button'
      className='btn-secondary w-full h-10'
      onClick={handleClick}
    >
      Apply Card Media
    </button>
  );
}

function GameStyleNotes() {
  return (
    <section>
      <div>
        <label className='font-medium'>Taboo</label>
        <ul className='list-inside list-disc'>
          <li>
            You can add 6 card texts, the first one represents the guess word,
            and the rest are taboo words.
          </li>
          <li>
            Leave the Audience text blank, we don't show any words to the
            audience.
          </li>
          <li>
            Customize card media will be ignored in this style, we will use the
            pre-defined UI instead.
          </li>
        </ul>
      </div>
    </section>
  );
}

const modeOptions = [
  {
    label: 'Standard',
    value: IcebreakerMode.Default,
  },
  {
    label: 'Heads Up',
    value: IcebreakerMode.HeadsUp,
  },
  {
    label: 'Chat Pack',
    value: IcebreakerMode.ChatPack,
  },
];

const styleOptions = [
  {
    label: 'Standard',
    value: EnumsIcebreakerGameStyle.IcebreakerGameStyleDefault,
  },
  {
    label: 'Taboo',
    value: EnumsIcebreakerGameStyle.IcebreakerGameStyleTaboo,
  },
];

export function IcebreakerBlockEditor(
  props: EditorProps<IcebreakerBlock>
): JSX.Element | null {
  const { block } = props;
  const { updateField } = useEditor(props);

  const disableOnStageCardSelection =
    block.fields.mode !== IcebreakerMode.Default ||
    !IcebreakerUtils.ShouldSelectOnStagePlayer(block);

  const disableCardClickProgressesGame =
    block.fields.mode !== IcebreakerMode.Default ||
    !block.fields.onStageCardSelection;

  const disableMajorityVoteProgressesGame =
    block.fields.mode !== IcebreakerMode.Default ||
    !block.fields.offStageCardVoting;

  const disableOffStageCardVoting =
    block.fields.mode !== IcebreakerMode.Default;

  const handleModeChange = useLiveCallback(async (mode: IcebreakerMode) => {
    if (mode === IcebreakerMode.HeadsUp) {
      await updateField('onStageCardSelection', false);
      await updateField('cardClickProgressesGame', false);
      await updateField('offStageCardVoting', false);
      await updateField('majorityVoteProgressesGame', false);
      await updateField('skipGameRecap', false);
      await updateField(
        'onStageTimerMode',
        IcebreakerOnStageTimerMode.PlayerReset
      );
      await updateField('onStageTimerAutoStart', false);
    } else if (mode === IcebreakerMode.ChatPack) {
      // TODO(falcon): how can we update in bulk
      await updateField('onStageCardSelection', false);
      await updateField('cardClickProgressesGame', false);
      await updateField('offStageCardVoting', false);
      await updateField('majorityVoteProgressesGame', false);
      await updateField('skipGameRecap', false);
      await updateField('points', 0);
      await updateField('onStageSelection', IcebreakerOnStageSelection.None);
      await updateField('skipGameRecap', true);
      await updateField(
        'onStageTimerMode',
        IcebreakerOnStageTimerMode.CardReset
      );
      await updateField('onStageTimerAutoStart', true);
      await updateField(
        'style',
        EnumsIcebreakerGameStyle.IcebreakerGameStyleDefault
      );
    } else {
      await updateField('points', 0);
      await updateField(
        'style',
        EnumsIcebreakerGameStyle.IcebreakerGameStyleDefault
      );
    }
    await updateField('mode', mode);
  });

  const handleStyleChange = useLiveCallback(
    async (style: EnumsIcebreakerGameStyle) => {
      await updateField('style', style);
    }
  );

  return (
    <EditorLayout
      bottomAccessory={
        <AdditionalSettings>
          <AdditionalSharedSettingsEditor {...props} />
        </AdditionalSettings>
      }
    >
      <EditorBody>
        <h2 className='text-2xl text-white'>Icebreaker Cards</h2>

        <div className='w-full my-7.5 flex flex-col text-white'>
          <div className='w-full flex gap-6'>
            <section className='w-2/3'>
              <label>
                <div className='mb-1 text-base font-bold'>Game Name</div>
                <input
                  name='gameName'
                  key={`block-${props.block.id}-game-name`}
                  className='field h-13.5 m-0 w-full'
                  placeholder='Max 300 characters'
                  maxLength={300}
                  defaultValue={props.block.fields.gameName}
                  onBlur={(event) => {
                    updateField('gameName', event.target.value);
                  }}
                />
              </label>

              <label>
                <div className='mt-5 mb-1 text-base font-bold'>
                  Instructions for User On Stage
                </div>
                <input
                  name='instructionsOnStage'
                  key={`block-${props.block.id}-instructions-on-stage`}
                  className='field h-13.5 m-0 w-full'
                  placeholder='Max 300 characters'
                  maxLength={300}
                  defaultValue={props.block.fields.instructionsOnStage}
                  onBlur={(event) => {
                    updateField('instructionsOnStage', event.target.value);
                  }}
                />
                <div className='ml-2 mt-2 text-icon-gray text-3xs font-medium'>
                  Use the variable %onStagePlayer%, to populate the first name
                  of the player on stage.
                </div>
              </label>

              <label>
                <div className='mt-5 mb-1 text-base font-bold'>
                  Instructions for Users NOT On Stage
                </div>
                <input
                  name='instructionsNotOnStage'
                  key={`block-${props.block.id}-instructions-not-on-stage`}
                  className='field h-13.5 m-0 w-full'
                  placeholder='Max 300 characters'
                  maxLength={300}
                  defaultValue={props.block.fields.instructionsNotOnStage}
                  onBlur={(event) => {
                    updateField('instructionsNotOnStage', event.target.value);
                  }}
                />
                <div className='ml-2 mt-2 text-icon-gray text-3xs font-medium'>
                  Use the variable %onStagePlayer%, to populate the first name
                  of the player on stage.
                </div>
              </label>

              {block.fields.mode === IcebreakerMode.HeadsUp &&
                block.fields.style ===
                  EnumsIcebreakerGameStyle.IcebreakerGameStyleTaboo && (
                  <div className='text-white mt-5'>
                    <div className='font-bold mb-1'>Game Style Notes:</div>
                    <div className='text-sms border border-secondary p-2 rounded-xl'>
                      <GameStyleNotes />
                    </div>
                  </div>
                )}

              <IcebreakerCardsManagement
                block={block}
                cards={block.fields.cards}
                onChange={(cards) => {
                  updateField('cards', cards);
                }}
              />
            </section>
            <section className='w-1/3 flex flex-col items-center'>
              <label htmlFor='backgroundMedia' className='w-full my-2'>
                <BlockMediaEditor<IcebreakerBlockMedia>
                  blockId={block.id}
                  width='w-full'
                  title='Background Media'
                  field='backgroundMedia'
                  video
                  volumeSelectable
                  loopSelectable
                  scene={EnumsMediaScene.MediaSceneBlockBackground}
                  mediaData={block.fields.backgroundMediaData}
                  media={block.fields.backgroundMedia}
                  extraNotice='Media will display and play in the background during the Game Timer.'
                />
              </label>
              <ApplyCardMediaButton
                block={block}
                cards={block.fields.cards}
                onChange={(cards) => {
                  updateField('cards', cards);
                }}
              />
              <RHFSelectField<IcebreakerBlock, 'mode'>
                className='flex-grow h-10 my-2 text-white'
                label='Game Mode'
                name='mode'
                options={modeOptions}
                onChange={(_, mode) => {
                  if (mode && typeof mode === 'string') {
                    handleModeChange(mode as IcebreakerMode);
                  }
                }}
                value={block.fields.mode ?? IcebreakerMode.Default}
              />
              {block.fields.mode === IcebreakerMode.HeadsUp && (
                <RHFSelectField<IcebreakerBlock, 'style'>
                  className='flex-grow h-10 my-2 text-white'
                  label={
                    <div className='text-white flex items-center gap-1'>
                      <span className='font-bold'>Game Style</span>
                      <SimpleTooltip className='z-5'>
                        <div
                          className='w-80 bg-black rounded-lg py-2 px-4 
                        border border-secondary text-xs'
                        >
                          <GameStyleNotes />
                        </div>
                      </SimpleTooltip>
                    </div>
                  }
                  name='style'
                  options={styleOptions}
                  onChange={(_, val) => {
                    if (val && typeof val === 'string') {
                      handleStyleChange(val as EnumsIcebreakerGameStyle);
                    }
                  }}
                  value={
                    block.fields.style ??
                    EnumsIcebreakerGameStyle.IcebreakerGameStyleDefault
                  }
                  description='The additional game style to apply to the game.'
                />
              )}
              <label htmlFor='gameTimeSec' className='w-full my-2'>
                <CreatableDurationSelect<IcebreakerBlock, 'gameTimeSec'>
                  label='Game Time'
                  name='gameTimeSec'
                  description='For a custom duration, enter the desired time in seconds and press Enter.'
                  value={block.fields.gameTimeSec}
                  onChange={(name, value) => {
                    updateField(name, Number(value));
                  }}
                  options={gameTimeOptions}
                />
              </label>
              <label htmlFor='onStageTimeSec' className='w-full my-2'>
                <CreatableDurationSelect<IcebreakerBlock, 'onStageTimeSec'>
                  label='On Stage Timer'
                  name='onStageTimeSec'
                  description='For a custom duration, enter the desired time in seconds and press Enter.'
                  value={block.fields.onStageTimeSec}
                  onChange={(name, value) => {
                    updateField(name, Number(value));
                  }}
                  options={onStageTimeOptions}
                />
              </label>
              <label
                htmlFor='onStageTimerAutoStart'
                className={`w-full my-2 ${
                  block.fields.onStageTimeSec === 0
                    ? 'opacity-50 pointer-events-off'
                    : ''
                }`}
              >
                <RHFCheckbox<IcebreakerBlock>
                  label='Auto Start On Stage Timer'
                  name='onStageTimerAutoStart'
                  value={block.fields.onStageTimerAutoStart}
                  onChange={(_, checked: boolean): void => {
                    updateField('onStageTimerAutoStart', checked);
                  }}
                  description={`When enabled, the timer will start automatically, without user interaction.`}
                />
              </label>
              <label
                htmlFor='onStageTimerMode'
                className={`w-full my-2 ${
                  block.fields.onStageTimeSec === 0
                    ? 'opacity-50 pointer-events-off'
                    : ''
                }`}
              >
                <RHFSelectField<IcebreakerBlock>
                  className='w-full h-10 text-white'
                  label='On Stage Timer Mode'
                  name='onStageTimerMode'
                  options={onStageTimerModeOptions}
                  onChange={updateField}
                  value={block.fields.onStageTimerMode}
                />
              </label>
              <label htmlFor='onStageSelection' className='w-full my-2'>
                <RHFSelectField<IcebreakerBlock>
                  className='w-full h-10 text-white'
                  label='On Stage Selection'
                  name='onStageSelection'
                  options={onStageSelectionOptions}
                  onChange={updateField}
                  value={block.fields.onStageSelection}
                />
              </label>
              <label htmlFor='selectNextStrategy' className='w-full my-2'>
                <RHFSelectField<IcebreakerBlock>
                  className='w-full h-10 text-white'
                  label='How to select next person'
                  name='selectNextStrategy'
                  options={getSelectNextStrategyOptions(
                    block.fields.onStageSelection
                  )}
                  onChange={updateField}
                  value={block.fields.selectNextStrategy}
                  disabled={!IcebreakerUtils.ShouldSelectOnStagePlayer(block)}
                />
              </label>
              {block.fields.mode === IcebreakerMode.HeadsUp && (
                <label className='w-full flex flex-col gap-1'>
                  <span className='text-white font-bold'>Points</span>
                  <PointsInput
                    defaultValue={block.fields.points}
                    onChange={(value) => {
                      updateField('points', value);
                    }}
                  />
                  <div className='text-icon-gray text-3xs'>
                    The number of points to award when a user guesses correctly.
                  </div>
                </label>
              )}
              <label
                htmlFor='onStageCardSelection'
                className={`w-full my-2 ${
                  disableOnStageCardSelection
                    ? 'opacity-50 pointer-events-none'
                    : ''
                }`}
              >
                <RHFCheckbox<IcebreakerBlock>
                  label='On Stage Card Selection'
                  name='onStageCardSelection'
                  value={block.fields.onStageCardSelection}
                  disabled={disableOnStageCardSelection}
                  onChange={(_, checked: boolean): void => {
                    updateField('onStageCardSelection', checked);
                  }}
                  description={`When enabled, the on stage player can select one of the cards they are presented with.`}
                />
              </label>
              <label
                htmlFor='cardClickProgressesGame'
                className={`w-full my-2 ${
                  disableCardClickProgressesGame
                    ? 'opacity-50 pointer-events-none'
                    : ''
                }`}
              >
                <RHFCheckbox<IcebreakerBlock>
                  label='Card Click Progresses Game'
                  name='cardClickProgressesGame'
                  value={block.fields.cardClickProgressesGame}
                  disabled={disableCardClickProgressesGame}
                  onChange={(_, checked: boolean): void => {
                    updateField('cardClickProgressesGame', checked);
                  }}
                  description={`When enabled, the game progresses when the on stage player selects a card. “I’m Done” will not be shown.`}
                />
              </label>
              <label
                htmlFor='majorityVoteProgressesGame'
                className='w-full my-2'
              >
                <RHFCheckbox<IcebreakerBlock>
                  label='Majority Vote Progresses Game'
                  name='majorityVoteProgressesGame'
                  value={block.fields.majorityVoteProgressesGame}
                  disabled={disableMajorityVoteProgressesGame}
                  onChange={(_, checked: boolean): void => {
                    updateField('majorityVoteProgressesGame', checked);
                  }}
                  description={`When enabled, a card will be revealed if a majority of users has voted for it.`}
                />
              </label>
              <label
                htmlFor='offStageCardVoting'
                className={`w-full my-2 ${
                  disableOffStageCardVoting
                    ? 'opacity-50 pointer-events-none'
                    : ''
                }`}
              >
                <RHFCheckbox<IcebreakerBlock>
                  label='Off Stage Card Voting'
                  name='offStageCardVoting'
                  value={block.fields.offStageCardVoting}
                  onChange={(_, checked: boolean): void => {
                    updateField('offStageCardVoting', checked);
                  }}
                  description={`When enabled the off stage players can vote on a card.`}
                  disabled={disableOffStageCardVoting}
                />
              </label>
              <label htmlFor='muteBackgroundMusic' className='w-full my-2'>
                <RHFCheckbox<IcebreakerBlock>
                  label='Mute Background Music'
                  name='muteBackgroundMusic'
                  value={block.fields.muteBackgroundMusic}
                  onChange={(_, checked: boolean): void => {
                    updateField('muteBackgroundMusic', checked);
                  }}
                  description={`When enabled, venue background music will not play.`}
                />
              </label>
              <label htmlFor='skipGameRecap' className='w-full my-2'>
                <RHFCheckbox<IcebreakerBlock>
                  label='Include Game Recap'
                  name='skipGameRecap'
                  value={!block.fields.skipGameRecap}
                  onChange={(_, checked: boolean): void => {
                    updateField('skipGameRecap', !checked);
                  }}
                  description={`When enabled, the game recap will play once the block is finished`}
                />
              </label>
              <label htmlFor='playCardsInOrder' className='w-full my-2'>
                <RHFCheckbox<IcebreakerBlock>
                  label='Play Cards In Order'
                  name='playCardsInOrder'
                  value={block.fields.playCardsInOrder}
                  onChange={(_, checked: boolean): void => {
                    updateField('playCardsInOrder', checked);
                  }}
                  description={`When enabled, cards will be displayed in order. When disabled cards will be displayed in random order.`}
                />
              </label>
            </section>
          </div>
        </div>
      </EditorBody>
    </EditorLayout>
  );
}
